const workPermitTypeQuestionList = {
    HotWork: {
      generalPrecautionsList: [
        "Isolation equipment/pipeline from the plant",
        "Equipment/piping was free from organic vapor and flammable material Flush/Purge with water, steam or inert gas",
        "Nearest equipment/Drainage covered",
        "Proper tools & tackles",
        "Water hose/fire extinguisher available nearby",
        "Flush and clean the container and refill the container with water till it overflow from top",
        "Surrounding area is cleaned with water and free from any leakage/spillage of flammable Material",
        "Welding/cutting spark falls in the safe zone",
      ],
      specialPrecautionsList: [
        "Mechanical LOTO requirement",
        "Electrical LOTO requirement",
        "Area Barricading",
        "HOT WORK Training Provided",
        "Does the permit required any other permit system?",
        "Any special equipment/PPE, required (CO monitor, Respirator, Oxygen meter)",
      ],
    },
    WorkAtHeight: {
      generalPrecautionsList: [
        "Equipment isolated, Tagged & locked from power supply.",
        "Equipment properly drained/cleaned/isolated with valve/blinded.",
        "Safe means of access, scaffolding provided.",
        "Safety ladder required or not?",
        "Crawling ladder required or not?",
        "Scaffold /Ladder is in good working condition.",
        "Platform is safe to use the Scaffold /Ladder.",
      ],
      specialPrecautionsList: [
        "Mechanical LOTO requirement",
        "Electrical LOTO requirement",
        "Area Barricading",
        "Work inside Confined Space Training Provided",
        "Does the permit required any other permit system?",
        "Any special equipment/PPE, required (CO monitor, Respirator, Oxygen meter)",
      ],
    },
    ElectricalWork: {
      generalPrecautionsList: [
        "Switch closed",
        "Main fuse removed",
        "Control fuse removed",
        "HT/LT breaker in test position",
        "Pad locking provided",
        "Earthling provided",
        "Caution board provided",
        "Electrical Tools are in good condition",
        "This isolation will close following machine:",
      ],
      specialPrecautionsList: [
        "Mechanical LOTO requirement",
        "Electrical LOTO requirement",
        "Area Barricading",
        "Work inside Confined Space Training Provided",
        "Does the permit required any other permit system?",
        "Any special equipment/PPE, required (CO monitor, Respirator, Oxygen meter)",
      ],
    },
    ConfinedSpace: {
      generalPrecautionsList: [
        "Equipment/work area inspected Surrounding area checked/cleaned",
        "Sewers, manholes, cable ducts, hot surfaces nearby covered",
        "Equipment electrically isolated and tagged",
        "Fire extinguisher Provided",
        "Fire Water system checked for readiness Equipment blinded/disconnected/closed/isolated",
        "Equipment properly purged",
        "Oxygen % (19.5 - 23.0)",
        "Combustible LEL % (0)",
        "Toxic Gas H2S PPM (< 10 PPM)",
        "Toxic Gas - CO PPM (< 25 PPM)",
        "Proper ventilation provided",
        "Precautionary tags/board provided",
        "24V portable elec. tools properly earth/grounded",
        "24V flame-proof lighting provided Standby personnel provided for vessel entry",
      ],
      specialPrecautionsList: [
        "Mechanical LOTO requirement",
        "Electrical LOTO requirement",
        "Area Barricading",
        "Work inside Confined Space Training Provided",
        "Does the permit required any other permit system?",
        "Any special equipment/PPE, required (CO monitor, Respirator, Oxygen meter)",
      ],
    },
  };
  
  const ppesList = [
    "Safety Helmet",
    "Safety full body harness",
    "Safety shoes",
    "Nose Mask",
    "Hand gloves",
    "Safety goggles",
    "Ear Plug/Ear Muffs",
  ];
  
  export { workPermitTypeQuestionList, ppesList };
  