import React, { useEffect, useState, useRef } from "react";
import {
  Container,
  Grid,
  Paper,
  Typography,
  Button,
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  CardContent,
  Card,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { Bar, Line } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import DetailedDataTable from "./PpeGrievances/DetailedDataTable";
import CustomCard from "../../componants/CustomCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandshake, faHandshakeSimple } from "@fortawesome/free-solid-svg-icons";

ChartJS.register( CategoryScale, LinearScale, BarElement, Title, Tooltip);

const PPEGrievancesChart = ({ company_name }) => {
  const [startDate, setStartDate] = useState(dayjs().startOf("year"));
  const [endDate, setEndDate] = useState(dayjs());
  const [loading, setLoading] = useState(false);
  const [designationData, setDesignationData] = useState([]);
  const [departmentData, setDepartmentData] = useState([]);
  const [totalDesignationReported, setTotalDesignationReported] = useState(0);
  const [totalDesignationClosed, setTotalDesignationClosed] = useState(0);
  const [totalDepartmentReported, setTotalDepartmentReported] = useState(0);
  const [totalDepartmentClosed, setTotalDepartmentClosed] = useState(0);

  const [monthlyData, setMonthlyData] = useState(null);

  const [openDialog, setOpenDialog] = useState(false);
  const [selectedDataType, setSelectedDataType] = useState("");

  const chartRefs = useRef([]);

  const fetchData = async () => {
    setLoading(true);
    const db = firebase.firestore();
    const ppeGrievancesCollection = db
      .collection("company_data")
      .doc(company_name)
      .collection(company_name)
      .doc("for_user")
      .collection("ppe_grievances");

    const querySnapshot = await ppeGrievancesCollection.get();

    const designationDataObj = {};
    const departmentDataObj = {};
    const monthlyDataObj = {};
    let totalDesignationReportedCount = 0;
    let totalDesignationClosedCount = 0;
    let totalDepartmentReportedCount = 0;
    let totalDepartmentClosedCount = 0;

    querySnapshot.forEach((doc) => {
      const data = doc.data();
      const {
        reportingPersonDesignation,
        departmentName,
        isPending,
        dateOfPPEGrievancesRaised,
      } = data;

      //const formatedDateOfDocument = dateOfPPEGrievancesRaised.toDate();
      const formatedDateOfDocument =
        typeof dateOfPPEGrievancesRaised === "string"
          ? new Date(dateOfPPEGrievancesRaised).getTime()
          : dateOfPPEGrievancesRaised.toMillis();
      const date = dayjs(dateOfPPEGrievancesRaised);
      if (
        formatedDateOfDocument >= startDate.toDate() &&
        formatedDateOfDocument <= endDate.toDate()
      ) {
        // console.log('date1',date);

        // Monthly data
        const monthYear = date.format("MMM YY");
        monthlyDataObj[monthYear] = (monthlyDataObj[monthYear] || 0) + 1;

        // Update designation data
        if (designationDataObj[reportingPersonDesignation]) {
          if (isPending) {
            designationDataObj[reportingPersonDesignation].reported++;
          } else {
            designationDataObj[reportingPersonDesignation].closed++;
            designationDataObj[reportingPersonDesignation].reported++;
          }
        } else {
          designationDataObj[reportingPersonDesignation] = {
            reported: isPending ? 1 : 1,
            closed: isPending ? 0 : 1,
          };
        }

        // Update department data
        if (departmentDataObj[departmentName]) {
          if (isPending) {
            departmentDataObj[departmentName].reported++;
          } else {
            departmentDataObj[departmentName].reported++;
            departmentDataObj[departmentName].closed++;
          }
        } else {
          departmentDataObj[departmentName] = {
            reported: isPending ? 1 : 1,
            closed: isPending ? 0 : 1,
          };
        }

        if (isPending) {
          totalDesignationReportedCount++;
          totalDepartmentReportedCount++;
        } else {
          totalDesignationClosedCount++;
          totalDepartmentClosedCount++;
          totalDesignationReportedCount++;
          totalDepartmentReportedCount++;
        }
      }
    });

    const formattedDesignationData = Object.entries(designationDataObj).map(
      ([label, { reported, closed }]) => ({
        label,
        reported,
        closed,
      })
    );

    const formattedDepartmentData = Object.entries(departmentDataObj).map(
      ([label, { reported, closed }]) => ({
        label,
        reported,
        closed,
      })
    );

    setMonthlyData({
      labels: Object.keys(monthlyDataObj).sort((a, b) =>
        dayjs(a, "MMM YY").diff(dayjs(b, "MMM YY"))
      ),
      datasets: [
        {
          label: "Ppe Grievances Reported",
          data: Object.values(monthlyDataObj),
          borderColor: "rgb(75, 192, 192)",
          tension: 0.1,
        },
      ],
    });

    setDesignationData(formattedDesignationData);
    setDepartmentData(formattedDepartmentData);
    setTotalDesignationReported(totalDesignationReportedCount);
    setTotalDesignationClosed(totalDesignationClosedCount);
    setTotalDepartmentReported(totalDepartmentReportedCount);
    setTotalDepartmentClosed(totalDepartmentClosedCount);
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [company_name, startDate, endDate]);

  const handleExportPDF = () => {
    const pdf = new jsPDF("p", "mm", "a4");
    const pageHeight = pdf.internal.pageSize.height;
    const pageWidth = pdf.internal.pageSize.width;
    const margin = 10;
    let yOffset = margin;

    const addChartToPDF = (chartRef, callback) => {
      html2canvas(chartRef, { useCORS: true }).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const imgWidth = pageWidth - 2 * margin;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;

        if (yOffset + imgHeight + margin > pageHeight) {
          pdf.addPage();
          yOffset = margin;
        }

        pdf.addImage(imgData, "PNG", margin, yOffset, imgWidth, imgHeight);
        yOffset += imgHeight + margin;

        callback();
      });
    };

    const processCharts = (index) => {
      if (index < chartRefs.current.length) {
        addChartToPDF(chartRefs.current[index], () => {
          processCharts(index + 1);
        });
      } else {
        pdf.save("ppe_grievances_charts.pdf");
      }
    };
    processCharts(0);
  };

  const chartOptions = {
    responsive: true,
    scales: {
      x: { stacked: true },
      y: { stacked: true },
    },
    plugins: {
      legend: { position: "top" },
      title: { display: true, text: "PPE Grievances Charts" },
    },
  };

  const handleTotalClick = (dataType) => {
    setSelectedDataType(dataType);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const designationChartData = {
    labels: designationData.map((item) => item.label),
    datasets: [
      {
        label: "Reported",
        data: designationData.map((item) => item.reported),
        backgroundColor: "rgba(75, 192, 192, 0.6)",
      },
      {
        label: "Closed",
        data: designationData.map((item) => item.closed),
        backgroundColor: "rgba(255, 99, 132, 0.6)",
      },
    ],
  };

  const departmentChartData = {
    labels: departmentData.map((item) => item.label),
    datasets: [
      {
        label: "Reported",
        data: departmentData.map((item) => item.reported),
        backgroundColor: "rgba(75, 192, 192, 0.6)",
      },
      {
        label: "Closed",
        data: departmentData.map((item) => item.closed),
        backgroundColor: "rgba(255, 99, 132, 0.6)",
      },
    ],
  };
  return (
    <Box p={2}>
      <Typography variant="h4" gutterBottom fontWeight={"bold"}>
        PPE Grievances Report
      </Typography>

      <Box mb={2}>
        <Grid container spacing={2}>
        <CustomCard
            bannerText="PPE Grievances"
            bannerColor="#ff5722"
            bannerTextColor="#f8f9fa"
            onOpenClick={() => handleTotalClick("reported")}
            onCloseClick={() => handleTotalClick("closed")}
            openTitle="Reported"
            openValue={totalDesignationReported}
            
            closedTitle="Closed"
            closedValue={totalDesignationClosed}
            
          />
        </Grid>
      </Box>

      <Grid container spacing={3} mb={3}>
        <Grid item xs={12} md={4}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Start Date"
              value={startDate}
              onChange={(newValue) => setStartDate(newValue)}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} md={4}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="End Date"
              value={endDate}
              onChange={(newValue) => setEndDate(newValue)}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} md={4}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleExportPDF}
            fullWidth
          >
            Export as PDF
          </Button>
        </Grid>
      </Grid>
      {loading ? (
        <Box display="flex" justifyContent="center" my={4}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper elevation={3} ref={(el) => (chartRefs.current[0] = el)}>
                <Typography variant="h6" align="center" gutterBottom>
                  Monthly Ppe Grievances Reported
                </Typography>
                {monthlyData ? (
                  <Line
                    data={monthlyData}
                    options={{
                      responsive: true,
                      scales: {
                        x: { title: { display: true, text: "Month Year" } },
                        y: { title: { display: true, text: "Number of ppe" } },
                      },
                    }}
                  />
                ) : (
                  <Typography align="center" color="error">
                    No data available
                  </Typography>
                )}
              </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
              <Paper elevation={3} ref={(el) => (chartRefs.current[1] = el)}>
                <Typography variant="h6" align="center" gutterBottom>
                  Designation-wise Reported vs Closed
                </Typography>
                <Bar data={designationChartData} options={chartOptions} />
              </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
              <Paper elevation={3} ref={(el) => (chartRefs.current[2] = el)}>
                <Typography variant="h6" align="center" gutterBottom>
                  Department-wise Reported vs Closed
                </Typography>
                <Bar data={departmentChartData} options={chartOptions} />
              </Paper>
            </Grid>
          </Grid>
          <Dialog
            open={openDialog}
            onClose={handleCloseDialog}
            maxWidth="lg"
            fullWidth
          >
            <DialogTitle>Detailed Report</DialogTitle>
            <DialogContent>
              <DetailedDataTable
                companyName={company_name}
                startDate={startDate.toDate()}
                endDate={endDate.toDate()}
                dataType={selectedDataType}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialog} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </Box>
  );
};

export default PPEGrievancesChart;
