import { Box, Container } from '@mui/material'
import React from 'react'

const ExameForm = ( { exameLink } ) => {
  return (
    <Container>
      <Box marginTop={12} marginBottom={4}>
          <iframe src={ exameLink } 
          width="100%" 
          height="600" 
          frameborder="0" 
          marginheight="0" 
          marginwidth="0"
          >
          Loading…
          </iframe>
      </Box>
    </Container>
  )
}

export default ExameForm