import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Card,
  CardContent,
  Button,
  Grid,
  Modal,
  Box,
  TextField,
  IconButton,
  Paper,
  Tabs,
  Tab
} from '@mui/material';
import { styled } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';

import { useLocation } from 'react-router-dom';
import { firestore } from '../../firebase/firebaseConfig';
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';

const StyledPaper = styled(Paper)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const StyledCard = styled(Paper)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  '&:hover': {
    boxShadow: theme.shadows[4],
  },
}));

const ModalContent = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[5],
  padding: theme.spacing(4),
  outline: 'none',
  maxHeight: '80vh',
  overflowY: 'auto',
}));

const KaizenReportsList = () => {
  const [kaizenReports, setKaizenReports] = useState([]);
  const [selectedKaizen, setSelectedKaizen] = useState(null);
  const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const [approvedBy, setApprovedBy] = useState('');
  const [dateOfImplementation, setDateOfImplementation] = useState(new Date());

  const [tabValue, setTabValue] = useState(0);
  const [imageOfVerification, setImageOfverification] = useState(null);
  const location = useLocation();
  const { userId, company_name } = location.state || {};
  console.log('kcomany',company_name);
  useEffect(() => {
    const fetchKaizenReports = async () => {
      const kaizenReportsRef = firestore.collection('company_data').doc(company_name).collection(company_name).doc('for_user').collection('kaizen_reports');
      const snapshot = await kaizenReportsRef.get();
      const kaizenData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
     
        // Sort work permits by case number (highest first)
     const sortedkaizenData = kaizenData.sort((a, b) => {
      // Assuming caseNumber is a string, we'll parse it to an integer
      const caseNumberA = parseInt(a.caseNumber, 10);
      const caseNumberB = parseInt(b.caseNumber, 10);
      return caseNumberB - caseNumberA; // For descending order
    });
     
      setKaizenReports(kaizenData);
    };
    fetchKaizenReports();
  }, [company_name]);

  const formatDate = (date) => {
    if (typeof date === 'string' && date.includes('-')) {
      return moment(date).format('MMMM D, YYYY');
    }
    if (date && date.seconds) {
      return moment(date.seconds * 1000 + date.nanoseconds / 1000000).format('MMMM D, YYYY');
    }
    return 'Invalid Date';
  };

  const handleDetailModalOpen = (kaizen) => {
    setSelectedKaizen(kaizen);
    setIsDetailModalOpen(true);
  };

  const handleDetailModalClose = () => {
    setIsDetailModalOpen(false);
  };

  const handleFormModalOpen = (kaizen) => {
    setSelectedKaizen(kaizen);
    setIsFormModalOpen(true);
  };

  const handleFormModalClose = () => {
    setIsFormModalOpen(false);
  };

  const uploadImage = async (file) => {
    if (!file) return null;

    const storageRef = firebase.storage().ref();
    const fileRef = storageRef.child(`kaizen_report_images/${Date.now()}_${file.name}`);

    try {
      await fileRef.put(file);
      const downloadURL = await fileRef.getDownloadURL();
      return downloadURL;
    } catch (error) {
      console.error('Error uploading image: ', error);
      throw error;
    }
  };

  const handleSubmit = async () => {
    if (!dateOfImplementation || !approvedBy) {
      alert('Please fill all required fields');
      return;
    }
    let imageUrl = null;
    if (imageOfVerification) {
      imageUrl = await uploadImage(imageOfVerification);
    }

    const data = {
      dateOfImplementation: new Date(dateOfImplementation),
      approvedBy,
      imageOfVerification: imageUrl,
      isPending: false,
    };

    try {
      await firestore.collection('company_data').doc(company_name).collection(company_name).doc('for_user').collection('kaizen_reports').doc(selectedKaizen.id).set(data, { merge: true });
      alert('Form submitted successfully');
      handleFormModalClose();
    } catch (error) {
      console.error('Error submitting form: ', error);
      alert('Failed to submit form');
    }
  };

  const handleClear = () => {
    setDateOfImplementation('');
    setApprovedBy('');
  };

  const handleImageChange = (e) => {
    if (e.target.files.length > 0) {
      setImageOfverification(e.target.files[0]);
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const filteredkaizenReports = kaizenReports.filter(w => 
    tabValue === 0 ? w.isPending : !w.isPending
  );

  return (
    <Box marginTop={12}>
      <Container maxWidth="lg">
      <Typography variant="h5" component="div">
        Kaizen Reports
      </Typography>

      <Tabs 
      value={tabValue} 
      onChange={handleTabChange} 
      centered>
        <Tab label="Pending" />
        <Tab label="Completed" />
      </Tabs>
      <Box mt={3}>
      <Grid container spacing={2}>
        {filteredkaizenReports.map((kaizen) => (
          <Grid item xs={12} sm={6} md={4} key={kaizen.id}>
            <StyledCard elevation={1}>
              <CardContent>
                <Typography><strong>Case Number:</strong> {kaizen.caseNumber}</Typography>
                <Typography><strong>Date:</strong> {formatDate(kaizen.kaizenIdentificationDate)}</Typography>
                <typography><strong>Area Name:</strong>{kaizen.areaName}</typography>
                <Typography><strong>Description:</strong> {kaizen.kaizenDescription}</Typography>
                <Typography color={kaizen.isPending ? "error" : "green"}><strong>Status:</strong> {kaizen.isPending ? 'Pending' : 'Completed'}</Typography>
                <Box mt={2}>
                  <Button variant="contained" color="primary" onClick={() => handleDetailModalOpen(kaizen)}>
                    See Form
                  </Button>
                  {kaizen.isPending &&
                  <Button variant="contained" color="secondary" onClick={() => handleFormModalOpen(kaizen)} style={{ marginLeft: '10px' }}>
                    Close Form
                  </Button>
                  }
                </Box>
              </CardContent>
            </StyledCard>
          </Grid>
        ))}
      </Grid>
      </Box>

      {/* Detail Modal */}
      <Modal
        open={isDetailModalOpen}
        onClose={handleDetailModalClose}
        aria-labelledby="detail-modal-title"
        aria-describedby="detail-modal-description"
      >
        <ModalContent>
          <IconButton
            aria-label="close"
            onClick={handleDetailModalClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
          {selectedKaizen && (
            <Box>
              <Typography id="detail-modal-title" variant="h6" gutterBottom>Kaizen Report Details</Typography>
              <Typography>Description: {selectedKaizen.kaizenDescription}</Typography>
              <Typography>Date: {formatDate(selectedKaizen.kaizenIdentificationDate)}</Typography>
              <Typography>Case Number: {selectedKaizen.caseNumber}</Typography>
              <Typography>Area Name: {selectedKaizen.areaName}</Typography>
              <Typography>Area Manager Name: {selectedKaizen.areaManagerName}</Typography>
              <Typography>Identified By: {selectedKaizen.identifiedBy}</Typography>
              <Typography>Source of Identification: {selectedKaizen.sourceOfIdentification.join(', ')}</Typography>
            </Box>
          )}
        </ModalContent>
      </Modal>

      {/* Form Modal */}
      <Modal
        open={isFormModalOpen}
        onClose={handleFormModalClose}
        aria-labelledby="form-modal-title"
        aria-describedby="form-modal-description"
      >
        <ModalContent>
          <IconButton
            aria-label="close"
            onClick={handleFormModalClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography id="form-modal-title" variant="h6" gutterBottom>Close Kaizen Report</Typography>
          <TextField
            fullWidth
            label="Approved By (Person's Name)*"
            value={approvedBy}
            onChange={(e) => setApprovedBy(e.target.value)}
            margin="normal"
          />
          {/* <TextField
            fullWidth
            label="Date of Implementation*"
            type="date"
            value={dateOfImplementation.toString()}
            onChange={(e) => setDateOfImplementation(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            margin="normal"
           // disabled={true}
          /> */}
          <TextField
                type="date"
                label="Date of Implementation*"
                value={moment(dateOfImplementation).format('YYYY-MM-DD')}
                onChange={(e) => setDateOfImplementation(e.target.value)}
                fullWidth
                margin="normal"
                disabled={true}
              />
          <Grid item xs={12} >
              <input
                accept="image/*"
                id="contained-button-file"
                type="file"
                onChange={handleImageChange}
                style={{ display: 'none' }}
              />
              <label htmlFor="contained-button-file">
                <Button variant="contained" color="primary" component="span">
                  Upload Image for Verification
                </Button>
              </label>
              {imageOfVerification && <Typography>{imageOfVerification.name}</Typography>}
            </Grid>
          <Box mt={2}>
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              Submit
            </Button>
            <Button variant="contained" onClick={handleClear} sx={{ marginLeft: '10px' }}>
              Clear
            </Button>
            <Button variant="outlined" onClick={handleFormModalClose} sx={{ marginLeft: '10px' }}>
              Back to List
            </Button>
          </Box>
        </ModalContent>
      </Modal>
    </Container>
    </Box>
    
  );
};

export default KaizenReportsList;