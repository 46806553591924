// constants.js
// Severity Color Mapping
export const SEVERITY_COLORS = {
  Low: { main: '#4CAF50', light: '#E8F5E9' },
  Medium: { main: '#FF9800', light: '#FFF3E0' },
  High: { main: '#F44336', light: '#FFEBEE' }
};

// Define colors for chart states
export const COLORS = {
    OPEN: '#4caf50', // Green color for "open"
    CLOSED: '#f44336', // Red color for "closed"
  };
  
  // Optionally, you can include additional colors or styles
  export const ADDITIONAL_COLORS = {
    PRIMARY: '#2196f3', // Example primary color
    SECONDARY: '#ff9800', // Example secondary color
  };
  