import React, { useState, useEffect } from 'react';
import {
  Typography,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Paper,
  Checkbox,
  FormControlLabel,
  FormGroup,
  CircularProgress,
  Box,
  Container,
  Snackbar,
  Alert,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { firestore } from '../../../firebase/firebaseConfig';
import ImageUploader from '../../../componants/ImageUploader';

import { workPermitTypeQuestionList, ppesList } from './../../../componants/workPermitTypeQuestionList';

import { Radio } from '@mui/material';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
}));

const ChecklistItem = ({ srNo, point, onChange, value }) => (
  <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
    <Typography sx={{ flex: '0 0 50px' }}>{srNo}.</Typography>
    <Typography sx={{ flex: 1 }}>{point}</Typography>
    {/* <FormGroup row>
      <FormControlLabel
        control={
          <Checkbox
            checked={value?.yes === 'yes'}
            onChange={(e) =>
              onChange(srNo, {
                yes: e.target.checked ? 'yes' : 'no',
                not_required: false, // Reset not_required if Yes is selected
              })
            }
          />
        }
        label="Yes"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={value?.yes === 'no'}
            onChange={(e) =>
              onChange(srNo, {
                yes: e.target.checked ? 'no' : 'yes',
                not_required: false, // Reset not_required if Yes is selected
              })
            }
          />
        }
        label="No"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={value?.not_required === true}
            onChange={(e) =>
              onChange(srNo, {
                not_required: e.target.checked,
                yes: e.target.checked ? 'no' : value?.yes, // Reset Yes if not_required is selected
              })
            }
          />
        }
        label="Not Req"
      />
    </FormGroup>
     */}
     <FormGroup row>
      <FormControlLabel
        control={
          <Checkbox
            checked={value?.yes === 'yes'}
            onChange={() =>
              onChange(srNo, {
                yes: 'yes',
                not_required: false,
              })
            }
          />
        }
        label="Yes"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={value?.yes === 'no'}
            onChange={() =>
              onChange(srNo, {
                yes: 'no',
                not_required: false,
              })
            }
          />
        }
        label="No"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={value?.not_required === true}
            onChange={() =>
              onChange(srNo, {
                not_required: true,
                yes: 'na', // Reset Yes/No when Not Required is selected
              })
            }
          />
        }
        label="Not Req"
      />
    </FormGroup>
  </Box>
);

const WorkPermitForm = ({ userId, email, company_name }) => {
  // Basic Information State
  const [basicInfo, setBasicInfo] = useState({
    plant: '',
    section: '',
    docNo: '',
    date: new Date(),
    timeFrom: null,
    timeTo: null,
    timeFrom_multyday: null,
    timeTo_multyday: null,
    jobDescription: '',
    locationOfWork: '',
    nameOfPerson: '',
    signature: null,
    workPermitType: '',
    isMultiDay: false,
    startDate: null,
    endDate: null,
  });

  // // Checklist States
  const [generalPrecautions, setGeneralPrecautions] = useState({});
  const [specialPrecautions, setSpecialPrecautions] = useState({});
  const [necessaryPPEs, setNecessaryPPEs] = useState({});
  
  // NOC States
  const [electricalNOC, setElectricalNOC] = useState({
    required: false,
    email: '',
    status: 'pending', // pending, requested, confirmed
  });
  
  const [mechanicalNOC, setMechanicalNOC] = useState({
    required: false,
    email: '',
    status: 'pending',
  });

  const [caseCount, setCaseCount] = useState(0);

  const [isLoading, setIsLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  
  useEffect(() => {
    const fetchData = async () => {
      const companyDoc = await firestore.collection('company_data').doc(company_name).get();
      if (companyDoc.exists) {
        setCaseCount(companyDoc.data().case_count || 0);
      }
    };

    fetchData();
  }, [company_name, userId]);

  const getPermitType = (type) => {
    switch(type) {
      case "Hot Work": return "HotWork";
      case "Work at Height": return "WorkAtHeight";
      case "Electrical Work": return "ElectricalWork";
      case "Confined Space": return "ConfinedSpace";
      default: return "";
    }
  };
useEffect(() => {
      if (basicInfo.workPermitType) {
        
        // const typeKey = basicInfo.workPermitType.replace(/\s+/g, ''); // Remove spaces for key matching
        const questions = workPermitTypeQuestionList[getPermitType(basicInfo.workPermitType)] || {};
        
        const initialGeneralPrecautions = {};
        const initialSpecialPrecautions = {};
        const initialPPEs = {};
  
        questions.generalPrecautionsList?.forEach((_, index) => {
          initialGeneralPrecautions[index + 1] = { yes: 'na', not_required: false };
        });
        questions.specialPrecautionsList?.forEach((_, index) => {
          initialSpecialPrecautions[index + 1] = { yes: 'na', not_required: false };
        });
        ppesList?.forEach((item, index) => {
          initialPPEs[index + 1] = { yes: 'na', not_required: false };
        });
  
        setGeneralPrecautions(initialGeneralPrecautions);
        setSpecialPrecautions(initialSpecialPrecautions);
        setNecessaryPPEs(initialPPEs);
      }
    }, [basicInfo.workPermitType]);


  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
  };

  const handleNOCRequest = async (type) => {
    const nocState = type === 'electrical' ? electricalNOC : mechanicalNOC;
    const setNOCState = type === 'electrical' ? setElectricalNOC : setMechanicalNOC;

    if (!validateEmail(nocState.email)) {
      setSnackbar({
        open: true,
        message: 'Please enter a valid email address',
        severity: 'error',
      });
      return;
    }

    try {
      // Here you would implement the email sending logic
      // For now, we'll just update the status
      setNOCState({
        ...nocState,
        status: 'requested',
      });

      setSnackbar({
        open: true,
        message: `NOC request sent to ${nocState.email}`,
        severity: 'success',
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Failed to send NOC request',
        severity: 'error',
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
  
    try {
      const workPermitRef = firestore
        .collection('company_data')
        .doc(company_name)
        .collection(company_name)
        .doc('for_user')
        .collection('work_permit_v2')
        .doc();

      const caseNumber = caseCount + 1;
  
      const workPermitData = {
        type: basicInfo.workPermitType,
        caseNumber: caseNumber,
        userId: userId,
        basic_info: {
          plant: basicInfo.plant,
          section: basicInfo.section,
          doc_no: basicInfo.docNo,
          date: basicInfo.isMultiDay ? null : basicInfo.date,
          start_time: basicInfo.isMultiDay ? null : basicInfo.timeFrom,
          end_time: basicInfo.isMultiDay ? null : basicInfo.timeTo,
          start_time_multyday: basicInfo.isMultiDay ? basicInfo.timeFrom_multyday : null,
          end_time_multyday: basicInfo.isMultiDay ? basicInfo.timeTo_multyday : null,
          startDate: basicInfo.isMultiDay ? basicInfo.startDate : null,
          endDate: basicInfo.isMultiDay ? basicInfo.endDate : null,
          job_description: basicInfo.jobDescription,
          location_of_work: basicInfo.locationOfWork,
          name_of_person: basicInfo.nameOfPerson,
          signature: basicInfo.signature,
          isMultiDay: basicInfo.isMultiDay,
        },
        general_precautions: generalPrecautions,
        special_precautions: specialPrecautions,
        necessary_ppes: necessaryPPEs,
        noc: {
          electrical: electricalNOC,
          mechanical: mechanicalNOC,
        },
        created_at: new Date(),
        created_by: userId,
        status: 'ongoing',
      };
  
      await workPermitRef.set(workPermitData);

      await firestore.collection('company_data').doc(company_name).update({
        case_count: caseNumber,
      });
  
      setSnackbar({
        open: true,
        message: 'Work permit submitted successfully',
        severity: 'success',
      });
  
      // Reset form
      setBasicInfo({
        plant: '',
        section: '',
        docNo: '',
        date: new Date(),
        timeFrom: null,
        timeTo: null,
        jobDescription: '',
        locationOfWork: '',
        nameOfPerson: '',
        signature: null,
        workPermitType: '',
        isMultiDay: false,
        startDate: null,
        endDate: null,
      });
      setGeneralPrecautions({});
      setSpecialPrecautions({});
      setNecessaryPPEs({});
      setElectricalNOC({ required: false, email: '', status: 'pending' });
      setMechanicalNOC({ required: false, email: '', status: 'pending' });
  
    } catch (error) {
      console.error('Error submitting work permit:', error);
      setSnackbar({
        open: true,
        message: 'Error submitting work permit',
        severity: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };

  // Function to dynamically render checklists
  const renderChecklist = (type) => {
    let t;
    if (type === "Hot Work"){
      t = "HotWork"
    }else if (type === "Work at Height"){
      t = "WorkAtHeight"
    }else if (type === "Electrical Work"){
      t = "ElectricalWork"
    }else if (type === "Confined Space"){
      t = "ConfinedSpace"
    }
    const { generalPrecautionsList, specialPrecautionsList } = workPermitTypeQuestionList[t] || {};
    return (
      <>
        <StyledPaper>
          <Typography variant="h5" gutterBottom>
            General Precautions
          </Typography>
          {generalPrecautionsList?.map((point, index) => (
            <ChecklistItem
              key={index}
              srNo={index + 1}
              point={point}
              value={generalPrecautions[index + 1] || { yes: 'no', not_required: false }}
              onChange={(srNo, updatedValues) =>
                setGeneralPrecautions((prev) => ({
                  ...prev,
                  [srNo]: updatedValues,
                }))
              }
            />
          ))}
          <Typography variant="h5" gutterBottom sx={{ mt: 3 }}>
            Special Precautions
          </Typography>
          {specialPrecautionsList?.map((point, index) => (
            <ChecklistItem
              key={index}
              srNo={index + 1}
              point={point}
              value={specialPrecautions[index + 1] || { yes: 'no', not_required: false }}
              onChange={(srNo, updatedValues) =>
                setSpecialPrecautions((prev) => ({
                  ...prev,
                  [srNo]: updatedValues,
                }))
              }
            />
          ))}
        </StyledPaper>
      </>
    );
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Container maxWidth="lg">
        <form onSubmit={handleSubmit}>
          {/* Basic Information Section */}
          <StyledPaper>
            <Typography variant="h5" gutterBottom>Basic Information</Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  label="Plant/Area"
                  value={basicInfo.plant}
                  onChange={(e) => setBasicInfo({ ...basicInfo, plant: e.target.value })}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  label="Section"
                  value={basicInfo.section}
                  onChange={(e) => setBasicInfo({ ...basicInfo, section: e.target.value })}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  label="Doc. No"
                  value={basicInfo.docNo}
                  onChange={(e) => setBasicInfo({ ...basicInfo, docNo: e.target.value })}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h6">Work Permit Type</Typography>
                   <FormGroup row>
                   <FormControlLabel
                    control={
          <Radio
          checked={!basicInfo.isMultiDay}
          onChange={() => setBasicInfo({ ...basicInfo, isMultiDay: false })}
          />
      }
      label="One Day"
    />
    <FormControlLabel
      control={
        <Radio
          checked={basicInfo.isMultiDay}
          onChange={() => setBasicInfo({ ...basicInfo, isMultiDay: true })}
        />
      }
      label="Multi-Day"
    />
     </FormGroup>
    </Grid>

    {!basicInfo.isMultiDay ? (
  <>
    <Grid item xs={12} md={4}>
      <DatePicker
        label="Date"
        value={basicInfo.date}
        onChange={(newDate) => setBasicInfo({ ...basicInfo, date: newDate })}
        renderInput={(params) => <TextField {...params} fullWidth />}
      />
    </Grid>
    <Grid item xs={12} md={4}>
      <TimePicker
        label="Time From"
        value={basicInfo.timeFrom}
        onChange={(newTime) => setBasicInfo({ ...basicInfo, timeFrom: newTime })}
        renderInput={(params) => <TextField {...params} fullWidth />}
      />
    </Grid>
    <Grid item xs={12} md={4}>
      <TimePicker
        label="Time To"
        value={basicInfo.timeTo}
        onChange={(newTime) => setBasicInfo({ ...basicInfo, timeTo: newTime })}
        renderInput={(params) => <TextField {...params} fullWidth />}
      />
    </Grid>
  </>
) : (
  <>
    <Grid item xs={12} md={3}>
      <DatePicker
        label="Start Date"
        value={basicInfo.startDate}
        onChange={(startDate) => setBasicInfo({ ...basicInfo, startDate })}
        renderInput={(params) => <TextField {...params} fullWidth />}
      />
    </Grid>
    <Grid item xs={12} md={3}>
      <TimePicker
        label="Time From"
        value={basicInfo.timeFrom_multyday}
        onChange={(newTime) => setBasicInfo({ ...basicInfo, timeFrom_multyday: newTime })}
        renderInput={(params) => <TextField {...params} fullWidth />}
      />
    </Grid>
    <Grid item xs={12} md={3}>
      <DatePicker
        label="End Date"
        value={basicInfo.endDate}
        onChange={(endDate) => setBasicInfo({ ...basicInfo, endDate })}
        renderInput={(params) => <TextField {...params} fullWidth />}
      />
    </Grid>
    <Grid item xs={12} md={3}>
      <TimePicker
        label="Time To"
        value={basicInfo.timeTo_multyday}
        onChange={(newTime) => setBasicInfo({ ...basicInfo, timeTo_multyday: newTime })}
        renderInput={(params) => <TextField {...params} fullWidth />}
      />
    </Grid>
  </>
)}
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Job Description"
                  multiline
                  rows={3}
                  value={basicInfo.jobDescription}
                  onChange={(e) => setBasicInfo({ ...basicInfo, jobDescription: e.target.value })}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Location of Work (Equipment/Tank/Line/Vessel)"
                  value={basicInfo.locationOfWork}
                  onChange={(e) =>
                    setBasicInfo({ ...basicInfo, locationOfWork: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Name of the Person/Contractor Assigned for Job"
                  value={basicInfo.nameOfPerson}
                  onChange={(e) =>
                    setBasicInfo({ ...basicInfo, nameOfPerson: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <ImageUploader
                  label="Upload Signature"
                  onUpload={(fileUrl) =>
                    setBasicInfo({ ...basicInfo, signature: fileUrl })
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  {/* <InputLabel>Type of Work Permit</InputLabel> */}
                  {/* <Select
                    fullWidth
                    //label="Type of Work Permit"
                    placeholder='Select type of Work Permit'
                    value={basicInfo.workPermitType}
                    onChange={(e) =>
                      setBasicInfo({ ...basicInfo, workPermitType: e.target.value })
                    }
                  >
                    <MenuItem value="Hot Work">Hot Work</MenuItem>
                    <MenuItem value="Work at Height">Work at Height</MenuItem>
                    <MenuItem value="Electrical Work">Electrical Work</MenuItem>
                    <MenuItem value="Confined Space">Confined Space</MenuItem>
                  </Select> */}
                  <InputLabel id="work-permit-label">Type of Work Permit</InputLabel>
                  <Select
                    labelId="work-permit-label"
                    value={basicInfo.workPermitType || ""}
                    onChange={(e) =>
                      setBasicInfo({ ...basicInfo, workPermitType: e.target.value })
                    }
                  >
                    <MenuItem value="" disabled>
                      Select type of Work Permit
                    </MenuItem>
                    <MenuItem value="Hot Work">Hot Work</MenuItem>
                    <MenuItem value="Work at Height">Work at Height</MenuItem>
                    <MenuItem value="Electrical Work">Electrical Work</MenuItem>
                    <MenuItem value="Confined Space">Confined Space</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </StyledPaper>

          {/* Dynamic Checklists */}
          {basicInfo.workPermitType && renderChecklist(basicInfo.workPermitType)}

          {/* Necessary PPEs */}
          <StyledPaper>
            <Typography variant="h5" gutterBottom>
              Necessary PPEs
            </Typography>
            {ppesList.map((ppe, index) => (
              <ChecklistItem
                key={index}
                srNo={index + 1}
                point={ppe}
                value={necessaryPPEs[index + 1]}
                    onChange={(srNo, updatedValues) => {
                      setNecessaryPPEs((prev) => ({
        ...prev,
        [srNo]: {
          ...prev[srNo],
          ...updatedValues,
          verified: false, // Ensure verified is initially false
        },
      }));
    }}
              />
            ))}
          </StyledPaper>

          {/* NOC Section */}
          <StyledPaper>
            <Typography variant="h5" gutterBottom>
              NOC Requirements
            </Typography>
            {['electrical', 'mechanical'].map((type) => {
              const nocState = type === 'electrical' ? electricalNOC : mechanicalNOC;
              const setNOCState = type === 'electrical' ? setElectricalNOC : setMechanicalNOC;
              return (
                <Box key={type} sx={{ mb: 3 }}>
                  <Typography>{`N.O.C. from ${type.charAt(0).toUpperCase() + type.slice(1)} Department`}</Typography>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={nocState.required}
                        onChange={(e) =>
                          setNOCState({ ...nocState, required: e.target.checked })
                        }
                      />
                    }
                    label="Required"
                  />
                  {nocState.required && (
                    <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                      <TextField
                        label="Email"
                        fullWidth
                        value={nocState.email}
                        onChange={(e) =>
                          setNOCState({ ...nocState, email: e.target.value })
                        }
                      />
                      <Button
                        sx={{ ml: 2 }}
                        variant="contained"
                        onClick={() => handleNOCRequest(type)}
                        disabled={nocState.status !== 'pending'}
                      >
                        {nocState.status === 'requested'
                          ? 'Waiting for N.O.C.'
                          : nocState.status === 'confirmed'
                          ? 'N.O.C. Confirmed'
                          : 'Request for N.O.C.'}
                      </Button>
                    </Box>
                  )}
                </Box>
              );
            })}
          </StyledPaper>

          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={isLoading}
              startIcon={isLoading && <CircularProgress size={20} />}
            >
              Submit Work Permit
            </Button>
          </Box>
        </form>
        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
        >
          <Alert
            onClose={() => setSnackbar({ ...snackbar, open: false })}
            severity={snackbar.severity}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Container>
    </LocalizationProvider>
  );
};
export default WorkPermitForm;
