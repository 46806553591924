import React, { useState } from "react";
import { Box, Tabs, Tab, Typography } from "@mui/material";

// Import your chart components here
import NearMissChart from "../charts/NearMissChart";
import UnsafeActChart from "../charts/UnsafeActChart";
import AccidentReportChart from "../charts/AccidentReportChart";
import WeeklySafetyMeetingChart from "../charts/WeeklySafetyMeetingChart";
import FireInjuryChart from "../charts/FireInjuryChart";
import PPEGrievancesChart from "../charts/PPEGrievancesChart";
import KaizenReportChart from "../charts/KaizenReportChart";
import WorkPermitChart from "../charts/WorkPermitChart";

const Dashboard = ({ company_name, permissions }) => {
  const [activeTab, setActiveTab] = useState(0);

  const reportTypes = [
    {
      name: "Unsafe Act Report",
      component: UnsafeActChart,
      permission: "Unsafe Act",
    },
    {
      name: "Near Miss Report",
      component: NearMissChart,
      permission: "Near Miss Report",
    },
    {
      name: "Fire Injury Report",
      component: FireInjuryChart,
      permission: "Fire Injury",
    },
    {
      name: "Accident Report",
      component: AccidentReportChart,
      permission: "Accident Report",
    },
    {
      name: "Work Permit",
      component: WorkPermitChart,
      permission: "Work Permit",
    },
    {
      name: "Kaizen Report",
      component: KaizenReportChart,
      permission: "Kaizen Reports",
    },
    {
      name: "PPE Grievances Report",
      component: PPEGrievancesChart,
      permission: "PPE Grievances",
    },
    {
      name: "Weekly Safety Meeting",
      component: WeeklySafetyMeetingChart,
      permission: "Weekly Safety Meeting",
    }
  ];

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const filteredReports = reportTypes.filter(report =>
    permissions.includes(report.permission)
  );

  const ActiveChartComponent = filteredReports[activeTab]?.component;

  return (
    <Box sx={{ width: '100%',mt:8}}>
      <Box>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="report tabs"
        >
          {filteredReports.map((report, index) => (
            <Tab
              key={index}
              label={report.name}
              sx={{
            "&.Mui-selected": {
              backgroundColor: "black",
              color: "white",
            },
            transition: 'transform 0.3s ease-in-out',
        '&:hover': {
          transform: 'scale(1.08)',
          backgroundColor: "white",
          color:'black'
        },
          }}
            />
          ))}
        </Tabs>
      </Box>

        {ActiveChartComponent && (
          <ActiveChartComponent company_name={company_name}/>
        )}
  
    </Box>
  );
};

export default Dashboard;

