// import React, { useState } from 'react';
// import { makeStyles } from '@material-ui/core/styles';
// // import firebase from 'firebase/compat/app';
// import {
//   Typography,
//   Box,
//   Grid,
//   TextField,
//   MenuItem,
//   Button,
//   IconButton,
// } from '@material-ui/core';
// import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
// import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
// // import 'firebase/compat/firestore';
// // import 'firebase/auth';

// // const firestore = firebase.firestore();
// import { firestore } from '../../firebase/firebaseConfig';
// const useStyles = makeStyles((theme) => ({
//   container: {
//     padding: theme.spacing(2),
//   },
//   zoneContainer: {
//     padding: theme.spacing(2),
//     border: `1px solid ${theme.palette.grey[300]}`,
//     borderRadius: theme.shape.borderRadius,
//     marginBottom: theme.spacing(2),
//   },
//   label: {
//     fontWeight: 'bold',
//     marginBottom: theme.spacing(1),
//   },
//   actionButtons: {
//     display: 'flex',
//     justifyContent: 'flex-end',
//     marginTop: theme.spacing(2),
//   },
//   error: {
//     color: theme.palette.error.main,
//     marginTop: theme.spacing(0.5),
//   },
// }));

// const CompanyZones = ({ companyName }) => {
//   const classes = useStyles();
//   const [zones, setZones] = useState([
//     {
//       name: '',
//       alias: '',
//       subZone: '',
//       category: '',
//       remark: '',
//     },
//   ]);
//   const [errors, setErrors] = useState({});

//   const handleAddZone = () => {
//     setZones([
//       ...zones,
//       {
//         name: '',
//         alias: '',
//         subZone: '',
//         category: '',
//         remark: '',
//       },
//     ]);
//   };

//   const handleSubmit = async () => {
//     const errors = validateFields();
//     if (Object.keys(errors).length > 0) {
//       setErrors(errors);
//       alert('Please fill in all mandatory fields');
//       return;
//     }

// //     try {
// //       // Implement your logic to save company zones here
// //       console.log('Company Zones:', zones);
// //       alert('Your data has been submitted successfully!');
// //       clearForm();
// //     } catch (error) {
// //       console.error('Error saving company zones:', error);
// //       alert('Error occurred while submitting data. Please try again.');
// //     }
// //   };

// try {
//     const savedZoneIds = [];
//     if (Array.isArray(zones) && zones.length > 0) {
//       for (const zone of zones) {
//         const savedZone = await firestore
//           .collection('company_data')
//           .doc(companyName)
//           .collection(companyName)
//           .doc('for_admin')
//           .collection('company_zones')
//           .add({
//             name: zone.name,
//             alias: zone.alias,
//             subZone: zone.subZone,
//             category: zone.category,
//             remark: zone.remark,
//           });
//         savedZoneIds.push(savedZone.id);
//       }
//     }
//     console.log('Company Zones saved with IDs:', savedZoneIds);
//     alert('Your data has been submitted successfully!');
//     clearForm();
//   } catch (error) {
//     console.error('Error saving company zones:', error);
//     alert('Error occurred while submitting data. Please try again.');
//   }
// };

//   const validateFields = () => {
//     const errors = {};

//     zones.forEach((zone, index) => {
//       if (!zone.name.trim()) {
//         errors[`name-${index}`] = 'Name is required';
//       }
//       if (!zone.category.trim()) {
//         errors[`category-${index}`] = 'Category is required';
//       }
//     });

//     return errors;
//   };

//   const clearForm = () => {
//     setZones([
//       {
//         name: '',
//         alias: '',
//         subZone: '',
//         category: '',
//         remark: '',
//       },
//     ]);
//     setErrors({});
//   };

//   const handleZoneChange = (value, field, index) => {
//     const updatedZones = [...zones];
//     updatedZones[index][field] = value;
//     setZones(updatedZones);
//     setErrors({ ...errors, [`${field}-${index}`]: '' }); // Clear error for this field
//   };

//   const handleRemoveZone = (index) => {
//     const updatedZones = [...zones];
//     updatedZones.splice(index, 1);
//     setZones(updatedZones);
//   };

//   return (
//     <Box sx={{ width:"100%", marginTop:4, paddingLeft:2,paddingRight:2}}>
//       <Typography variant="h5" gutterBottom>
//         Company Zones
//       </Typography>
//       {zones.map((zone, index) => (
//         <Box key={index} className={classes.zoneContainer}>
//           <Typography variant="subtitle1" className={classes.label}>
//             Zone {index + 1}
//           </Typography>
//           <Grid container spacing={2}>
//             <Grid item xs={12}>
//               <Typography variant="body1" className={classes.label}>
//                 Name *
//               </Typography>
//               <TextField
//                 fullWidth
//                 value={zone.name}
//                 onChange={(e) => handleZoneChange(e.target.value, 'name', index)}
//                 error={!!errors[`name-${index}`]}
//                 helperText={errors[`name-${index}`]}
//               />
//             </Grid>
//             <Grid item xs={12}>
//               <Typography variant="body1" className={classes.label}>
//                 Alias (optional)
//               </Typography>
//               <TextField
//                 fullWidth
//                 value={zone.alias}
//                 onChange={(e) => handleZoneChange(e.target.value, 'alias', index)}
//               />
//             </Grid>
//             <Grid item xs={12}>
//               <Typography variant="body1" className={classes.label}>
//                 Sub Zone (optional)
//               </Typography>
//               <TextField
//                 fullWidth
//                 value={zone.subZone}
//                 onChange={(e) => handleZoneChange(e.target.value, 'subZone', index)}
//               />
//             </Grid>
//             <Grid item xs={12}>
//               <Typography varnpm iant="body1" className={classes.label}>
//                 Category *
//               </Typography>
//               <TextField
//                 fullWidth
//                 select
//                 value={zone.category}
//                 onChange={(e) => handleZoneChange(e.target.value, 'category', index)}
//                 error={!!errors[`category-${index}`]}
//                 helperText={errors[`category-${index}`]}
//               >
//                 <MenuItem value="red">Red</MenuItem>
//                 <MenuItem value="orange">Orange</MenuItem>
//               </TextField>
//             </Grid>
//             <Grid item xs={12}>
//               <Typography variant="body1" className={classes.label}>
//                 Remark
//               </Typography>
//               <TextField
//                 fullWidth
//                 value={zone.remark}
//                 onChange={(e) => handleZoneChange(e.target.value, 'remark', index)}
//               />
//             </Grid>
//           </Grid>
//           <Box className={classes.actionButtons}>
//             {index === zones.length - 1 && (
//               <>
//                 <IconButton onClick={handleAddZone}>
//                   <AddCircleOutlineIcon />
//                 </IconButton>
//                 {index > 0 && (
//                   <IconButton onClick={() => handleRemoveZone(index)}>
//                     <RemoveCircleOutlineIcon />
//                   </IconButton>
//                 )}
//               </>
//             )}
//           </Box>
//         </Box>
//       ))}
//       <Button variant="contained" color="primary" onClick={handleSubmit}>
//         Submit
//       </Button>
//     </Box>
//   );
// };

// export default CompanyZones;

import React, { useState } from 'react';
import { 
  Typography, 
  Box, 
  Grid, 
  TextField, 
  MenuItem, 
  Button, 
  IconButton, 
  Card, 
  CardContent,
  CardActions 
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { firestore } from '../../firebase/firebaseConfig';

const ZONE_CATEGORIES = [
  { value: 'red', label: 'Red' },
  { value: 'orange', label: 'Orange' },
  { value: 'green', label: 'Green' },
];

const CompanyZones = ({ companyName }) => {
  const [zones, setZones] = useState([
    {
      name: '',
      alias: '',
      subZone: '',
      category: '',
      remark: '',
    },
  ]);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleAddZone = () => {
    setZones(prevZones => [
      ...prevZones,
      {
        name: '',
        alias: '',
        subZone: '',
        category: '',
        remark: '',
      },
    ]);
  };

  const handleSubmit = async () => {
    // Validate fields
    const validationErrors = validateFields();
    
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    // Prevent multiple submissions
    if (isSubmitting) return;

    setIsSubmitting(true);

    try {
      const savedZoneIds = [];
      
      for (const zone of zones) {
        const savedZone = await firestore
          .collection('company_data')
          .doc(companyName)
          .collection(companyName)
          .doc('for_admin')
          .collection('company_zones')
          .add({
            name: zone.name.trim(),
            alias: zone.alias.trim(),
            subZone: zone.subZone.trim(),
            category: zone.category,
            remark: zone.remark.trim(),
          });
        
        savedZoneIds.push(savedZone.id);
      }

      // Success notification
      alert('Zones saved successfully!');
      
      // Reset form
      clearForm();
    } catch (error) {
      console.error('Error saving company zones:', error);
      alert('Failed to save zones. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const validateFields = () => {
    const errors = {};

    zones.forEach((zone, index) => {
      if (!zone.name.trim()) {
        errors[`name-${index}`] = 'Zone name is required';
      }
      if (!zone.category) {
        errors[`category-${index}`] = 'Category is required';
      }
    });

    return errors;
  };

  const clearForm = () => {
    setZones([
      {
        name: '',
        alias: '',
        subZone: '',
        category: '',
        remark: '',
      },
    ]);
    setErrors({});
  };

  const handleZoneChange = (value, field, index) => {
    setZones(prevZones => {
      const updatedZones = [...prevZones];
      updatedZones[index] = {
        ...updatedZones[index],
        [field]: value
      };
      return updatedZones;
    });

    // Clear specific field error
    setErrors(prevErrors => {
      const newErrors = {...prevErrors};
      delete newErrors[`${field}-${index}`];
      return newErrors;
    });
  };

  const handleRemoveZone = (index) => {
    setZones(prevZones => {
      const updatedZones = [...prevZones];
      updatedZones.splice(index, 1);
      return updatedZones;
    });
  };

  return (
    <Card sx={{ width: "100%", mt: 2 }}>
      <CardContent>
        <Typography variant="h5" gutterBottom>
          Company Zones
        </Typography>

        {zones.map((zone, index) => (
          <Box key={index} sx={{ 
            border: 1, 
            borderColor: 'grey.300', 
            borderRadius: 2, 
            p: 2, 
            mb: 2 
          }}>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 2 }}>
              Zone {index + 1}
            </Typography>

            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Name *"
                  value={zone.name}
                  onChange={(e) => handleZoneChange(e.target.value, 'name', index)}
                  error={!!errors[`name-${index}`]}
                  helperText={errors[`name-${index}`]}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Alias (optional)"
                  value={zone.alias}
                  onChange={(e) => handleZoneChange(e.target.value, 'alias', index)}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Sub Zone (optional)"
                  value={zone.subZone}
                  onChange={(e) => handleZoneChange(e.target.value, 'subZone', index)}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  select
                  label="Category *"
                  value={zone.category}
                  onChange={(e) => handleZoneChange(e.target.value, 'category', index)}
                  error={!!errors[`category-${index}`]}
                  helperText={errors[`category-${index}`]}
                >
                  {ZONE_CATEGORIES.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Remark (optional)"
                  multiline
                  rows={2}
                  value={zone.remark}
                  onChange={(e) => handleZoneChange(e.target.value, 'remark', index)}
                />
              </Grid>
            </Grid>

            {/* Action Buttons for Last Zone */}
            {index === zones.length - 1 && (
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                <IconButton onClick={handleAddZone} color="primary">
                  <AddCircleOutlineIcon />
                </IconButton>
                {zones.length > 1 && (
                  <IconButton onClick={() => handleRemoveZone(index)} color="secondary">
                    <RemoveCircleOutlineIcon />
                  </IconButton>
                )}
              </Box>
            )}
          </Box>
        ))}
      </CardContent>

      <CardActions sx={{ justifyContent: 'flex-end', p: 2 }}>
        <Button 
          variant="contained" 
          color="primary" 
          onClick={handleSubmit}
          disabled={isSubmitting}
        >
          {isSubmitting ? 'Submitting...' : 'Submit Zones'}
        </Button>
      </CardActions>
    </Card>
  );
};

export default CompanyZones;