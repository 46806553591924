import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Chip,
  Button,
  Divider,
  Modal,
  IconButton,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Tabs,
  Tab,
  Container,
  Grid,
  useTheme,
  Paper,
} from "@mui/material";
import { styled } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import moment from "moment";
import { firestore } from "../../firebase/firebaseConfig";
import { useLocation } from "react-router-dom";
import firebase from "firebase/compat/app";
import "firebase/compat/storage";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { format } from "date-fns";
import { makeStyles } from "@material-ui/core";
import DataTable from "../../componants/common/DataTable";
import StatusCard from "../../componants/StatusCard";

const useStyles = makeStyles((theme) => ({
  modalContent: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: 800,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    outline: 'none',
    maxHeight: '80vh',
    overflowY: 'auto',
  },
  formSection: {
    marginBottom: theme.spacing(3),
  },
  actionPlanForm: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const StyledCard = styled(Paper)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  '&:hover': {
    boxShadow: theme.shadows[4],
  },
}));


const NearMissReportList = () => {
  // ... (keep all existing state variables and functions)
  const classes = useStyles();
    // ... (all state variables and functions remain the same)

  const [reports, setReports] = useState([]);
  const [selectedReport, setSelectedReport] = useState(null);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const [isInvestigationModalOpen, setIsInvestigationModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [date, setDate] = useState(new Date());
  const [tabValue, setTabValue] = useState(0);
  const [imageOfVerification, setImageOfVerification] = useState(null);
  const location = useLocation();
  const { company_name } = location.state || {};
  const [investigationTeam, setInvestigationTeam] = useState("");
  const [dateOfInvestigation, setDateOfInvestigation] = useState(new Date());
  const [timeOfInvestigation, setTimeOfInvestigation] = useState(new Date());
  const [rootCause, setRootCause] = useState("");
  const [actionPlans, setActionPlans] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    const fetchReports = async () => {
      const reportsRef = firestore
        .collection("company_data")
        .doc(company_name)
        .collection(company_name)
        .doc("for_user")
        .collection("near_miss_report");
      const snapshot = await reportsRef.get();
      const reportsData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      const sortedReportsData = reportsData.sort((a, b) => {
        const caseNumberA = parseInt(a.caseNumber, 10);
        const caseNumberB = parseInt(b.caseNumber, 10);
        return caseNumberB - caseNumberA;
      });

      setReports(sortedReportsData);
    };
    fetchReports();
  }, [company_name]);

  const formatDate = (timestamp) => {
    if (typeof timestamp === 'string' && timestamp.includes('-')) {
      return timestamp;
    }
    if (timestamp && timestamp.seconds) {
      return moment(timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000).format('MMMM D, YYYY');
    }
    return 'Invalid Date';
  };

  const formatTime = (timestamp) => {
    if (timestamp && timestamp.seconds) {
      return moment(timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000).format('h:mm A');
    }
    return 'Invalid Time';
  };

  const handleDetailsModalOpen = (report) => {
    setSelectedReport(report);
    setIsDetailsModalOpen(true);
  };

  const handleFormModalOpen = (report) => {
    setSelectedReport(report);
    setIsFormModalOpen(true);
  };

  const handleInvestigationModalOpen = (report) => {
    setSelectedReport(report);
    setIsInvestigationModalOpen(true);
    if (report.DateOfInvestigation) {
      setInvestigationTeam(report.InvestigationTeam || "");
      setDateOfInvestigation(report.DateOfInvestigation.toDate());
      setTimeOfInvestigation(report.TimeOfInvestigation.toDate());
      setRootCause(report.rootCause || "");
      setActionPlans(report.actionPlans || []);
      setIsEditMode(true);
    } else {
      setInvestigationTeam("");
      setDateOfInvestigation(new Date());
      setTimeOfInvestigation(new Date());
      setRootCause("");
      setActionPlans([]);
      setIsEditMode(false);
    }
  };

  const handleModalClose = () => {
    setIsDetailsModalOpen(false);
    setIsFormModalOpen(false);
    setIsInvestigationModalOpen(false);
    setSelectedReport(null);
  };

  const handleDateChange = (newDate) => {
    setDate(newDate);
  };

  const uploadImage = async (file) => {
    if (!file) return null;

    const storageRef = firebase.storage().ref();
    const fileRef = storageRef.child(
      `near_miss_report_images/${Date.now()}_${file.name}`
    );

    try {
      await fileRef.put(file);
      const downloadURL = await fileRef.getDownloadURL();
      return downloadURL;
    } catch (error) {
      console.error("Error uploading image: ", error);
      throw error;
    }
  };

  const handleCloseNearMissReport = async () => {
    setIsLoading(true);
    let imageUrl = null;
    if (imageOfVerification) {
      imageUrl = await uploadImage(imageOfVerification);
    }
    try {
      const nearMissReportRef = firestore
        .collection("company_data")
        .doc(company_name)
        .collection(company_name)
        .doc("for_user")
        .collection("near_miss_report")
        .doc(selectedReport.id);
      await nearMissReportRef.update({
        isPending: false,
        dateOfClosing: date,
        imageOfVerification: imageUrl,
      });
      setIsLoading(false);
      handleModalClose();
    } catch (error) {
      console.error("Error updating near miss report:", error);
      setIsLoading(false);
    }
  };

  const handleImageChange = (e) => {
    if (e.target.files.length > 0) {
      setImageOfVerification(e.target.files[0]);
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const filteredReports = reports.filter((w) =>
    tabValue === 0 ? w.isPending : !w.isPending
  );

  const handleAddActionPlan = () => {
    if (actionPlans.length < 5) {
      setActionPlans([
        ...actionPlans,
        {
          TypeOfActionPlan: "",
          ResponsiblePerson: "",
          ApDescription: "",
          TargetDate: new Date(),
          status: "Open",
        },
      ]);
    }
  };

  const handleRemoveActionPlan = (index) => {
    const newActionPlans = actionPlans.filter((_, i) => i !== index);
    setActionPlans(newActionPlans);
  };

  const handleActionPlanChange = (index, field, value) => {
    const newActionPlans = [...actionPlans];
    newActionPlans[index][field] = value;
    setActionPlans(newActionPlans);
  };

  const handleSubmitInvestigation = async () => {
    setIsLoading(true);
    try {
      const nearMissReportRef = firestore
        .collection("company_data")
        .doc(company_name)
        .collection(company_name)
        .doc("for_user")
        .collection("near_miss_report")
        .doc(selectedReport.id);
      await nearMissReportRef.update({
        InvestigationTeam: investigationTeam,
        DateOfInvestigation: dateOfInvestigation,
        TimeOfInvestigation: timeOfInvestigation,
        rootCause: rootCause,
        actionPlans: actionPlans,
      });
      setIsLoading(false);
      handleModalClose();
    } catch (error) {
      console.error("Error updating investigation details:", error);
      setIsLoading(false);
    }
  };

  const canCloseNearMissReport = () => {
    return (
      actionPlans.length !== 0 &&
      actionPlans.every((plan) => plan.status === "Close")
    );
  };

  const getStatusColor = (severityLevel) => {
    switch (severityLevel) {
      case "minor":
        return "#FFD700";
      case "major":
        return "#FFA500";
      case "fatal":
        return "#FF4500";
      default:
        return "#000000";
    }
  };

  const severityLevel = "minor";
  const borderColor = getStatusColor(severityLevel);

  const formatTimestamp = (timestamp) => {
    if (timestamp && timestamp.seconds) {
      const date = new Date(timestamp.seconds * 1000);
      return format(date, "dd-MM-yyyy HH:mm:ss");
    }
    return "";
  };

  const handleToggleExpand = () => {
    setExpanded(!expanded);
  };
  // const theme = useTheme();
  // const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  // const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));

  const maxLength = 10;

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box sx={{mt:8}}>
        <Container maxWidth="lg">
          <Tabs value={tabValue} onChange={handleTabChange} centered>
            <Tab label="Pending" />
            <Tab label="Completed" />
          </Tabs>
          <Box mt={3}>
            <Grid container spacing={2}>
              {filteredReports.map((report) => (
                <Grid item xs={12} sm={6} md={6} key={report.id}>
                  <StyledCard elevation={1}>
                    <CardContent>
                      <Typography><strong>Case Number:</strong> {report.caseNumber}</Typography>
                      <Typography><strong>Reported on:</strong> {formatDate(report.dateOfNearMiss)}</Typography>
                      <Typography><strong>Area Name:</strong> {report.areaName}</Typography>
                      <Typography><strong>Description:</strong> 
                      {report.descriptionOfNearMiss.length > 40 
                      ?`${report.descriptionOfNearMiss.substring(0, 100)}...`
                      : report.descriptionOfNearMiss}
                      </Typography>
                      <Typography color={report.isPending ? 'error' : 'green'}><strong>Status:</strong>{report.isPending ? 'Pending' : 'Completed'}</Typography>
                      <Box mt={2}>
                        <Button variant="contained" color="primary" onClick={() => handleDetailsModalOpen(report)} style={{ marginRight: '10px' }}>
                          View Details
                        </Button>
                        {report.isPending && (
                          <Button variant="contained" color="secondary" onClick={() => handleInvestigationModalOpen(report)} style={{ marginRight: '10px' }}>
                            Investigate
                          </Button>
                        )}
                        {report.isPending && (
                          <Button variant="contained" color="secondary" onClick={() => handleFormModalOpen(report)}>
                            Close Form
                          </Button>
                        )}
                      </Box>
                    </CardContent>
                  </StyledCard>
                </Grid>
              ))}
            </Grid>
          </Box>

          {/* Details Modal */}
          <Modal
            open={isDetailsModalOpen}
            onClose={handleModalClose}
            aria-labelledby="near-miss-details-modal-title"
          >
            <Box className={classes.modalContent}>
              <IconButton
                aria-label="close"
                onClick={handleModalClose}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                }}
              >
                <CloseIcon />
              </IconButton>
              {selectedReport && (
                <Box>
                  <Typography id="near-miss-details-modal-title" variant="h6" gutterBottom>Near Miss Details</Typography>
                  <Typography>Description: {selectedReport.descriptionOfNearMiss}</Typography>
                  <Typography>Date: {formatDate(selectedReport.dateOfNearMiss)}</Typography>
                  <Typography>Case Number: {selectedReport.caseNumber}</Typography>
                  <Typography>Area Name: {selectedReport.areaName}</Typography>
                  <Typography>Department Name: {selectedReport.departmentName}</Typography>
                  <Typography>Employee ID: {selectedReport.employeeId}</Typography>
                  <Typography>Reporting Person Name: {selectedReport.reportingPersonName}</Typography>
                  <Typography>Reporting Person Designation: {selectedReport.reportingPersonDesignation}</Typography>
                  <Box mt={2}>
                    <Button variant="outlined" onClick={handleModalClose}>
                      Back to list
                    </Button>
                  </Box>
                </Box>
              )}
            </Box>
          </Modal>

          {/* Investigation Modal */}
          <Modal
            open={isInvestigationModalOpen}
            onClose={handleModalClose}
            aria-labelledby="near-miss-investigation-modal-title"
          >
            <Box className={classes.modalContent}>
              <IconButton
                aria-label="close"
                onClick={handleModalClose}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                }}
              >
                <CloseIcon />
              </IconButton>
              {selectedReport && (
                <Box>
                  <Typography id="near-miss-investigation-modal-title" variant="h6" gutterBottom>Near Miss Investigation</Typography>
                  <TextField
                    label="Investigation Team"
                    value={investigationTeam}
                    onChange={(e) => setInvestigationTeam(e.target.value)}
                    fullWidth
                    margin="normal"
                    disabled={isEditMode && !isLoading}
                  />
                  <DatePicker
                    label="Date of Investigation"
                    value={dateOfInvestigation}
                    onChange={setDateOfInvestigation}
                    renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
                    disabled={isEditMode && !isLoading}
                  />
                  <TimePicker
                    label="Time of Investigation"
                    value={timeOfInvestigation}
                    onChange={setTimeOfInvestigation}
                    renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
                    disabled={isEditMode && !isLoading}
                  />
                  <TextField
                    label="Root Cause"
                    value={rootCause}
                    onChange={(e) => setRootCause(e.target.value)}
                    fullWidth
                    multiline
                    rows={4}
                    margin="normal"
                    disabled={isEditMode && !isLoading}
                  />
                  <Typography variant="h6" gutterBottom>Action Plans</Typography>
                  {actionPlans.map((plan, index) => (
                    <Box key={index} className={classes.actionPlanForm}>
                      <FormControl fullWidth margin="normal">
                        <InputLabel>Type of Action Plan</InputLabel>
                        <Select
                          value={plan.TypeOfActionPlan}
                          onChange={(e) => handleActionPlanChange(index, 'TypeOfActionPlan', e.target.value)}
                          disabled={isEditMode && !isLoading}
                        >
                          <MenuItem value="corrective">Corrective AP</MenuItem>
                          <MenuItem value="preventive">Preventive AP</MenuItem>
                        </Select>
                      </FormControl>
                      <TextField
                        label="Action plan description"
                        value={plan.ApDescription}
                        onChange={(e) => handleActionPlanChange(index, 'ApDescription', e.target.value)}
                        fullWidth
                        multiline
                        rows={2}
                        margin="normal"
                        disabled={isEditMode && !isLoading}
                      />
                      <TextField
                        label="Responsible Person"
                        value={plan.ResponsiblePerson}
                        onChange={(e) => handleActionPlanChange(index, 'ResponsiblePerson', e.target.value)}
                        fullWidth
                        margin="normal"
                        disabled={isEditMode && !isLoading}
                      />
                      <DatePicker
                        label="Target Date"
                        value={plan.TargetDate}
                        onChange={(date) => handleActionPlanChange(index, 'TargetDate', date)}
                        renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
                        disabled={isEditMode && !isLoading}
                      />
                      <FormControl fullWidth margin="normal">
                        <InputLabel>CAPA Status</InputLabel>
                        <Select
                          value={plan.status}
                          onChange={(e) => handleActionPlanChange(index, 'status', e.target.value)}
                          disabled={isEditMode && !isLoading}
                        >
                          <MenuItem value="Open">Open</MenuItem>
                          <MenuItem value="Close">Close</MenuItem>
                        </Select>
                      </FormControl>
                      {!isEditMode && (
                        <Button
                          startIcon={<DeleteIcon />}
                          onClick={() => handleRemoveActionPlan(index)}
                          disabled={isLoading}
                        >
                          Remove
                        </Button>
                      )}
                    </Box>
                  ))}
                  {!isEditMode && actionPlans.length < 5 && (
                    <Button
                      startIcon={<AddIcon />}
                      onClick={handleAddActionPlan}
                      disabled={isLoading}
                    >
                      Add Action Plan
                    </Button>
                  )}
                  <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
                    {isEditMode ? (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setIsEditMode(false)}
                        disabled={isLoading}
                      >
                        Edit
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmitInvestigation}
                        disabled={isLoading}
                      >
                        Submit Investigation
                      </Button>
                    )}
                    {isLoading && <CircularProgress size={24} />}
                  </Box>
                </Box>
              )}
            </Box>
          </Modal>

          {/* Close Form Modal */}
          <Modal
            open={isFormModalOpen}
            onClose={handleModalClose}
            aria-labelledby="near-miss-form-modal-title"
          >
            <Box className={classes.modalContent}>
              <IconButton
                aria-label="close"
                onClick={handleModalClose}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                }}
              >
                <CloseIcon />
              </IconButton>
              {selectedReport && (
                <Box>
                  <Typography id="near-miss-form-modal-title" variant="h6" gutterBottom>Close Near Miss Report</Typography>
                  <Typography>Case Number: {selectedReport.caseNumber}</Typography>
                  
                  {/* Display Action Plan Progress */}
                  <Typography variant="h6" gutterBottom>Action Plan Progress</Typography>
                  {selectedReport.actionPlans && selectedReport.actionPlans.map((plan, index) => (
                    <React.Fragment key={index}>
                      <Typography>
                        Action Plan {index + 1}: {plan.ApDescription}
                      </Typography>
                      <Typography>
                        Status: {plan.status}
                      </Typography>
                    </React.Fragment>
                  ))}
                  <Typography>Date of Closing*</Typography>
                  <DatePicker
                    value={date}
                    onChange={handleDateChange}
                    renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
                    disabled={true}
                  />
                  <Grid item xs={12}>
                    <input
                      accept="image/*"
                      id="contained-button-file"
                      type="file"
                      onChange={handleImageChange}
                      style={{ display: 'none' }}
                      disabled={!canCloseNearMissReport()}
                    />
                    <label htmlFor="contained-button-file">
                      <Button variant="contained" color="primary" component="span">
                        Upload Image for Verification
                      </Button>
                    </label>
                    {imageOfVerification && <Typography>{imageOfVerification.name}</Typography>}
                  </Grid>
                  <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleCloseNearMissReport}
                      disabled={isLoading || !canCloseNearMissReport()}
                    >
                      {isLoading ? 'Closing...' : 'Close the form'}
                    </Button>
                    {isLoading && <CircularProgress size={24} />}
                  </Box>
                  {!canCloseNearMissReport() && (
                    <Typography color="error">
                      All action plans must be closed before closing the near miss report.
                    </Typography>
                  )}
                </Box>
              )}
            </Box>
          </Modal>
        </Container>
      </Box>
    </LocalizationProvider>
  );
};

export default NearMissReportList;