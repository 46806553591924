
import React, { useEffect, useState } from 'react';
import { 
  Typography, 
  Container, 
  Card, 
  CardContent, 
  CardActions, 
  Button, 
  CircularProgress, 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  Grid,
  Chip,
  Box
} from '@mui/material';
import { firestore } from '../../../firebase/firebaseConfig';
import WorkPermitEditForm from './WorkPermitEditForm'; // We'll create this component

const WorkPermitStatus = ({ userId, company_name, status }) => {
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);

  useEffect(() => {
    const fetchRecords = async () => {
      setLoading(true);
      try {
        let query = firestore
          .collection('company_data')
          .doc(company_name)
          .collection(company_name)
          .doc('for_user')
          .collection('work_permit_v2')
          .where('userId', '==', userId);

        // Add status filter based on the props
        if (status === 'pending') {
          query = query.where('status', 'in', ['ongoing', 'hold']);
        } else if (status === 'completed') {
          query = query.where('status', 'in', ['closed', 'rejected']);
        }
        const snapshot = await query.get();
        const data = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setRecords(data);
      } catch (error) {
        console.error('Error fetching records:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchRecords();
  }, [company_name, status, userId]);

  const handleEditClick = (record) => {
    setSelectedRecord(record);
    setIsEditDialogOpen(true);
  };

  const handleCloseEditDialog = () => {
    setIsEditDialogOpen(false);
    setSelectedRecord(null);
  };

  const getStatusColor = (recordStatus) => {
    switch (recordStatus) {
      case 'ongoing': return 'primary';
      case 'hold': return 'warning';
      case 'closed': return 'success';
      case 'rejected': return 'error';
      default: return 'default';
    }
  };

  return (
    <Container>
      <Typography variant="h5" gutterBottom>
        {status === 'pending' ? 'Pending Work Permits' : 'Completed Work Permits'}
      </Typography>
      
      {loading ? (
        <Box display="flex" justifyContent="center" my={4}>
          <CircularProgress />
        </Box>
      ) : records.length > 0 ? (
        <Grid container spacing={3}>
          {records.map((record) => (
            <Grid item xs={12} md={6} key={record.id}>
              <Card variant="outlined">
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Permit #{record.caseNumber}
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography variant="body2" color="textSecondary">
                        Type: {record.type}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Chip 
                        label={record.status} 
                        color={getStatusColor(record.status)} 
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body2" color="textSecondary">
                        Created: {new Date(record.created_at.seconds * 1000).toLocaleString()}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
                <CardActions>
                  <Button 
                    size="small" 
                    color="primary"
                    onClick={() => {/* Show full details logic */}}
                  >
                    View Details
                  </Button>
                  {console.log('status',status)}
                  {status === 'pending' && (
                    <Button 
                      size="small" 
                      color="primary"
                      onClick={() => handleEditClick(record)}
                    >
                      Edit
                    </Button>
                  )}
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Typography>No records found.</Typography>
      )}

      {/* Edit Dialog */}
      <Dialog 
        open={isEditDialogOpen} 
        onClose={handleCloseEditDialog}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Edit Work Permit</DialogTitle>
        <DialogContent>
          {selectedRecord && (
            <WorkPermitEditForm 
              record={selectedRecord}
              onClose={handleCloseEditDialog}
            />
          )}
        </DialogContent>
      </Dialog>
    </Container>
  );
};

export default WorkPermitStatus;
