import React, { useState, useEffect } from 'react';
import { 
  Grid, 
  TextField, 
  Typography, 
  Button, 
  Checkbox, 
  FormControlLabel 
} from '@mui/material';
import { firestore } from '../../../firebase/firebaseConfig';

const WorkPermitEditForm = ({ record, onClose }) => {
  const [editedRecord, setEditedRecord] = useState({
    // Initial state based on the record
    generalPrecautions: record.generalPrecautions || [],
    specialPrecautions: record.specialPrecautions || [],
    necessaryPPEs: record.necessaryPPEs || {},
    electricalNOC: record.electricalNOC || { required: false },
    mechanicalNOC: record.mechanicalNOC || { required: false }
  });

  const handleSave = async () => {
    try {
      // Update the firestore document
      await firestore
        .collection('company_data')
        .doc(record.company_name)
        .collection(record.company_name)
        .doc('for_user')
        .collection('work_permit_v2')
        .doc(record.id)
        .update({
          generalPrecautions: editedRecord.generalPrecautions,
          specialPrecautions: editedRecord.specialPrecautions,
          necessaryPPEs: editedRecord.necessaryPPEs,
          electricalNOC: editedRecord.electricalNOC,
          mechanicalNOC: editedRecord.mechanicalNOC
        });

      // Close the dialog
      onClose();
    } catch (error) {
      console.error('Error updating work permit:', error);
      // Optionally show an error snackbar
    }
  };

  // Render form fields for different sections
  return (
    <Grid container spacing={3}>
      {/* General Precautions Section */}
      <Grid item xs={12}>
        <Typography variant="h6">General Precautions</Typography>
        {editedRecord.generalPrecautions.map((precaution, index) => (
          <TextField
            key={index}
            fullWidth
            variant="outlined"
            margin="normal"
            label={`Precaution ${index + 1}`}
            value={precaution}
            onChange={(e) => {
              const newPrecautions = [...editedRecord.generalPrecautions];
              newPrecautions[index] = e.target.value;
              setEditedRecord(prev => ({
                ...prev,
                generalPrecautions: newPrecautions
              }));
            }}
          />
        ))}
      </Grid>

      {/* Special Precautions Section */}
      <Grid item xs={12}>
        <Typography variant="h6">Special Precautions</Typography>
        {editedRecord.specialPrecautions.map((precaution, index) => (
          <TextField
            key={index}
            fullWidth
            variant="outlined"
            margin="normal"
            label={`Special Precaution ${index + 1}`}
            value={precaution}
            onChange={(e) => {
              const newPrecautions = [...editedRecord.specialPrecautions];
              newPrecautions[index] = e.target.value;
              setEditedRecord(prev => ({
                ...prev,
                specialPrecautions: newPrecautions
              }));
            }}
          />
        ))}
      </Grid>

      {/* NOC Requirements */}
      <Grid item xs={12}>
        <Typography variant="h6">NOC Requirements</Typography>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={editedRecord.electricalNOC.required}
                  onChange={(e) => setEditedRecord(prev => ({
                    ...prev,
                    electricalNOC: { 
                      ...prev.electricalNOC, 
                      required: e.target.checked 
                    }
                  }))}
                />
              }
              label="Electrical NOC Required"
            />
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={editedRecord.mechanicalNOC.required}
                  onChange={(e) => setEditedRecord(prev => ({
                    ...prev,
                    mechanicalNOC: { 
                      ...prev.mechanicalNOC, 
                      required: e.target.checked 
                    }
                  }))}
                />
              }
              label="Mechanical NOC Required"
            />
          </Grid>
        </Grid>
      </Grid>

      {/* Action Buttons */}
      <Grid item xs={12}>
        <Button 
          variant="contained" 
          color="primary" 
          onClick={handleSave}
        >
          Save Changes
        </Button>
        <Button 
          variant="outlined" 
          color="secondary" 
          onClick={onClose}
          style={{ marginLeft: 10 }}
        >
          Cancel
        </Button>
      </Grid>
    </Grid>
  );
};

export default WorkPermitEditForm;