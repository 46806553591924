import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Box,
  TablePagination,
  Collapse,
  Typography,
} from "@mui/material";
import {
  ViewList as ViewListIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
} from "@mui/icons-material";
import { firestore } from "../../firebase/firebaseConfig";
import {
  collection,
  getDocs,
  doc,
  updateDoc,
  query,
  where,
  onSnapshot,
} from "firebase/firestore";

const DataTable = ({ company_name  }) => {

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);

  // Fetch data from Firebase
  //   const fetchData = async () => {
  //     setLoading(true);
  //     try {
  //       const snapshot = await firestore
  //         .collection('company_data')
  //         .doc(company_name)
  //         .collection(company_name)
  //         .doc('for_user')
  //         .collection('unsafe_act')
  //         .orderBy('createdAt', 'desc')
  //         .limit(rowsPerPage)
  //         .offset(page * rowsPerPage)
  //         .get();

  //       const fetchedData = snapshot.docs.map(doc => ({
  //         id: doc.id,
  //         ...doc.data()
  //       }));

  //       // Get total count for pagination
  //       const countSnapshot = await firestore
  //         .collection('company_data')
  //         .doc(company_name)
  //         .collection(company_name)
  //         .doc('for_user')
  //         .collection('unsafe_act')
  //         .get();

  //       setTotalCount(countSnapshot.size);
  //       setData(fetchedData);
  //     } catch (error) {
  //       console.error('Error fetching data:', error);
  //     }
  //     setLoading(false);
  //   };

  useEffect(() => {
    
      const getUnsafeActs = async () => {
        const unsafeActsRef = collection(
          firestore,
          "company_data",
          company_name,
          company_name,
          "for_user",
          "unsafe_act"
        );
        const snapshot = await getDocs(unsafeActsRef);
        const data = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setData(data);
      };

      getUnsafeActs();
    
  }, [ page , rowsPerPage ]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };



  const formatDate = (timestamp) => {
    if (!timestamp) return "N/A";
    const date = timestamp.toDate();
    return date.toLocaleDateString();
  };

  return (
    <Paper sx={{ width: "100%", mb: 2 }}>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell>Case Number</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Auditor Name</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Severity</TableCell>
              <TableCell>Area</TableCell>
              <TableCell>Department</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={8} align="center">
                  Loading...
                </TableCell>
              </TableRow>
            ) : (
              data.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>{row.caseNumber}</TableCell>
                  <TableCell>{formatDate(row.createdAt)}</TableCell>
                  <TableCell>{row.auditorName}</TableCell>
                  <TableCell>{row.typeOfObservation}</TableCell>
                  <TableCell>
                    <Box
                      sx={{
                        backgroundColor:
                          row.severity === "High"
                            ? "#ffebee"
                            : row.severity === "Medium"
                            ? "#fff3e0"
                            : "#e8f5e9",
                        color:
                          row.severity === "High"
                            ? "#c62828"
                            : row.severity === "Medium"
                            ? "#ef6c00"
                            : "#2e7d32",
                        padding: "4px 8px",
                        borderRadius: "4px",
                        display: "inline-block",
                      }}
                    >
                      {row.severity}
                    </Box>
                  </TableCell>
                  <TableCell>{row.areaName}</TableCell>
                  <TableCell>{row.departmentName}</TableCell>
                  <TableCell>
                    <Box
                      sx={{
                        backgroundColor: row.isPending ? "#fff3e0" : "#e8f5e9",
                        color: row.isPending ? "#ef6c00" : "#2e7d32",
                        padding: "4px 8px",
                        borderRadius: "4px",
                        display: "inline-block",
                      }}
                    >
                      {row.isPending ? "Pending" : "Closed"}
                    </Box>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default DataTable;
