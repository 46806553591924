import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Typography,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Collapse,
  IconButton,
  Grid,
  Container,
} from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { Chart as ChartJS, RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend } from 'chart.js';
import { Radar } from 'react-chartjs-2';
import jsPDF from 'jspdf';

ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend);

const db = firebase.firestore();

const formatTimestamp = (timestamp) => {
  if (timestamp && typeof timestamp.toDate === 'function') {
    return timestamp.toDate().toLocaleString();
  }
  return 'N/A';
};

const convertTimestamps = (item) => {
  if (item === null || typeof item !== 'object') {
    return item;
  }

  if (item instanceof firebase.firestore.Timestamp) {
    return formatTimestamp(item);
  }

  if (Array.isArray(item)) {
    return item.map(convertTimestamps);
  }

  return Object.entries(item).reduce((acc, [key, value]) => {
    acc[key] = convertTimestamps(value);
    return acc;
  }, {});
};

const sectionTitles = [
  "HEALTH AND SAFETY POLICY",
  "SAFETY AND HEALTH ORGANIZATION",
  "ACCIDENT REPORTING",
  "SAFETY INSPECTION",
  "SAFETY EDUCATION AND TRAINING",
  "FIRST AID",
  "OCCUPATIONAL HEALTH CENTRE",
  "GENERAL WORKING CONDITION",
  "HAZARD IDENTIFICATION AND CONTROL",
  "TECHNICAL ASPECT",
  "WORK PERMIT SYSTEM",
  "WASTE DISPOSAL SYSTEM",
  "PERSONAL PROTECTIVE EQUIPMENT (PPE)",
  "FIRE PROTECTION",
  "EMERGENCY PREPAREDNESS",
  "PLANT LAYOUT AND AREA CLASSIFICATION",
  "STATIC ELECTRICITY",
  "PRESSURE VESSELS (FIRED AND UNFIRED)",
  "NEW EQUIPMENT REVIEW",
  "LIFTING MACHINES & TACKLE",
  "MOBILE EQUIPMENT VEHICULAR TRAFFIC AND",
  "ACCESS",
  "MATERIAL HANDLING",
  "TANK STORAGE VESSEL AREA",
  "ON-SITE GAS CYLINDERS STORAGE AREA",
  "COMMUNICATION SYSTEM ADOPTED IN PLANT",
  "TRANSPORTATION",
  "PIPELINES",
];

const RadarChartModal = ({ open, handleClose, auditData, companyName, userId, auditId }) => {
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    const fetchSectionData = async () => {
      try {
        const sectionsRef = db
          .collection("company_data")
          .doc(companyName)
          .collection(companyName)
          .doc("Audits")
          .collection(userId)
          .doc(auditId)
          .collection("sections");

        const sectionsSnapshot = await sectionsRef.get();
        
        const sectionsData = sectionsSnapshot.docs.map(doc => ({
          sectionId: doc.id,
          ...doc.data()
        }));
        //console.log("Section Data : ", sectionsData);
        const percentages = new Array(28).fill(0);
        sectionsData.forEach(section => {
          //const index = parseInt(section.sectionId.split('_')[1]) - 1;
          const index = parseInt(section.sectionId) - 1;
          console.log("index : ", index);
          // if (index >= 0 && index < 28) {
          //   percentages[index] = section.percentage || 0;
          // }
          if (index >= 0 && index < 28) {
            // if(index !== 8)
            percentages[index] = section.percentage || 0;
          }
        });
       // console.log("percantage : ",percentages);

        setChartData({
          labels: sectionTitles,
          datasets: [
            {
              label: 'Section Scores',
              data: percentages,
              backgroundColor: 'rgba(255, 99, 132, 0.2)',
              borderColor: 'rgba(255, 99, 132, 1)',
              borderWidth: 1,
            },
          ],
        });
      } catch (error) {
        console.error("Error fetching section data:", error);
      }
    };

    if (open) {
      fetchSectionData();
    }
  }, [open, companyName, userId, auditId]);

  const exportToPDF = () => {
    const canvas = document.getElementById('radar-chart');
    const imgData = canvas.toDataURL('image/png');
    const pdf = new jsPDF('landscape');
    pdf.addImage(imgData, 'PNG', 10, 10, 280, 150);
    pdf.save('radar-chart.pdf');
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="radar-chart-modal"
      aria-describedby="radar-chart-description"
    >
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '90%',
        maxHeight: '90%',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        overflow: 'auto',
      }}>
        {/* <Typography variant="h5" gutterBottom>Radar Chart - Audit Performance</Typography> */}
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
         <Typography variant="h5">Radar Chart - Audit Performance</Typography>
               <Button variant="contained" onClick={exportToPDF}>
                               Export as PDF
               </Button>
        </Box>
        {chartData && (
          <Box sx={{ height: '80vh', position: 'relative' }}>
            <Radar
              id="radar-chart"
              data={chartData}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                  r: {
                    angleLines: {
                      display: false
                    },
                    pointLabels: {
                            font: {
                               size: 8 // Reduce font size for labels
                           }
                     },
                    suggestedMin: 0,
                    suggestedMax: 100
                  }
                },

                plugins: {
                  legend: {
                    labels: {
                      font: {
                        size: 12 // Adjust legend font size if needed
                      }
                    }
                  }
                }

              }}
            />
          </Box>
        )}
        {/* <Button variant="contained" onClick={exportToPDF} sx={{ mt: 2 }}>
          Export as PDF
        </Button> */}
      </Box>
    </Modal>
  );
};

const AuditReportList = ({ companyName, userId }) => {
  const [auditReports, setAuditReports] = useState([]);
  const [selectedAudit, setSelectedAudit] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [openChartModal, setOpenChartModal] = useState(false);
  const [selectedAuditForChart, setSelectedAuditForChart] = useState(null);

  useEffect(() => {
    const fetchAuditReports = async () => {
      try {
        const auditsRef = db
          .collection("company_data")
          .doc(companyName)
          .collection(companyName)
          .doc("Audits")
          .collection(userId);

        const auditsSnapshot = await auditsRef.orderBy("createdAt", "desc").get();
        const audits = auditsSnapshot.docs.map((doc) => convertTimestamps({
          id: doc.id,
          ...doc.data(),
        }));
        setAuditReports(audits);
      } catch (error) {
        console.error("Error fetching audit reports:", error);
      }
    };

    fetchAuditReports();
  }, [companyName, userId]);

  const handleOpenModal = async (auditId) => {
    try {
      const auditRef = db
        .collection("company_data")
        .doc(companyName)
        .collection(companyName)
        .doc("Audits")
        .collection(userId)
        .doc(auditId);

      const auditDoc = await auditRef.get();
      const auditData = convertTimestamps(auditDoc.data());

      const sectionsRef = auditRef.collection("sections");
      const sectionsSnapshot = await sectionsRef.get();
      const sectionsData = sectionsSnapshot.docs.map((doc) => convertTimestamps({
        id: doc.id,
        ...doc.data(),
      }));

      const questionsPromises = sectionsData.map(async (section) => {
        const questionsRef = sectionsRef.doc(section.id).collection("questions");
        const questionsSnapshot = await questionsRef.get();
        return questionsSnapshot.docs.map((doc) => convertTimestamps({
          id: doc.id,
          ...doc.data(),
        }));
      });

      const questionsData = await Promise.all(questionsPromises);

      setSelectedAudit({
        ...auditData,
        sections: sectionsData.map((section, index) => ({
          ...section,
          questions: questionsData[index],
        })),
      });
      setOpenModal(true);
    } catch (error) {
      console.error("Error fetching audit details:", error);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedAudit(null);
  };

  const handleOpenChartModal = (audit) => {
    setSelectedAuditForChart(audit);
    setOpenChartModal(true);
  };

  const handleCloseChartModal = () => {
    setOpenChartModal(false);
    setSelectedAuditForChart(null);
  };

  const ExpandableRow = ({ question }) => {
    const [open, setOpen] = useState(false);

    return (
      <>
        <TableRow>
          <TableCell>
            <IconButton size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          </TableCell>
          <TableCell>{question.questionId}</TableCell>
          <TableCell>{question.questionText}</TableCell>
          <TableCell>{question.answer}</TableCell>
          <TableCell>{question.score}</TableCell>
          <TableCell>
            {question.imageURL && (
              <img 
                src={question.imageURL} 
                alt="Observation" 
                style={{ width: 100, height: 100, objectFit: 'cover', cursor: 'pointer' }} 
                onClick={() => window.open(question.imageURL, '_blank')}
              />
            )}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Typography variant="h6" gutterBottom component="div">
                  Action Plan
                </Typography>
                <Typography variant="body2">
                  Action plan details will be implemented here in the future.
                </Typography>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  };

  return (
    <Container maxWidth="lg" sx={{mt:12}}>
      <Grid container spacing={3}>
        {auditReports.map((report) => (
          <Grid item xs={12} sm={6} md={4} key={report.id}>
            <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
              <CardContent sx={{ flexGrow: 1 }}>
                <Typography variant="h6">Audit #{report.audit_id}</Typography>
                <Typography variant="body2">Date: {report.date}</Typography>
                <Typography variant="body2">Scope: {report.scope}</Typography>
              </CardContent>
              <Box sx={{ p: 2 }}>
                <Button variant="contained" onClick={() => handleOpenModal(report.id)} sx={{ mr: 1 }}>
                  View Details
                </Button>
                <Button variant="contained" onClick={() => handleOpenChartModal(report)}>
                  View Chart
                </Button>
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="audit-detail-modal"
        aria-describedby="audit-detail-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '90%',
          maxHeight: '90%',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          overflow: 'auto',
        }}>
          {selectedAudit && (
            <>
              <Typography variant="h5" gutterBottom>
                Audit Report Details
              </Typography>
              <Typography variant="body1"><strong>Auditor Name:</strong> {selectedAudit.name}</Typography>
              <Typography variant="body1"><strong>Audit Date:</strong> {selectedAudit.date}</Typography>

              {selectedAudit.sections.map((section) => (
                <Card key={section.id} sx={{ mt: 3 }}>
                  <CardContent>
                    <Typography variant="h6">{section.sectionTitle}</Typography>
                    <TableContainer component={Paper}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell />
                            <TableCell>SN</TableCell>
                            <TableCell>Question</TableCell>
                            <TableCell>Observation</TableCell>
                            <TableCell>Score</TableCell>
                            <TableCell>Image</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {section.questions.map((question) => (
                            <ExpandableRow key={question.id} question={question} />
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </CardContent>
                </Card>
              ))}
            </>
          )}
        </Box>
      </Modal>

      <RadarChartModal
        open={openChartModal}
        handleClose={handleCloseChartModal}
        auditData={selectedAuditForChart}
        companyName={companyName}
        userId={userId}
        auditId={selectedAuditForChart?.id}
      />
    </Container>
  );
};

export default AuditReportList;