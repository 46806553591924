import React, { useState } from 'react';
import { Menu, MenuItem, IconButton, Typography, Button } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';

const Filter = ({ onFilterChange }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (severity) => {
    setAnchorEl(null);
      onFilterChange(severity); // Pass the selected filter to parent
  };

  return (
    <div>
      <Button
        variant="outlined"
        startIcon={<FilterListIcon />}
        onClick={handleMenuOpen}
      >
        Filter
      </Button>
      <Menu
        id="filter-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => handleMenuClose(null)}
      >
        <MenuItem onClick={() => handleMenuClose('Low')}>
          <Typography color="green">Low</Typography>
        </MenuItem>
        <MenuItem onClick={() => handleMenuClose('Medium')}>
          <Typography color="orange">Medium</Typography>
        </MenuItem>
        <MenuItem onClick={() => handleMenuClose('High')}>
          <Typography color="red">High</Typography>
        </MenuItem>
        <MenuItem onClick={() => handleMenuClose(null)}>
          <Typography color="textSecondary">Clear Filter</Typography>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default Filter;
