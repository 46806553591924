import React, { useState, useEffect } from 'react';
import { Typography, TextField, Button, FormControl, InputLabel, Select, MenuItem, Grid, Paper, CircularProgress, FormControlLabel, Checkbox, Tabs, Tab, Box, Container, Snackbar
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { firestore } from '../../firebase/firebaseConfig';
import firebase from 'firebase/compat/app';
import moment from 'moment';
import StatusCard from '../../componants/StatusCard';


const StyledFormControl = styled(FormControl)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginRight: theme.spacing(2),
}));


const UnsafeActReport = ({ userId, email, company_name }) => {
  const [formData, setFormData] = useState({
    date: new Date(),
    auditorName: '',
    employeeId: '',
    reportingPersonDesignation: '',
    typeOfObservation: '',
    description: '',
    image: null,
    severity: '',
    immediateActionRequired: false,
    departmentName: '',
    areaName: '',
    responsiblePerson: '',
    responsibleDepartment: '',
    plannedDateOfClosing: new Date(),
  });

  const [departments, setDepartments] = useState([]);
  const [areas, setAreas] = useState([]);
  const [rpDesignation, setrpDesignation] = useState([]);
  const [rDept, setRDept] = useState([]);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [caseCount, setCaseCount] = useState(0);
  const [tabValue, setTabValue] = useState(0);
  const [reports, setReports] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      const companyRef = firestore.collection('company_data').doc(company_name);
      
      // Fetch departments
      const departmentsSnapshot = await companyRef
        .collection(company_name)
        .doc('for_admin')
        .collection('department_name')
        .get();
      setDepartments(departmentsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));

      // Fetch areas
      const areasSnapshot = await companyRef
        .collection(company_name)
        .doc('for_admin')
        .collection('company_zones')
        .get();
      setAreas(areasSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));

      // Fetch reporting person designations
      const designationSnapshot = await companyRef
        .collection(company_name)
        .doc('for_admin')
        .collection('reporting_person_designation')
        .get();
      setrpDesignation(designationSnapshot.docs.map(doc => ({ id: doc.id, name: doc.data().name })));

      // Fetch responsible departments
      const deptSnapshot = await companyRef
        .collection(company_name)
        .doc('for_admin')
        .collection('responsible_department')
        .get();
      setRDept(deptSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));

      // Fetch case count
      const companyDoc = await companyRef.get();
      if (companyDoc.exists) {
        setCaseCount(companyDoc.data().case_count || 0);
      }

      // Fetch user reports
      fetchReports();
    };

    fetchData();
  }, [ company_name ]);

  const fetchReports = async () => {
    const reportsSnapshot = await firestore
      .collection('company_data')
      .doc(company_name)
      .collection(company_name)
      .doc('for_user')
      .collection('unsafe_act')
      .where('userId', '==', userId)
      .get();

    setReports(reportsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevData => ({ ...prevData, [name]: value }));
  };

  const handleDateChange = (name, date) => {
    setFormData(prevData => ({ ...prevData, [name]: date }));
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setFormData(prevData => ({ ...prevData, [name]: checked }));
  };

  const handleImageChange = (event) => {
    if (event.target.files.length > 0) {
      setFormData(prevData => ({ ...prevData, image: event.target.files[0] }));
    }
  };

  const validate = () => {
    const newErrors = {};
    Object.keys(formData).forEach(key => {
      if (!formData[key] && key !== 'image' && key !== 'immediateActionRequired') {
        newErrors[key] = 'This field is required';
      }
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const uploadImage = async (file) => {
    if (!file) return null;
    const storageRef = firebase.storage().ref();
    const fileRef = storageRef.child(`images/${company_name}/${new Date().toISOString()}`);
    try {
      await fileRef.put(file);
      return await fileRef.getDownloadURL();
    } catch (error) {
      console.error('Error uploading image: ', error);
      throw error;
    }
  };
  
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (validate()) {
      setIsLoading(true);
      try {
        const caseNumber = caseCount + 1;
        let imageUrl = null;
        if (formData.image) {
          imageUrl = await uploadImage(formData.image);
        }

   
        const docRef = await firestore
          .collection('company_data')
          .doc(company_name)
          .collection(company_name)
          .doc('for_user')
          .collection('unsafe_act')
          .add({
            ...formData,
            image: imageUrl,
            caseNumber,
            userId,
            isPending: true,
            createdAt: firebase.firestore.FieldValue.serverTimestamp(), // Added for sorting and notification
          });
  
          // Update case count
        await firestore.collection('company_data').doc(company_name)
          .update({
            case_count: caseNumber,
          });

                // Add notification
      await firestore
      .collection('company_data')
      .doc(company_name)
      .collection('notifications')
      .add({
        title:`New ${formData.typeOfObservation} reported by ${formData.auditorName}.`,
        message: `${formData.description}.`,
        caseNumber,
        severity:`${formData.severity}`,
        company: company_name,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        isRead: false,
      });
     
  
        setFormData({
          date: new Date(),
          auditorName: '',
          employeeId: '',
          reportingPersonDesignation: '',
          typeOfObservation: '',
          description: '',
          image: null,
          severity: '',
          immediateActionRequired: false,
          departmentName: '',
          areaName: '',
          responsiblePerson: '',
          responsibleDepartment: '',
          plannedDateOfClosing: new Date(),
        });
        setErrors({});
        setIsLoading(false);
        setSnackbarMessage('Form submitted successfully');
        setSnackbarOpen(true);
        fetchReports();
      } catch (error) {
        console.error('Error submitting form:', error);
        console.error('Error creating notifications:', error);
        setIsLoading(false);
        setSnackbarMessage('Failed to submit form');
        setSnackbarOpen(true);
      }
    }
  };


  
  

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const formatDate = (timestamp) => {
    if (typeof timestamp === 'string' && timestamp.includes('-')) {
      return timestamp;
    }
    if (timestamp && timestamp.seconds) {
      return moment(timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000).format('MMMM D, YYYY');
    }
    return 'Invalid Date';
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Container maxWidth="lg" sx={{mt:12}}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <DatePicker
                  label="Date"
                  value={formData.date}
                  onChange={(date) => handleDateChange('date', date)}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Auditor Name"
                  name="auditorName"
                  value={formData.auditorName}
                  onChange={handleChange}
                  error={!!errors.auditorName}
                  helperText={errors.auditorName}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Employee ID"
                  name="employeeId"
                  value={formData.employeeId}
                  onChange={handleChange}
                  error={!!errors.employeeId}
                  helperText={errors.employeeId}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth error={!!errors.reportingPersonDesignation}>
                  <InputLabel id="employee-id-label">Reporting Person Designation</InputLabel>
                  <Select
                    name="reportingPersonDesignation"
                    value={formData.reportingPersonDesignation}
                    onChange={handleChange}
                  >
                    {rpDesignation.map((rpd) => (
                      <MenuItem key={rpd.id} value={rpd.name}>{rpd.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                
              </Grid>
              <Grid item xs={12} sm={6}>
                <StyledFormControl fullWidth error={!!errors.typeOfObservation}>
                  <InputLabel>Type of Observation</InputLabel>
                  <Select
                    name="typeOfObservation"
                    value={formData.typeOfObservation}
                    onChange={handleChange}
                  >
                    <MenuItem value="Unsafe Act">Unsafe Act</MenuItem>
                    <MenuItem value="Unsafe Condition">Unsafe Condition</MenuItem>
                  </Select>
                </StyledFormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  label="Description of observation"
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                  error={!!errors.description}
                  helperText={errors.description}
                />
              </Grid>
              <Grid item xs={12}>
                <input
                  accept="image/*"
                  style={{ display: 'none' }}
                  id="contained-button-file"
                  type="file"
                  onChange={handleImageChange}
                />
                <label htmlFor="contained-button-file">
                  <Button variant="contained" color="primary" component="span">
                    Upload Image of Observation
                  </Button>
                </label>
                {formData.image && <Typography variant="body2">{formData.image.name}</Typography>}
              </Grid>
              <Grid item xs={12} sm={6}>
                <StyledFormControl fullWidth error={!!errors.severity}>
                  <InputLabel>Severity</InputLabel>
                  <Select
                    name="severity"
                    value={formData.severity}
                    onChange={handleChange}
                  >
                    <MenuItem value="Low">Low</MenuItem>
                    <MenuItem value="Medium">Medium</MenuItem>
                    <MenuItem value="High">High</MenuItem>
                  </Select>
                </StyledFormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData.immediateActionRequired}
                      onChange={handleCheckboxChange}
                      name="immediateActionRequired"
                    />
                  }
                  label="Immediate Action Required"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <StyledFormControl fullWidth error={!!errors.departmentName}>
                  <InputLabel>Department Name</InputLabel>
                  <Select
                    name="departmentName"
                    value={formData.departmentName}
                    onChange={handleChange}
                  >
                    {departments.map((dept) => (
                      <MenuItem key={dept.id} value={dept.name}>{dept.name}</MenuItem>
                    ))}
                  </Select>
                </StyledFormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <StyledFormControl fullWidth error={!!errors.areaName}>
                  <InputLabel>Area Name</InputLabel>
                  <Select
                    name="areaName"
                    value={formData.areaName}
                    onChange={handleChange}
                  >
                    {areas.map((area) => (
                      <MenuItem key={area.id} value={area.name}>{area.name}</MenuItem>
                    ))}
                  </Select>
                </StyledFormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Responsible Person"
                  name="responsiblePerson"
                  value={formData.responsiblePerson}
                  onChange={handleChange}
                  error={!!errors.responsiblePerson}
                  helperText={errors.responsiblePerson}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <StyledFormControl fullWidth error={!!errors.responsibleDepartment}>
                  <InputLabel>Responsible Department</InputLabel>
                  <Select
                    name="responsibleDepartment"
                    value={formData.responsibleDepartment}
                    onChange={handleChange}
                  >
                    {rDept.map((rpd) => (
                      <MenuItem key={rpd.id} value={rpd.name}>{rpd.name}</MenuItem>
                    ))}
                  </Select>
                </StyledFormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <DatePicker
                  label="Planned Date of Closing"
                  value={formData.plannedDateOfClosing}
                  onChange={(date) => handleDateChange('plannedDateOfClosing', date)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      error={!!errors.plannedDateOfClosing}
                      helperText={errors.plannedDateOfClosing}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Box mt={3}>
              <StyledButton
                type="submit"
                variant="contained"
                color="primary"
                disabled={isLoading}
              >
                {isLoading ? <CircularProgress size={24} /> : 'Submit'}
              </StyledButton>
              <Button
                variant="outlined"
                onClick={() => {
                  setFormData({
                    date: new Date(),
                    auditorName: '',
                    employeeId: '',
                    reportingPersonDesignation: '',
                    typeOfObservation: '',
                    description: '',
                    image: null,
                    severity: '',
                    immediateActionRequired: false,
                    departmentName: '',
                    areaName: '',
                    responsiblePerson: '',
                    responsibleDepartment: '',
                    plannedDateOfClosing: new Date(),
                  });
                  setErrors({});
                }}
              >
                Clear
              </Button>
            </Box>
          </form>

        <Container maxWidth="lg" sx={{mt:4}}>
          <Typography variant="h5" component="div" >
            Unsafe Act Reports
          </Typography>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            variant="fullWidth"
          >
            <Tab label="Pending" />
            <Tab label="Completed" />
          </Tabs>
          <Box mt={3} sx={{paddingBottom:4}} >
            <Grid container spacing={3}>
          {reports
              .filter(report => tabValue === 0 ? report.isPending : !report.isPending)
              .map(report => (
                <StatusCard 
                key={report.id}
                status={report.isPending ?'Pending' : 'Completed'}
                severity={report.severity}
                caseNumber={report.caseNumber}
                date={formatDate(report.date)}
                areaName={report.areaName}
                description={report.description}
                />
                ))}
                </Grid>
          </Box>
        </Container>
      </Container>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />
    </LocalizationProvider>
  );
};

export default UnsafeActReport;
