// import React from 'react';
// import { Link } from 'react-router-dom';
// import { Card } from 'react-bootstrap';
// import { FaChartBar } from 'react-icons/fa';
// import { Box } from '@mui/system';
// import { Button, CardContent, Grid, Typography } from '@mui/material';

// const AdminDashboard = ({ companyName }) => {

//   const reportTypes = [
//     { name: 'Near Miss Report', path: 'near-miss-report-chart', permission: 'Near Miss Report' },
//     { name: 'Unsafe Act Report', path: 'unsafe-act-report-chart', permission: 'Unsafe Act' },
//     { name: 'Accident Report', path: 'accident-report-chart', permission: 'Accident Report' },
//     { name: 'Weekly Safety Meeting', path: 'weekly-safety-meeting-chart', permission: 'Weekly Safety Meeting' },
//     { name: 'Fire Injury Report', path: 'fire-injury-report-chart', permission: 'Fire Injury' },
//     { name: 'PPE Grievances Report', path: 'ppe-grievances-report-chart', permission: 'PPE Grievances' },
//     { name: 'Kaizen Report', path: 'kaizen-report-chart', permission: 'Kaizen Reports' },
//     { name: 'Work Permit', path: 'work-permit-chart', permission: 'Work Permit' },
//   ];

//   return (
//     <Box sx={{width:"100%" }}>
//     <Box display={'flex'} justifyContent={'center'} alignItems={'center'} mb={2} textAlign={"center"}>
//         <Typography variant="h4" fontWeight="bold">
//           Welcome to {companyName} Dashboard
//         </Typography>
//       </Box>   
//     <Grid container spacing={2}>
//       {reportTypes.map((report, index) => (
//         <Grid item xs={12} sm={6} md={4} key={index}>
//           <Card sx={{backgroundColor:"#526D82"}}>
//             <CardContent>
//               <Typography variant="h6" fontWeight="bold" color={'#ffff'}>
//                 {report.name === 'Fire Injury Report' ? 'Fire Incident Report' : report.name}
//               </Typography>
//               <Button
//                 component={Link} 
//                 to={report.path} 
//                 variant="contained" 
//                 color="primary" 
//                 startIcon={<FaChartBar />}
//                 sx={{ mt: 2 }}
//               >
//                 View Chart
//               </Button>
//             </CardContent>
//           </Card>
//         </Grid>
//       ))}
//     </Grid>
//   </Box>
//   );
// };
// export default AdminDashboard;


// import React, { useState } from 'react';
// import { Box, Typography, Tabs, Tab, Grid, Card, CardContent } from '@mui/material';
// import { FaChartBar } from 'react-icons/fa';
// import NearMissChart from '../charts/NearMissChart';
// import UnsafeActChart from '../charts/UnsafeActChart';
// import AccidentReportChart from '../charts/AccidentReportChart';
// import WeeklySafetyMeetingChart from '../charts/WeeklySafetyMeetingChart';
// import FireInjuryChart from '../charts/FireInjuryChart';
// import PPEGrievancesChart from '../charts/PPEGrievancesChart';
// import KaizenReportChart from '../charts/KaizenReportChart';
// import WorkPermitChart from '../charts/WorkPermitChart';

// const AdminDashboard = ({ companyName, permissions  }) => {
//   const [activeTab, setActiveTab] = useState(0);



//   // const reportTypes = [
//   //   { name: 'Near Miss Report', path: 'near-miss-report-chart',componant:<NearMissChart/>, permission: 'Near Miss Report' },
//   //   { name: 'Unsafe Act Report', path: 'unsafe-act-report-chart', permission: 'Unsafe Act' },
//   //   { name: 'Accident Report', path: 'accident-report-chart', permission: 'Accident Report' },
//   //   { name: 'Weekly Safety Meeting', path: 'weekly-safety-meeting-chart', permission: 'Weekly Safety Meeting' },
//   //   { name: 'Fire Injury Report', path: 'fire-injury-report-chart', permission: 'Fire Injury' },
//   //   { name: 'PPE Grievances Report', path: 'ppe-grievances-report-chart', permission: 'PPE Grievances' },
//   //   { name: 'Kaizen Report', path: 'kaizen-report-chart', permission: 'Kaizen Reports' },
//   //   { name: 'Work Permit', path: 'work-permit-chart', permission: 'Work Permit' },
//   // ];

//     const reportTypes = [
//     { 
//       name: 'Near Miss Report', 
//       path: 'near-miss-chart',
//       component: <NearMissChart />,
//       permission: 'Near Miss Report' 
//     },
//     { 
//       name: 'Unsafe Act Report', 
//       path: 'unsafe-act-report-chart',
//       component: <UnsafeActChart />,
//       permission: 'Unsafe Act' 
//     },
//     { 
//       name: 'Accident Report', 
//       path: 'accident-report-chart',
//       component: <AccidentReportChart />,
//       permission: 'Accident Report' 
//     },
//     { 
//       name: 'Weekly Safety Meeting', 
//       path: 'weekly-safety-meeting-chart',
//       component: <WeeklySafetyMeetingChart />,
//       permission: 'Weekly Safety Meeting' 
//     },
//     { 
//       name: 'Fire Injury Report', 
//       path: 'fire-injury-report-chart',
//       component: <FireInjuryChart />,
//       permission: 'Fire Injury' 
//     },
//     { 
//       name: 'PPE Grievances Report', 
//       path: 'ppe-grievances-report-chart',
//       component: <PPEGrievancesChart />,
//       permission: 'PPE Grievances' 
//     },
//     { 
//       name: 'Kaizen Report', 
//       path: 'kaizen-report-chart',
//       component: <KaizenReportChart />,
//       permission: 'Kaizen Reports' 
//     },
//     { 
//       name: 'Work Permit', 
//       path: 'work-permit-chart',
//       component: <WorkPermitChart/>,
//       permission: 'Work Permit' 
//     },
//   ];


//   const handleTabChange = (event, newValue) => {
//     setActiveTab(newValue);
//   };


//   return (
//     <Box sx={{ width: "100%" }}>
//       <Box display={'flex'} justifyContent={'center'} alignItems={'center'} mb={2} textAlign={"center"}>
//         <Typography variant="h4" fontWeight="bold">
//           Dashboard/{companyName}
//         </Typography>
//       </Box>

//       <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
//         <Tabs 
//           value={activeTab} 
//           onChange={handleTabChange} 
//           variant="scrollable"
//           scrollButtons="auto"
//         >
//           {reportTypes.map((report, index) => (
//             <Tab 
//               key={index} 
//               label={report.name === 'Fire Injury Report' ? 'Fire Incident Report' : report.name} 
//               icon={<FaChartBar />}
//               iconPosition="start"
//             />
//           ))}
//         </Tabs>
//       </Box>

//       <Grid container spacing={2}>
//   <Grid item xs={12}>
//     <Card sx={{ backgroundColor: "#526D82" }}>
//       <CardContent>
//         <Typography variant="h6" fontWeight="bold" color={'#ffff'}>
//           {reportTypes[activeTab].name === 'Fire Injury Report' 
//             ? 'Fire Incident Report' 
//             : reportTypes[activeTab].name}
//         </Typography>
//         <Box sx={{ mt: 2 }}>
//           {component ={Link}}
//         </Box>
//       </CardContent>
//     </Card>
//   </Grid>
// </Grid>

//     </Box>
//   );
// };

// export default AdminDashboard;

// import React, { useState } from 'react';
// import { Box, Typography, Tabs, Tab, Card, CardContent, Grid } from '@mui/material';
// import { FaChartBar } from 'react-icons/fa';
// import NearMissChart from '../charts/NearMissChart';
// import UnsafeActChart from '../charts/UnsafeActChart';
// import AccidentReportChart from '../charts/AccidentReportChart';
// import WeeklySafetyMeetingChart from '../charts/WeeklySafetyMeetingChart';
// import FireInjuryChart from '../charts/FireInjuryChart';
// import PPEGrievancesChart from '../charts/PPEGrievancesChart';
// import KaizenReportChart from '../charts/KaizenReportChart';
// import WorkPermitChart from '../charts/WorkPermitChart';

// const AdminDashboard = ({ companyName }) => {
//   const [activeTab, setActiveTab] = useState(0);

//   const reportTypes = [
//     { name: 'Near Miss Report', component: <NearMissChart /> },
//     { name: 'Unsafe Act Report', component: <UnsafeActChart /> },
//     { name: 'Accident Report', component: <AccidentReportChart /> },
//     { name: 'Weekly Safety Meeting', component: <WeeklySafetyMeetingChart /> },
//     { name: 'Fire Injury Report', component: <FireInjuryChart /> },
//     { name: 'PPE Grievances Report', component: <PPEGrievancesChart /> },
//     { name: 'Kaizen Report', component: <KaizenReportChart /> },
//     { name: 'Work Permit', component: <WorkPermitChart /> },
//   ];

//   const handleTabChange = (event, newValue) => {
//     setActiveTab(newValue);
//   };

//   return (
//     <Box sx={{ width: '100%' }}>
//       {/* Header */}
//       <Box display="flex" justifyContent="center" alignItems="center" mb={2} textAlign="center">
//         <Typography variant="h4" fontWeight="bold">
//           Welcome to {companyName} Dashboard
//         </Typography>
//       </Box>

//       {/* Tabs */}
//       <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
//         <Tabs
//           value={activeTab}
//           onChange={handleTabChange}
//           variant="scrollable"
//           scrollButtons="auto"
//           aria-label="report tabs"
//         >
//           {reportTypes.map((report, index) => (
//             <Tab
//               key={index}
//               label={report.name === 'Fire Injury Report' ? 'Fire Incident Report' : report.name}
//               icon={<FaChartBar />}
//               iconPosition="start"
//             />
//           ))}
//         </Tabs>
//       </Box>

//       {/* Active Report Content */}
//       <Grid container spacing={2}>
//         <Grid item xs={12}>
//           <Card sx={{ backgroundColor: '#526D82' }}>
//             <CardContent>
//               <Typography variant="h6" fontWeight="bold" color="#fff">
//                 {reportTypes[activeTab].name === 'Fire Injury Report'
//                   ? 'Fire Incident Report'
//                   : reportTypes[activeTab].name}
//               </Typography>
//               <Box sx={{ mt: 2 }}>
//                 {reportTypes[activeTab].component}
//               </Box>
//             </CardContent>
//           </Card>
//         </Grid>
//       </Grid>
//     </Box>
//   );
// };

// export default AdminDashboard;

import React, { useState } from "react";
import { Box, Tabs, Tab, Typography } from "@mui/material";

// Import your chart components here
import NearMissChart from "../charts/NearMissChart";
import UnsafeActChart from "../charts/UnsafeActChart";
import AccidentReportChart from "../charts/AccidentReportChart";
import WeeklySafetyMeetingChart from "../charts/WeeklySafetyMeetingChart";
import FireInjuryChart from "../charts/FireInjuryChart";
import PPEGrievancesChart from "../charts/PPEGrievancesChart";
import KaizenReportChart from "../charts/KaizenReportChart";
import WorkPermitChart from "../charts/WorkPermitChart";


const AdminDashboard = ({ companyName }) => {
  const [activeTab, setActiveTab] = useState(0);

  const reportTypes = [
    {
      name: "Unsafe Act Report",
      component: UnsafeActChart,
      permission: "Unsafe Act",
    },
    {
      name: "Near Miss Report",
      component: NearMissChart,
      permission: "Near Miss Report",
    },
    {
      name: "Fire Injury Report",
      component: FireInjuryChart,
      permission: "Fire Injury",
    },
    {
      name: "Accident Report",
      component: AccidentReportChart,
      permission: "Accident Report",
    },
    {
      name: "Work Permit",
      component: WorkPermitChart,
      permission: "Work Permit",
    },
    {
      name: "Kaizen Report",
      component: KaizenReportChart,
      permission: "Kaizen Reports",
    },
    {
      name: "PPE Grievances Report",
      component: PPEGrievancesChart,
      permission: "PPE Grievances",
    },
    {
      name: "Weekly Safety Meeting",
      component: WeeklySafetyMeetingChart,
      permission: "Weekly Safety Meeting",
    }
  ];

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  // const filteredReports = reportTypes.filter(report =>
  //   permissions.includes(report.permission)
  // );

  const ActiveChartComponent = reportTypes[activeTab]?.component;

  return (
    <Box sx={{ width: '100%'}}>
      <Box display={'flex'} justifyContent={'center'} alignItems={'center'} mb={2} textAlign={"center"}>
        <Typography variant="h4" fontWeight="bold">
          Dashboard
        </Typography>
      </Box>

      <Box>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="report tabs"
        >
          {reportTypes.map((report, index) => (
            <Tab
              key={index}
              label={report.name}
              sx={{
            "&.Mui-selected": {
              backgroundColor: "black",
              color: "white",
            },
            transition: 'transform 0.3s ease-in-out',
        '&:hover': {
          transform: 'scale(1.08)',
          backgroundColor: "white",
          color:'black'
        },
          }}
            />
          ))}
        </Tabs>
      </Box>

      
        {ActiveChartComponent && (
          <ActiveChartComponent company_name={companyName}/>
        )}
  
    </Box>
  );
};

export default AdminDashboard;

