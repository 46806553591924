import React, { useEffect, useState } from "react";
import { Container, Typography, Button, Grid, Modal, Box, TextField, IconButton, Paper, CircularProgress, Tabs, Tab, Divider,
} from "@mui/material";
import { styled } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import WarningIcon from "@mui/icons-material/Warning";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import moment from "moment";
import { firestore } from "../../firebase/firebaseConfig";
import { useLocation } from "react-router-dom";
import { collection, getDocs, doc, updateDoc, query, where, onSnapshot,
} from "firebase/firestore";
import firebase from "firebase/compat/app";
import "firebase/compat/storage";
import StatusCard from "../../componants/StatusCard";
import Filter from "../../componants/common/Filter";
import BottomRightAlerts from "../../componants/common/BottomRightAlert";
import DataTable from "../../componants/common/DataTable";


const ModalContent = styled(Paper)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  maxWidth: 600,
  maxHeight: "90vh",
  overflowY: "auto",
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[5],
  padding: theme.spacing(4),
  outline: "none",
}));

const UnsafeActList = ({ userId }) => {
  // ... (state and other functions remain the same)
  const [unsafeActs, setUnsafeActs] = useState([]);
  const [selectedUnsafeAct, setSelectedUnsafeAct] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [date, setDate] = useState(new Date());
  const [imageOfVerification, setImageOfverification] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const [severityFilter, setSeverityFilter] = useState(null); // For filtering by severity
  const [notifications, setNotifications] = useState([]);
  const location = useLocation();
  const { company_name } = location.state || {};

  // New utility function to calculate remaining time based on severity
  const calculateRemainingTime = (createdDate, severity) => {
    if (!createdDate) return null;

    // Ensure we're working with a Firestore timestamp
    const created = createdDate.toDate
      ? createdDate.toDate()
      : new Date(createdDate);
    const now = new Date();

    // Determine deadline based on severity
    let deadlineInDays;
    switch (severity) {
      case "High":
        deadlineInDays = 2; // 48 hours
        break;
      case "Medium":
        deadlineInDays = 7; // 5-7 days
        break;
      case "Low":
        deadlineInDays = 15; // 10-15 days
        break;
      default:
        deadlineInDays = 7; // Default to medium severity
    }
    // Calculate deadline
    const deadline = new Date(created);
    deadline.setDate(created.getDate() + deadlineInDays);

    // Calculate remaining time
    const differenceInTime = deadline.getTime() - now.getTime();
    const differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24));
    const differenceInHours = Math.floor(
      (differenceInTime % (1000 * 3600 * 24)) / (1000 * 3600)
    );

    return {
      days: differenceInDays,
      hours: differenceInHours,
      isOverdue: differenceInTime < 0,
    };
  };

  useEffect(() => {
    const getUnsafeActs = async () => {
      const unsafeActsRef = collection(
        firestore,
        "company_data",
        company_name,
        company_name,
        "for_user",
        "unsafe_act"
      );
      const snapshot = await getDocs(unsafeActsRef);
      const unsafeActsData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setUnsafeActs(unsafeActsData);
    };

    getUnsafeActs();

    if (!company_name) return;

    // Create a query for unread notifications
    const notificationsRef = collection(
      firestore,
      "company_data",
      company_name,
      "notifications"
    );

    const unreadQuery = query(notificationsRef, where("isRead", "==", false));

    // Real-time listener for notifications
    const unsubscribe = onSnapshot(unreadQuery, (snapshot) => {
      const fetchedNotifications = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setNotifications(fetchedNotifications);
    });

    // Cleanup subscription
    return () => unsubscribe();
  }, [company_name]);

  // Updated format date function with null checks
  const formatDate = (timestamp) => {
    if (!timestamp) return "No date";
    
    if (typeof timestamp === "string" && timestamp.indexOf("-") !== -1) {
      return timestamp;
    }
    
    if (timestamp.seconds) {
      return moment(
        timestamp.seconds * 1000 + (timestamp.nanoseconds || 0) / 1000000
      ).format("MMMM D, YYYY");
    }
    
    return "Invalid Date";
  };

  const handleModalOpen = (unsafeAct) => {
    setSelectedUnsafeAct(unsafeAct);
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setSelectedUnsafeAct(null);
  };

  const handleDateChange = (event) => {
    setDate(event.target.value);
  };

  const uploadImage = async (file) => {
    if (!file) return null;

    const storageRef = firebase.storage().ref();
    const fileRef = storageRef.child(
      `unsafe_act_report_images/${Date.now()}_${file.name}`
    );

    try {
      await fileRef.put(file);
      const downloadURL = await fileRef.getDownloadURL();
      return downloadURL;
    } catch (error) {
      console.error("Error uploading image: ", error);
      throw error;
    }
  };

  const handleCloseUnsafeAct = async () => {
    setIsLoading(true);
    let imageUrl = null;
    if (imageOfVerification) {
      imageUrl = await uploadImage(imageOfVerification);
    }
    try {
      const unsafeActRef = doc(
        firestore,
        "company_data",
        company_name,
        company_name,
        "for_user",
        "unsafe_act",
        selectedUnsafeAct.id
      );
      await updateDoc(unsafeActRef, {
        isPending: false,
        dateOfClosing: date,
        imageOfVerification: imageUrl,
      });
      setIsLoading(false);
      handleModalClose();
    } catch (error) {
      console.error("Error updating unsafe act:", error);
      setIsLoading(false);
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // Updated filtering logic with null checks
  const filteredUnsafeActs = unsafeActs
    .filter(act => act !== undefined && act !== null)
    .filter(act => (tabValue === 0 ? act.isPending : !act.isPending))
    .filter(act => !severityFilter || (act.severity && act.severity === severityFilter))
    .map(unsafeAct => {
      if (unsafeAct.isPending) {
        const remainingTime = calculateRemainingTime(
          unsafeAct.date,
          unsafeAct.severity
        );
        return { ...unsafeAct, remainingTime };
      }
      return unsafeAct;
    });

  const handleFilterChange = (severity) => {
    setSeverityFilter(severity); // Update severity filter
  };

  const handleImageChange = (e) => {
    if (e.target.files.length > 0) {
      setImageOfverification(e.target.files[0]);
    }
  };

  return (
    <Box sx={{ mt:10 }}>
      <Container maxWidth="lg">
          <Typography variant="h6" component="div">
            Reported Cases
          </Typography>    
       
          <Box display={'flex'} mt={2}>
          <DataTable company_name={company_name}/>
          </Box>
        <Tabs value={tabValue} onChange={handleTabChange} variant="fullWidth">
          <Tab label="Pending" icon={<WarningIcon />} iconPosition="start" />
          <Tab
            label="Completed"
            icon={<CheckCircleIcon />}
            iconPosition="start"
          />
        <Filter onFilterChange={handleFilterChange} />
        </Tabs>
        <Box mt={3} sx={{ paddingBottom: 4 }}>
          <Grid container spacing={3}>
            {filteredUnsafeActs.map((unsafeAct) => (
              <>
                <StatusCard
                  key={unsafeAct.id}
                  status={unsafeAct.isPending ? "Pending" : "Completed"}
                  severity={unsafeAct.severity}
                  caseNumber={unsafeAct.caseNumber}
                  date={formatDate(unsafeAct.date)}
                  areaName={unsafeAct.areaName}
                  description={unsafeAct.description}
                  onViewDetails={() => handleModalOpen(unsafeAct)}
                  // Optional: Add custom rendering for countdown if StatusCard supports it
                  additionalDetails={
                    unsafeAct.remainingTime && unsafeAct.isPending
                      ? `${
                          unsafeAct.remainingTime.isOverdue
                            ? "Overdue"
                            : `${unsafeAct.remainingTime.days} days ${unsafeAct.remainingTime.hours} hours remaining`
                        }`
                      : null
                  }
                />
              </>
            ))}
          </Grid>
        </Box>
      </Container>
    
      <BottomRightAlerts
        alerts={unsafeActs}
      />

      {/* Details Modal */}
      <Modal
        open={isModalOpen}
        onClose={handleModalClose}
        aria-labelledby="unsafe-act-modal-title"
      >
        <ModalContent>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={2}
          >
            <Typography id="unsafe-act-modal-title" variant="h5" component="h2">
              Unsafe Act Details
            </Typography>
            <IconButton onClick={handleModalClose} size="large">
              <CloseIcon />
            </IconButton>
          </Box>
          {selectedUnsafeAct && (
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">Case Number</Typography>
                  <Typography variant="body1" gutterBottom>
                    {selectedUnsafeAct.caseNumber}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">Date of Reporting</Typography>
                  <Typography variant="body1" gutterBottom>
                    {formatDate(selectedUnsafeAct.date)}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle1">Description</Typography>
                  <Typography variant="body1" paragraph>
                    {selectedUnsafeAct.description}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">Area Name</Typography>
                  <Typography variant="body1" gutterBottom>
                    {selectedUnsafeAct.areaName}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">Department Name</Typography>
                  <Typography variant="body1" gutterBottom>
                    {selectedUnsafeAct.departmentName}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">Auditor Name</Typography>
                  <Typography variant="body1" gutterBottom>
                    {selectedUnsafeAct.auditorName}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">Employee ID</Typography>
                  <Typography variant="body1" gutterBottom>
                    {selectedUnsafeAct.employeeId}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    Reporting Person Designation
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {selectedUnsafeAct.reportingPersonDesignation}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    Responsible Department
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {selectedUnsafeAct.responsibleDepartment}
                  </Typography>
                </Grid>
              </Grid>
              <Divider sx={{ my: 3 }} />
              {selectedUnsafeAct.image && (
                <Box mt={2} mb={3}>
                  <Typography variant="subtitle1" gutterBottom>
                    Reported Image
                  </Typography>
                  <img
                    src={selectedUnsafeAct.image}
                    alt="Unsafe Act"
                    style={{ width: "100%", borderRadius: "4px" }}
                  />
                </Box>
              )}
              {selectedUnsafeAct.isPending && (
                <Box mt={3}>
                  <Typography variant="h6" gutterBottom>
                    Close Unsafe Act
                  </Typography>
                  <TextField
                    label="Date of Closing"
                    type="date"
                    value={moment(date).format("YYYY-MM-DD")}
                    onChange={handleDateChange}
                    fullWidth
                    margin="normal"
                    disabled={true}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <Box mt={2}>
                    <input
                      accept="image/*"
                      id="contained-button-file"
                      type="file"
                      onChange={handleImageChange}
                      style={{ display: "none" }}
                    />
                    <label htmlFor="contained-button-file">
                      <Button variant="outlined" component="span" fullWidth>
                        Upload Verification Image
                      </Button>
                    </label>
                    {imageOfVerification && (
                      <Typography variant="body2" color="textSecondary" mt={1}>
                        {imageOfVerification.name}
                      </Typography>
                    )}
                  </Box>
                  <Box
                    mt={3}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleCloseUnsafeAct}
                      disabled={isLoading}
                    >
                      {isLoading ? "Closing..." : "Close Unsafe Act"}
                    </Button>
                    {isLoading && <CircularProgress size={24} />}
                  </Box>
                </Box>
              )}
            </Box>
          )}
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default UnsafeActList;
