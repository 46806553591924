import React, { useCallback, useState, useEffect } from 'react';
import { Box, Button, Typography, TextField, Radio, RadioGroup, FormControl, FormControlLabel, Select, MenuItem, InputLabel, Tooltip, Container } from '@mui/material';
import { auditSections } from "../../../utils/questions";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { getUIDLocally } from "../../../utils/userUtils";
import "firebase/compat/storage";
import ImageUploadButton from '../../../componants/Button/ImageUploader';

const db = firebase.firestore();
const storage = firebase.storage();

const SafetyAuditForm = () => {
  const [selectedSection, setSelectedSection] = useState(auditSections[0].id);
  const [formData, setFormData] = useState({});
  const [scores, setScores] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [userData, setUserData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [currentAuditId, setCurrentAuditId] = useState(null);
  const [images, setImages] = useState({});

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userId = await getUIDLocally();
        if (!userId) {
          throw new Error("User ID not found. Please log in again.");
        }
        const userDocRef = db
          .collection("peoples")
          .doc("users")
          .collection("users")
          .doc(userId);
        const userDoc = await userDocRef.get();
        if (userDoc.exists) {
          setUserData({ ...userDoc.data(), userId });
          await fetchCurrentAudit(userDoc.data().company_name, userId);
        } else {
          throw new Error(
            "User data not found. Please ensure you have the correct permissions."
          );
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchUserData();
  }, []);

  const fetchCurrentAudit = async (company_name, userId) => {
    try {
      const auditQuery = db
        .collection("company_data")
        .doc(company_name)
        .collection(company_name)
        .doc("Audits")
        .collection(userId)
        .orderBy("createdAt", "desc")
        .limit(1);

      const auditSnapshot = await auditQuery.get();

      if (auditSnapshot.empty) {
        // Create a new audit if none exists
        const newAuditRef = await db
          .collection("company_data")
          .doc(company_name)
          .collection(company_name)
          .doc("Audits")
          .collection(userId)
          .add({
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            isPending: true,
          });
        setCurrentAuditId(newAuditRef.id);
      } else {
        const auditDoc = auditSnapshot.docs[0];
        setCurrentAuditId(auditDoc.id);
      }
    } catch (error) {
      console.error("Error fetching/creating current audit:", error);
    }
  };

  // Handle score change for each question
  const handleScoreChange = useCallback((questionId, event) => {
    const score = parseInt(event.target.value, 10);
    setScores((prev) => ({ ...prev, [questionId]: score }));
  }, []);


   const calculateSectionPercentage = useCallback(
     (section) => {
       // Filter out the questions with a score of 0
       const sectionScores = section.questions
         .map((q) => scores[q.id] || 0)
         .filter((score) => score !== 0);

       // Calculate the total score
       const totalScore = sectionScores.reduce((sum, score) => sum + score, 0);

       // Calculate the max possible score excluding 0 scored questions
       const nonZeroQuestionsCount = sectionScores.length;
       const maxPossibleScore = nonZeroQuestionsCount * 5;

       // Handle the case where all scores are zero to avoid division by zero
       if (maxPossibleScore === 0) return 0;

       // Return the percentage
       return (totalScore / maxPossibleScore) * 100;
     },
     [scores]
   );

  // Handle text field and radio input change
  const handleChange = (sectionId, questionId, value) => {
    setFormData((prevState) => ({
      ...prevState,
      [sectionId]: {
        ...prevState[sectionId],
        [questionId]: value,
      },
    }));
  };

  // Handle section change in the dropdown
  const handleSectionChange = (e) => {
    setSelectedSection(parseInt(e.target.value));
  };

  const handleImageUpload = async (sectionId, questionId, file) => {
    if (!file) return;

    try {
      const storageRef = storage.ref();
      const fileRef = storageRef.child(`audits/${userData.company_name}/${currentAuditId}/${sectionId}/${questionId}/${file.name}`);
      await fileRef.put(file);
      const downloadURL = await fileRef.getDownloadURL();

      setImages((prevImages) => ({
        ...prevImages,
        [sectionId]: {
          ...(prevImages[sectionId] || {}),
          [questionId]: downloadURL,
        },
      }));
    } catch (error) {
      console.error("Error uploading image:", error);
      alert("Failed to upload image. Please try again.");
    }
  };

  // Validate if all fields are filled in
  const validateForm = () => {
    const errors = {};
    const currentSection = auditSections.find((section) => section.id === selectedSection);

    currentSection.questions.forEach((question) => {
      const answer = formData[selectedSection]?.[question.id];
      if (!answer || answer.trim() === '') {
        errors[question.id] = 'This field is required';
      }

      // Check sub-questions if main question's answer is 'Yes'
      if (question.subQuestions && formData[selectedSection]?.[question.id] === 'Yes') {
        question.subQuestions.forEach((subQuestion) => {
          const subAnswer = formData[selectedSection]?.[subQuestion.id];
          if (!subAnswer || subAnswer.trim() === '') {
            errors[subQuestion.id] = 'This field is required';
          }
        });
      }
    });

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  // Handle form submission

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   setSubmitted(true);

  //   if (validateForm()) {
  //     setIsLoading(true);
  //     try {
  //       if (!userData || !currentAuditId) {
  //         throw new Error("User data or current audit is not available. Please try again.");
  //       }

  //       const { company_name, userId } = userData;
  //       const auditDocRef = db
  //         .collection("company_data")
  //         .doc(company_name)
  //         .collection(company_name)
  //         .doc("Audits")
  //         .collection(userId)
  //         .doc(currentAuditId);

  //       const sectionRef = auditDocRef
  //         .collection("sections")
  //         .doc(`section_${selectedSection}`);
  //       const questionsRef = sectionRef.collection("questions");

  //       const batch = db.batch();

  //       const currentSection = auditSections.find((section) => section.id === selectedSection);
  //       const sectionPercentage = calculateSectionPercentage(currentSection);

  //       batch.set(sectionRef, {
  //         sectionId: selectedSection,
  //         sectionTitle: currentSection.title,
  //         submittedAt: firebase.firestore.FieldValue.serverTimestamp(),
  //         percentage: sectionPercentage,
  //       });

  //       currentSection.questions.forEach((question) => {
  //         const questionId = question.id;
  //         const answer = formData[selectedSection]?.[questionId] || "";
  //         const score = scores[questionId] || 0;

  //         const questionDoc = questionsRef.doc(String(questionId));
  //         batch.set(questionDoc, {
  //           questionId,
  //           questionText: question.question,
  //           answer,
  //           score,
  //         });
  //       });

  //       await batch.commit();

  //       console.log("Form Submitted: ", formData, "Scores: ", scores);
  //       alert("Section submitted successfully");
  //     } catch (error) {
  //       console.error("Error submitting section: ", error);
  //       alert("Failed to submit section: " + error.message);
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   } else {
  //     console.log("Form has errors.");
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitted(true);

    if (validateForm()) {
      setIsLoading(true);
      try {
        if (!userData || !currentAuditId) {
          throw new Error("User data or current audit is not available. Please try again.");
        }

        const { company_name, userId } = userData;
        const auditDocRef = db
          .collection("company_data")
          .doc(company_name)
          .collection(company_name)
          .doc("Audits")
          .collection(userId)
          .doc(currentAuditId);

        const sectionRef = auditDocRef
          .collection("sections")
          .doc(`section_${selectedSection}`);
        const questionsRef = sectionRef.collection("questions");

        const batch = db.batch();

        const currentSection = auditSections.find((section) => section.id === selectedSection);
        const sectionPercentage = calculateSectionPercentage(currentSection);

        batch.set(sectionRef, {
          sectionId: selectedSection,
          sectionTitle: currentSection.title,
          submittedAt: firebase.firestore.FieldValue.serverTimestamp(),
          percentage: sectionPercentage,
        });

        currentSection.questions.forEach((question) => {
          const questionId = question.id;
          const answer = formData[selectedSection]?.[questionId] || "";
          const score = scores[questionId] || 0;
          const imageURL = images[selectedSection]?.[questionId] || null;

          const questionDoc = questionsRef.doc(String(questionId));
          batch.set(questionDoc, {
            questionId,
            questionText: question.question,
            answer,
            score,
            imageURL,
          });
        });

        await batch.commit();

        console.log("Form Submitted: ", formData, "Scores: ", scores, "Images: ", images);
        alert("Section submitted successfully");
      } catch (error) {
        console.error("Error submitting section: ", error);
        alert("Failed to submit section: " + error.message);
      } finally {
        setIsLoading(false);
      }
    } else {
      console.log("Form has errors.");
    }
  };

  // Render each question, its score options, and validation
  const renderQuestion = (sectionId, question, score) => {
    const { id, question: questionText, type, options, subQuestions } = question;

    const scoreLabels = [
      "Exclude it",
      "Poor",
      "Fair",
      "Average",
      "Good",
      "Excellent",
    ];

    return (
      <Box key={id} sx={{ mb: 3, pb: 2, borderBottom: '1px solid #ddd' }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="body1">{questionText}</Typography>
          <FormControl component="fieldset">
            <RadioGroup
              row
              value={score !== undefined ? score.toString() : ""}
              onChange={(e) => handleScoreChange(question.id, e)}
            >
              {[0, 1, 2, 3, 4, 5].map((value) => (
                <Tooltip key={value} title={scoreLabels[value]} arrow>
                  <FormControlLabel
                    value={value.toString()}
                    control={<Radio />}
                    label={value}
                    labelPlacement="bottom"
                  />
                </Tooltip>
              ))}
            </RadioGroup>
          </FormControl>
        </Box>

        <Box mt={2}>
          {type === 'radio' && options ? (
            <FormControl>
              <RadioGroup
                row
                name={`section-${sectionId}-question-${id}`}
                value={formData[sectionId]?.[id] || ''}
                onChange={(e) => handleChange(sectionId, id, e.target.value)}
              >
                {options.map((option) => (
                  <FormControlLabel
                    key={option}
                    value={option}
                    control={<Radio />}
                    label={option}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          ) : (
            <TextField
              fullWidth
              label="Enter your answer"
              variant="outlined"
              value={formData[sectionId]?.[id] || ''}
              onChange={(e) => handleChange(sectionId, id, e.target.value)}
              sx={{ mt: 1 }}
              error={!!formErrors[id]}
              helperText={formErrors[id]}
            />
          )}
        </Box>

        {/* //Image Upload Button */}
        <Box mt={2}>
        <ImageUploadButton 
          sectionId={sectionId}
          id={id}
          handleImageUpload={handleImageUpload}
          images={images}
        />
        </Box>
        
        {/* Render sub-questions if main answer is 'Yes' */}
        {formData[sectionId]?.[id] === 'Yes' && subQuestions && (
          <Box mt={2} pl={3}>
            {subQuestions.map((subQuestion) => (
              <Box key={subQuestion.id} mb={2}>
                <Typography variant="body2" gutterBottom>
                  {subQuestion.question}
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  value={formData[sectionId]?.[subQuestion.id] || ''}
                  onChange={(e) => handleChange(sectionId, subQuestion.id, e.target.value)}
                  error={!!formErrors[subQuestion.id]}
                  helperText={formErrors[subQuestion.id]}
                />
              </Box>
            ))}
          </Box>
        )}
      </Box>
    );
  };

  const currentSection = auditSections.find((section) => section.id === selectedSection);

  return (
    <Box p={3}>
      <Container maxWidth="lg">
      <Box marginTop={2} marginBottom={4}>
          <Typography variant="h5" component="div" gutterBottom>
            Audit Questionnaire for {userData?.company_name}
          </Typography>
        </Box>
      </Container>

      <FormControl fullWidth sx={{ mb: 3 }}>
        <InputLabel id="section-select-label">Select Section</InputLabel>
        <Select
          labelId="section-select-label"
          id="section-select"
          value={selectedSection}
          label="Select Section"
          onChange={handleSectionChange}
        >
          {auditSections.map((section) => (
            <MenuItem key={section.id} value={section.id}>
              {section.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <form onSubmit={handleSubmit}>
        <Typography variant="h5" gutterBottom>
          {currentSection.title}
        </Typography>

        {currentSection.questions.map((question) =>
          renderQuestion(currentSection.id, question, scores[question.id])
        )}

        <Button variant="contained" color="primary" type="submit">
          Submit
        </Button>
      </form>
      {/* Display section percentage after submission */}
      {submitted && (
        <Box mt={3}>
          <Typography variant="h6">
            Section Score: {calculateSectionPercentage(currentSection).toFixed(2)}%
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default SafetyAuditForm;





