// import React, { useState } from 'react';
// //import {firebase} from './../../firebase/firebase';
// import { FaPlus, FaTrash } from 'react-icons/fa';
// // import firebase from 'firebase/compat/app';
// // import 'firebase/compat/auth';
// // import 'firebase/compat/firestore';

// // const firestore = firebase.firestore();
// import { makeStyles } from '@material-ui/core/styles';
// import {
//   Typography,
//   Box,
//   Grid,
//   TextField,
//   Button,
//   IconButton,
// } from '@material-ui/core';
// import { firestore } from '../../firebase/firebaseConfig';

// const useStyles = makeStyles((theme) => ({
//   container: {
//       paddingTop: theme.spacing(4),
//       paddingBottom: theme.spacing(4),
//   },
//   assignmentContainer: {
//     padding: theme.spacing(2),
//     border: `1px solid ${theme.palette.grey[300]}`,
//     borderRadius: theme.shape.borderRadius,
//     marginBottom: theme.spacing(2),
//   },
//   inputContainer: {
//     marginBottom: theme.spacing(2),
//   },
//   consultantContainer: {
//     padding: theme.spacing(2),
//     border: `1px solid ${theme.palette.grey[300]}`,
//     borderRadius: theme.shape.borderRadius,
//     marginBottom: theme.spacing(2),
//   },
//   label: {
//     fontWeight: 'bold',
//     marginBottom: theme.spacing(1),
//   },
//   actionButtons: {
//     display: 'flex',
//     justifyContent: 'flex-end',
//     marginTop: theme.spacing(1),
//   },
//   error: {
//     color: theme.palette.error.main,
//     marginTop: theme.spacing(0.5),
//   },
// }));


// const CompanyData = ({ companyName }) => {
// //   const { companyName } = route.params;
// // console.log('CompanyData');
// const classes = useStyles();
//   const [consultants, setConsultants] = useState([{ name: '' }]);
//   const [companyData, setCompanyData] = useState({
//     companyName: '',
//     plantLocation: '',
//     projectName: '',
//     projectDate: '',
//     projectEndDate: '',
//     projectSponsor: '',
//     projectLeader: '',
//     projectManager: '',
//   });
//   const [errors, setErrors] = useState({});

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     const errors1 = validateFields();
//     if (Object.keys(errors1).length > 0) {
//       setErrors(errors1);
//       alert('Please fill in all mandatory fields');
//       return;
//     }

//     try {
//       const companyDataRef = await firestore
//         .collection('company_data')
//         .doc(companyName)
//         .collection(companyName)
//         .doc('for_admin')
//         .collection('company_details')
//         .add(companyData);

//       if (companyDataRef && companyDataRef.id) {
//         for (const consultant of consultants) {
//           await firestore
//             .collection('company_data')
//             .doc(companyName)
//             .collection(companyName)
//             .doc('for_admin')
//             .collection('company_details')
//             .doc(companyDataRef.id)
//             .collection('Consultants')
//             .add(consultant);
//         }
//         alert('Form submitted successfully!');
//         clearForm();
//       } else {
//         console.error('Error saving company data: CompanyDataRef or ID is undefined');
//         alert('Form not submitted!');
//       }
//     } catch (error) {
//       console.error('Error saving company data:', error);
//       alert('Form not submitted!');
//     }
//   };

//   const validateFields = () => {
//     const errors2 = {};
//     const requiredFields = [
//       'companyName',
//       'plantLocation',
//       'projectName',
//       'projectDate',
//       'projectEndDate',
//       'projectSponsor',
//       'projectLeader',
//       'projectManager',
//     ];

//     requiredFields.forEach((field) => {
//       if (!companyData[field]) {
//         errors2[field] = 'This field is required';
//       }
//     });

//     if (consultants.length === 1 && !consultants[0].name.trim()) {
//       errors2.consultants = 'At least one consultant is required';
//     } else if (consultants.some((consultant) => !consultant.name.trim())) {
//       errors2.consultants = 'Please fill in all consultant names';
//     }

//     return errors2;
//   };

//   const clearForm = () => {
//     setCompanyData({
//       companyName: '',
//       plantLocation: '',
//       projectName: '',
//       projectDate: '',
//       projectEndDate: '',
//       projectSponsor: '',
//       projectLeader: '',
//       projectManager: '',
//     });
//     setConsultants([{ name: '' }]);
//     setErrors({});
//   };

//   const handleAddConsultant = () => {
//     setConsultants([...consultants, { name: '' }]);
//   };

//   const handleConsultantChange = (text, index) => {
//     const updatedConsultants = [...consultants];
//     updatedConsultants[index].name = text;
//     setConsultants(updatedConsultants);
//     setErrors({ ...errors, consultants: '' }); // Clear consultant error
//   };

//   const handleRemoveConsultant = (index) => {
//     const updatedConsultants = [...consultants];
//     updatedConsultants.splice(index, 1);
//     setConsultants(updatedConsultants);
//   };

//   const handleInputChange = (value, field) => {
//     setCompanyData({ ...companyData, [field]: value });
//     setErrors({ ...errors, [field]: '' }); // Clear error for this field
//   };

//   return (
//  <Box className={classes.container}>
//   <Typography variant="h5" gutterBottom>
//   Company Data
//   </Typography>
//   <Box className={classes.assignmentContainer}>
// <form onSubmit={handleSubmit}>
//   <Grid container spacing={2}>
//     <Grid item xs={12} className={classes.inputContainer}> 
//       <Typography variant="body1" className={classes.label}>
//         Company Name
//       </Typography>
//       <TextField
//         fullWidth
//         id="companyName"
//         value={companyData.companyName}
//         onChange={(e) => handleInputChange(e.target.value, 'companyName')}
//         error={!!errors.companyName}
//         helperText={errors.companyName}
//       />
//     </Grid>
//     <Grid item xs={12} className={classes.inputContainer}>
//       <Typography variant="body1" className={classes.label}>
//         Plant Location
//       </Typography>
//       <TextField
//         fullWidth
//         id="plantLocation"
//         value={companyData.plantLocation}
//         onChange={(e) => handleInputChange(e.target.value, 'plantLocation')}
//         error={!!errors.plantLocation}
//         helperText={errors.plantLocation}
//       />
//     </Grid>
//     <Grid item xs={12} className={classes.inputContainer}>
//       <Typography variant="body1" className={classes.label}>
//         Project Name
//       </Typography>
//       <TextField
//         fullWidth
//         id="projectName"
//         value={companyData.projectName}
//         onChange={(e) => handleInputChange(e.target.value, 'projectName')}
//         error={!!errors.projectName}
//         helperText={errors.projectName}
//       />
//     </Grid>
//     <Grid item xs={12} className={classes.inputContainer}>
//       <Typography variant="body1" className={classes.label}>
//         Project Date
//       </Typography>
//       <TextField
//         fullWidth
//         id="projectDate"
//         type="date"
//         value={companyData.projectDate}
//         onChange={(e) => handleInputChange(e.target.value, 'projectDate')}
//         error={!!errors.projectDate}
//         helperText={errors.projectDate}
//         InputLabelProps={{
//           shrink: true,
//         }}
//       />
//     </Grid>
//     <Grid item xs={12} className={classes.inputContainer}>
//       <Typography variant="body1" className={classes.label}>
//         Project End Date
//       </Typography>
//       <TextField
//         fullWidth
//         id="projectEndDate"
//         type="date"
//         value={companyData.projectEndDate}
//         onChange={(e) => handleInputChange(e.target.value, 'projectEndDate')}
//         error={!!errors.projectEndDate}
//         helperText={errors.projectEndDate}
//         InputLabelProps={{
//           shrink: true,
//         }}
//       />
//     </Grid>
//     <Grid item xs={12} className={classes.inputContainer}>
//       <Typography variant="body1" className={classes.label}>
//         Project Sponsor (client)
//       </Typography>
//       <TextField
//         fullWidth
//         id="projectSponsor"
//         value={companyData.projectSponsor}
//         onChange={(e) => handleInputChange(e.target.value, 'projectSponsor')}
//         error={!!errors.projectSponsor}
//         helperText={errors.projectSponsor}
//       />
//     </Grid>
//     <Grid item xs={12} className={classes.inputContainer}>
//       <Typography variant="body1" className={classes.label}>
//         Project Leader (client)
//       </Typography>
//       <TextField
//         fullWidth
//         id="projectLeader"
//         value={companyData.projectLeader}
//         onChange={(e) => handleInputChange(e.target.value, 'projectLeader')}
//         error={!!errors.projectLeader}
//         helperText={errors.projectLeader}
//       />
//     </Grid>
//     <Grid item xs={12} className={classes.inputContainer}>
//       <Typography variant="body1" className={classes.label}>
//         Project Manager (TMS)
//       </Typography>
//       <TextField
//         fullWidth
//         id="projectManager"
//         value={companyData.projectManager}
//         onChange={(e) => handleInputChange(e.target.value, 'projectManager')}
//         error={!!errors.projectManager}
//         helperText={errors.projectManager}
//       />
//     </Grid>
//   </Grid>

//   <Box className={classes.consultantContainer}>
//     <Typography variant="body1" className={classes.label}>
//       Project Consultants (TMS)
//     </Typography>
//     {consultants.map((consultant, index) => (
//       <div key={index} className={classes.consultantInput}>
//         <TextField
//           fullWidth
//           value={consultant.name}
//           onChange={(e) => handleConsultantChange(e.target.value, index)}
//           placeholder={`Project Consultant ${index + 1}`}
//         />
//         {index > 0 && (
//           <IconButton
//             className={classes.iconButton}
//             onClick={() => handleRemoveConsultant(index)}
//           >
//             <FaTrash />
//           </IconButton>
//         )}
//       </div>
//     ))}
//     {errors.consultants && <span className={classes.error}>{errors.consultants}</span>}
//     <Box className={classes.actionButtons}>
//       <IconButton className={classes.iconButton} onClick={handleAddConsultant}>
//         <FaPlus />
//       </IconButton>
//     </Box>
//   </Box>

//   <Button variant="contained" color="primary" type="submit">
//     Submit
//   </Button>
// </form>
// </Box>
//  </Box>

// );
// };

// export default CompanyData;

import React, { useState } from 'react';
import { 
  Typography, 
  Box, 
  Grid, 
  TextField, 
  Button, 
  IconButton, 
  Card, 
  CardContent,
  CardActions 
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { firestore } from '../../firebase/firebaseConfig';

const CompanyData = ({ companyName }) => {
  const [consultants, setConsultants] = useState([{ name: '' }]);
  const [companyData, setCompanyData] = useState({
    companyName: '',
    plantLocation: '',
    projectName: '',
    projectDate: '',
    projectEndDate: '',
    projectSponsor: '',
    projectLeader: '',
    projectManager: '',
  });
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const validateFields = () => {
    const errors = {};
    const requiredFields = [
      'companyName',
      'plantLocation',
      'projectName',
      'projectDate',
      'projectEndDate',
      'projectSponsor',
      'projectLeader',
      'projectManager',
    ];

    requiredFields.forEach((field) => {
      if (!companyData[field]?.trim()) {
        errors[field] = 'This field is required';
      }
    });

    if (consultants.length === 1 && !consultants[0].name.trim()) {
      errors.consultants = 'At least one consultant is required';
    } else if (consultants.some((consultant) => !consultant.name.trim())) {
      errors.consultants = 'Please fill in all consultant names';
    }

    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateFields();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    if (isSubmitting) return;
    setIsSubmitting(true);

    try {
      const companyDataRef = await firestore
        .collection('company_data')
        .doc(companyName)
        .collection(companyName)
        .doc('for_admin')
        .collection('company_details')
        .add({
          ...companyData,
          createdAt: new Date()
        });

      if (companyDataRef && companyDataRef.id) {
        const consultantPromises = consultants
          .filter(consultant => consultant.name.trim())
          .map(consultant => 
            firestore
              .collection('company_data')
              .doc(companyName)
              .collection(companyName)
              .doc('for_admin')
              .collection('company_details')
              .doc(companyDataRef.id)
              .collection('Consultants')
              .add(consultant)
          );

        await Promise.all(consultantPromises);

        alert('Form submitted successfully!');
        clearForm();
      } else {
        throw new Error('Failed to save company data');
      }
    } catch (error) {
      console.error('Error saving company data:', error);
      alert('Failed to submit form. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const clearForm = () => {
    setCompanyData({
      companyName: '',
      plantLocation: '',
      projectName: '',
      projectDate: '',
      projectEndDate: '',
      projectSponsor: '',
      projectLeader: '',
      projectManager: '',
    });
    setConsultants([{ name: '' }]);
    setErrors({});
  };

  const handleAddConsultant = () => {
    setConsultants(prev => [...prev, { name: '' }]);
  };

  const handleConsultantChange = (text, index) => {
    setConsultants(prev => {
      const updated = [...prev];
      updated[index].name = text;
      return updated;
    });
    setErrors(prev => ({ ...prev, consultants: '' }));
  };

  const handleRemoveConsultant = (index) => {
    setConsultants(prev => {
      const updated = [...prev];
      updated.splice(index, 1);
      return updated;
    });
  };

  const handleInputChange = (value, field) => {
    setCompanyData(prev => ({ 
      ...prev, 
      [field]: value 
    }));
    setErrors(prev => ({ ...prev, [field]: '' }));
  };

  const renderTextField = (field, label, type = 'text') => (
    <Grid item xs={12} sm={6}>
      <TextField
        fullWidth
        label={label}
        type={type}
        value={companyData[field]}
        onChange={(e) => handleInputChange(e.target.value, field)}
        error={!!errors[field]}
        helperText={errors[field]}
        InputLabelProps={type === 'date' ? { shrink: true } : {}}
      />
    </Grid>
  );

  return (
    <Card sx={{ width: '100%', mt: 2 }}>
      <form onSubmit={handleSubmit}>
        <CardContent>
          <Typography variant="h5" gutterBottom>
            Company Data
          </Typography>

          <Grid container spacing={2}>
            {renderTextField('companyName', 'Company Name')}
            {renderTextField('plantLocation', 'Plant Location')}
            {renderTextField('projectName', 'Project Name')}
            {renderTextField('projectDate', 'Project Date', 'date')}
            {renderTextField('projectEndDate', 'Project End Date', 'date')}
            {renderTextField('projectSponsor', 'Project Sponsor (Client)')}
            {renderTextField('projectLeader', 'Project Leader (Client)')}
            {renderTextField('projectManager', 'Project Manager (TMS)')}
          </Grid>

          <Box sx={{ 
            border: 1, 
            borderColor: 'grey.300', 
            borderRadius: 2, 
            p: 2, 
            mt: 2 
          }}>
            <Typography variant="subtitle1" sx={{ mb: 2 }}>
              Project Consultants (TMS)
            </Typography>

            {consultants.map((consultant, index) => (
              <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <TextField
                  fullWidth
                  variant="outlined"
                  value={consultant.name}
                  onChange={(e) => handleConsultantChange(e.target.value, index)}
                  placeholder={`Project Consultant ${index + 1}`}
                  sx={{ mr: 1 }}
                />
                {index > 0 && (
                  <IconButton 
                    onClick={() => handleRemoveConsultant(index)} 
                    color="error"
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
              </Box>
            ))}

            {errors.consultants && (
              <Typography color="error" variant="body2">
                {errors.consultants}
              </Typography>
            )}

            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1 }}>
              <IconButton onClick={handleAddConsultant} color="primary">
                <AddIcon />
              </IconButton>
            </Box>
          </Box>
        </CardContent>

        <CardActions sx={{ justifyContent: 'flex-end', p: 2 }}>
          <Button 
            variant="contained" 
            color="primary" 
            type="submit"
            disabled={isSubmitting}
          >
            {isSubmitting ? 'Submitting...' : 'Submit'}
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

export default CompanyData;