// import React, { useState, useEffect } from 'react';
// import { 
//   Card, 
//   CardContent, 
//   Typography, 
//   IconButton, 
//   Box 
// } from '@mui/material';
// import CloseIcon from '@mui/icons-material/Close';
// import WarningIcon from '@mui/icons-material/Warning';


// const SEVERITY_CONFIGS = {
//   High: { 
//     deadline: 48 * 60 * 60 * 1000, // 48 hours in milliseconds
//     alertInterval: 3 * 60 * 60 * 1000, // 3 hours in milliseconds
//   },
//   Medium: { 
//     deadline: 7 * 24 * 60 * 60 * 1000, // 7 days in milliseconds
//     alertInterval: 2 * 24 * 60 * 60 * 1000, // 2 days in milliseconds
//   },
//   Low: { 
//     deadline: 15 * 24 * 60 * 60 * 1000, // 15 days in milliseconds
//     alertInterval: 4 * 24 * 60 * 60 * 1000, // 4 days in milliseconds
//   }
// };

// const SEVERITY_COLORS = {
//   High: { main: '#ff3333', light: '#ffebee' },
//   Medium: { main: '#ff9800', light: '#fff3e0' },
//   Low: { main: '#4caf50', light: '#e8f5e9' }
// };

// const BottomRightAlerts = ({ alerts, additionalDetails }) => {
//   const [visibleAlerts, setVisibleAlerts] = useState([]);

//   useEffect(() => {
//     const now = new Date();
//     const processedAlerts = alerts
//       .filter(act => act.isPending)
//       .map(act => {
//         // Calculate remaining time
//         const createdDate = act.date instanceof Date ? act.date : new Date(act.date);
        
//         // Get severity-specific configuration
//         const config = SEVERITY_CONFIGS[act.severity];
        
//         // Calculate deadline
//         const deadline = new Date(createdDate.getTime() + config.deadline);
        
//         // Calculate remaining time
//         const differenceInTime = deadline.getTime() - now.getTime();
//         const differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24));
//         const differenceInHours = Math.floor((differenceInTime % (1000 * 3600 * 24)) / (1000 * 3600));
//         const differenceInMinutes = Math.floor((differenceInTime % (1000 * 3600)) / (1000 * 60));
        
//         // Determine if the alert should be shown based on interval
//         const shouldShowAlert = 
//           differenceInTime < 0 || // Overdue
//           differenceInDays <= 3 || // Within 3 days of deadline
//           (act.lastAlertTime 
//             ? now.getTime() - act.lastAlertTime.getTime() >= config.alertInterval 
//             : true); // Show if no previous alert time

//         return shouldShowAlert ? {
//           ...act,
//           remainingTime: {
//             days: differenceInDays,
//             hours: differenceInHours,
//             minutes: differenceInMinutes,
//             isOverdue: differenceInTime < 0
//           }
//         } : null;
//       })
//       .filter(alert => alert !== null);

//     setVisibleAlerts(processedAlerts);
//   }, [alerts]);

// const removeAlert = (id) => {
//   // Temporarily remove the alert
//   setVisibleAlerts((prev) => prev.filter((alert) => alert.id !== id));

//   // Find the dismissed alert
//   const dismissedAlert = visibleAlerts.find((alert) => alert.id === id);

//   // Re-add the alert after 60 seconds
//   if (dismissedAlert) {
//     setTimeout(() => {
//       setVisibleAlerts((prev) => [...prev, dismissedAlert]);
//     }, 60000); // 60 seconds delay
//   }
// };


//   return (
//     <Box 
//       sx={{
//         position: 'fixed', 
//         bottom: 16, 
//         right: 16, 
//         display: 'flex', 
//         flexDirection: 'column', 
//         alignItems: 'flex-end',
//         zIndex: 1000,
//         maxWidth: 350,
//       }}
//     >
//       {visibleAlerts.map((alert) => (
//         <Card
//           key={alert.id}
//           sx={{
//             marginBottom: 2,
//             border: `2px solid ${SEVERITY_COLORS[alert.severity].main}`,
//             backgroundColor: SEVERITY_COLORS[alert.severity].light,
//             width: '100%',
//             maxWidth: 300,
//             animation: 'slideIn 0.5s ease-out',
//             '@keyframes slideIn': {
//               from: { transform: 'translateX(100%)', opacity: 0 },
//               to: { transform: 'translateX(0)', opacity: 1 }
//             }
//           }}
//         >
//           <CardContent sx={{ 
//             display: 'flex', 
//             alignItems: 'center', 
//             justifyContent: 'space-between',
//             paddingBottom: '16px !important'
//           }}>
//             <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
//               <WarningIcon 
//                 sx={{ 
//                   color: SEVERITY_COLORS[alert.severity].main, 
//                   marginRight: 2 
//                 }} 
//               />
//               <Box sx={{ flexGrow: 1 }}>
//                 <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
//                   {alert.caseNumber} - {alert.areaName}
//                 </Typography>
//                 <Typography variant="body2" color="text.secondary" gutterBottom>
//                   Reported By: {alert.reportingPersonDesignation || 'Unknown'}
//                 </Typography>
//                 <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
//                   <Typography 
//                     variant="caption" 
//                     sx={{ 
//                       color: SEVERITY_COLORS[alert.severity].main,
//                       fontWeight: 'bold'
//                     }}
//                   >
//                     Severity: {alert.severity}
//                   </Typography>
//                 </Box>
//               </Box>
//               <IconButton 
//                 size="small" 
//                 onClick={() => removeAlert(alert.id)}
//                 sx={{ 
//                   color: SEVERITY_COLORS[alert.severity].main,
//                   marginLeft: 1
//                 }}
//               >
//                 <CloseIcon fontSize="small" />
//               </IconButton>
//             </Box>
//           </CardContent>
//         </Card>
//       ))}
//     </Box>
//   );
// };

// export default BottomRightAlerts;

import React, { useState, useEffect } from 'react';
import { 
  Card, 
  CardContent, 
  Typography, 
  IconButton, 
  Box 
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import WarningIcon from '@mui/icons-material/Warning';

const SEVERITY_COLORS = {
  High: { main: '#ff3333', light: '#ffebee' },
  Medium: { main: '#ff9800', light: '#fff3e0' },
  Low: { main: '#4caf50', light: '#e8f5e9' }
};

const BottomRightAlerts = ({ alerts }) => {
  const [visibleAlerts, setVisibleAlerts] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);

  // Process and sort alerts
  useEffect(() => {
    const sortedAlerts = [...alerts]
      .filter(alert => alert.isPending)
      .sort((a, b) => {
        const severityOrder = { High: 0, Medium: 1, Low: 2 };
        return severityOrder[a.severity] - severityOrder[b.severity];
      });
    
    // Get first 3 alerts
    const initial = sortedAlerts.slice(0, 3);
    setVisibleAlerts(initial);
  }, [alerts]);

  // Handle pagination
  useEffect(() => {
    if (alerts.length <= 3) return;

    const interval = setInterval(() => {
      setPageIndex(prevIndex => {
        const nextIndex = (prevIndex + 3) % alerts.length;
        const nextAlerts = [...alerts]
          .filter(alert => alert.isPending)
          .sort((a, b) => {
            const severityOrder = { High: 0, Medium: 1, Low: 2 };
            return severityOrder[a.severity] - severityOrder[b.severity];
          })
          .slice(nextIndex, nextIndex + 3);
        
        setVisibleAlerts(nextAlerts);
        return nextIndex;
      });
    }, 5000);

    return () => clearInterval(interval);
  }, [alerts]);

  const removeAlert = (id) => {
    setVisibleAlerts(prev => prev.filter(alert => alert.id !== id));
    
    // Re-add after 60 seconds
    setTimeout(() => {
      setVisibleAlerts(prev => {
        const alertToReAdd = alerts.find(alert => alert.id === id);
        if (alertToReAdd && prev.length < 3) {
          return [...prev, alertToReAdd];
        }
        return prev;
      });
    }, 60000);
  };

  return (
    <Box 
      sx={{
        position: 'fixed', 
        bottom: 16, 
        right: 16, 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'flex-end',
        zIndex: 1000,
        maxWidth: 350,
      }}
    >
      {visibleAlerts.map((alert) => (
        <Card
          key={alert.id}
          sx={{
            marginBottom: 2,
            border: `2px solid ${SEVERITY_COLORS[alert.severity].main}`,
            backgroundColor: SEVERITY_COLORS[alert.severity].light,
            width: '100%',
            maxWidth: 300,
            animation: 'slideIn 0.5s ease-out',
            '@keyframes slideIn': {
              from: { transform: 'translateX(100%)', opacity: 0 },
              to: { transform: 'translateX(0)', opacity: 1 }
            }
          }}
        >
          <CardContent sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'space-between',
            paddingBottom: '16px !important'
          }}>
            <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
              <WarningIcon 
                sx={{ 
                  color: SEVERITY_COLORS[alert.severity].main, 
                  marginRight: 2 
                }} 
              />
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                  {alert.caseNumber} - {alert.areaName}
                </Typography>
                <Typography variant="body2" color="text.secondary" gutterBottom>
                  Reported By: {alert.reportingPersonDesignation || 'Unknown'}
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography 
                    variant="caption" 
                    sx={{ 
                      color: SEVERITY_COLORS[alert.severity].main,
                      fontWeight: 'bold'
                    }}
                  >
                    Severity: {alert.severity}
                  </Typography>
                </Box>
              </Box>
              <IconButton 
                size="small" 
                onClick={() => removeAlert(alert.id)}
                sx={{ 
                  color: SEVERITY_COLORS[alert.severity].main,
                  marginLeft: 1
                }}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </Box>
          </CardContent>
        </Card>
      ))}
    </Box>
  );
};

export default BottomRightAlerts;