import React, { useState } from 'react';
import { Container, Tabs, Tab, Typography, Box } from '@mui/material';
import WorkPermitForm from './WorkPermit/WorkPermitForm';
import WorkPermitStatus from './WorkPermit/WorkPermitStatus';

const WorkPermit = ({ userId, email, company_name }) => {
  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = (event, newIndex) => {
    setTabIndex(newIndex);
  };

  return (
    <Box mt={8}>
          <Container maxWidth="lg">
      <Tabs value={tabIndex} onChange={handleTabChange} centered>
        <Tab label="Form" />
        <Tab label="Pending" />
        <Tab label="Completed" />
      </Tabs>
      <Box sx={{ mt: 4 }}>
        {tabIndex === 0 && <WorkPermitForm userId={userId} email={email} company_name={company_name} />}
        {tabIndex === 1 && <WorkPermitStatus userId={userId} company_name={company_name} status="pending" />}
        {tabIndex === 2 && <WorkPermitStatus userId={userId} company_name={company_name} status="completed" />}
      </Box>
    </Container>
    </Box>
  );
};

export default WorkPermit;

