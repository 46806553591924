import React, { useEffect, useState, useRef } from "react";
import { Grid, Paper, Typography, Button, Box, CircularProgress, Dialog, DialogContent, DialogTitle, DialogActions, IconButton, styled, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { Bar, Line } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, PointElement, LineElement, Title, Tooltip, Legend } from "chart.js";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import DetailedDataTable from "./NearMissReport/DetailedDataTable";
import DownloadIcon from "@mui/icons-material/Download";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import AspectRatioIcon from "@mui/icons-material/AspectRatio";
import CloseIcon from "@mui/icons-material/Close";
import CustomCard from "../../componants/CustomCard";
ChartJS.register(CategoryScale, LinearScale, BarElement, PointElement, LineElement, Title, Tooltip, Legend);

const FullScreenContainer = styled(Box)(({ theme }) => ({
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: theme.palette.background.paper,
  zIndex: theme.zIndex.modal,
  padding: theme.spacing(2),
  display: "flex",
  flexDirection: "column",
}));

const CustomDatePicker = styled(DatePicker)(({ theme }) => ({
  "& .MuiInputLabel-root": {
    color: "#4caf50", // Label color
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#1976d2", // Default border color
    },
    "&:hover fieldset": {
      borderColor: "#0288d1", // Border color on hover
    },
    "&.Mui-focused fieldset": {
      borderColor: "#0288d1", // Border color when focused
    },
  },
  "& .MuiSvgIcon-root": {
    color: "#ff5722", // Icon color
  },
}));



const NearMissChart = ({ company_name }) => {

  const colorPalette = {
    reported: "rgba(255, 99, 132, 0.8)", // Red
    investigated: "rgba(255, 99, 132, 0.5)", // Light Red
    actionPlanOpen: "rgba(54, 162, 235, 0.8)", // Blue
    actionPlanClosed: "rgba(54, 162, 235, 0.5)", // Light Blue
  };

  const [startDate, setStartDate] = useState(dayjs().startOf("year"));
  const [endDate, setEndDate] = useState(dayjs());
  const [loading, setLoading] = useState(false);
  const [chartData, setChartData] = useState({
    monthlyReportedVsInvestigated: null,
    monthlyActionPlanOpenVsClosed: null,
    areaReportedVsInvestigated: null,
    areaActionPlanOpenVsClosed: null,
    designationReportedVsInvestigated: null,
    designationActionPlanOpenVsClosed: null,
    departmentReportedVsInvestigated: null,
    departmentActionPlanOpenVsClosed: null,
  });
  const [totals, setTotals] = useState({
    totalReported: 0,
    totalInvestigated: 0,
    totalActionPlanOpen: 0,
    totalActionPlanClosed: 0,
  });

  const [openDialog, setOpenDialog] = useState(false);
  const [selectedDataType, setSelectedDataType] = useState("");
  const [fullScreenChart, setFullScreenChart] = useState(null);
  const [open, setOpen] = useState(false);
  const [currentChart, setCurrentChart] = useState(null);
  const [chartSizes, setChartSizes] = useState([
    { width: "100%", height: 300 },
    { width: "100%", height: 300 },
    { width: "100%", height: 300 },
  ]);


  const chartRefs = useRef([]);

  useEffect(() => {
    fetchData();
  }, [company_name, startDate, endDate]);

  const fetchData = async () => {
    setLoading(true);
    const db = firebase.firestore();
    const nearMissReportsCollection = db
      .collection("company_data")
      .doc(company_name)
      .collection(company_name)
      .doc("for_user")
      .collection("near_miss_report");

    const querySnapshot = await nearMissReportsCollection.get();

    const monthlyData = {};
    const areaData = {};
    const designationData = {};
    const departmentData = {};
    let totalReported = 0;
    let totalInvestigated = 0;
    let totalActionPlanOpen = 0;
    let totalActionPlanClosed = 0;

    querySnapshot.forEach((doc) => {
      const data = doc.data();
      const {
        areaName,
        departmentName,
        reportingPersonDesignation,
        isPending,
        dateOfNearMiss,
      } = data;


      const dateObj = dateOfNearMiss.toDate();
      if (dateObj >= startDate.toDate() && dateObj <= endDate.toDate()) {
        const monthLabel = `${dateObj.toLocaleString("default", {
          month: "short",
        })} ${dateObj.getFullYear()}`;

        // Update monthly data
        if (!monthlyData[monthLabel]) {
          monthlyData[monthLabel] = {
            reported: 0,
            investigated: 0,
            actionPlanOpen: 0,
            actionPlanClosed: 0,
          };
        }
        monthlyData[monthLabel].reported++;
        if (!isPending) monthlyData[monthLabel].investigated++;

        // Update area data
        if (!areaData[areaName]) {
          areaData[areaName] = {
            reported: 0,
            investigated: 0,
            actionPlanOpen: 0,
            actionPlanClosed: 0,
          };
        }
        areaData[areaName].reported++;
        if (!isPending) areaData[areaName].investigated++;

        // Update designation data
        if (!designationData[reportingPersonDesignation]) {
          designationData[reportingPersonDesignation] = {
            reported: 0,
            investigated: 0,
            actionPlanOpen: 0,
            actionPlanClosed: 0,
          };
        }
        designationData[reportingPersonDesignation].reported++;
        if (!isPending)
          designationData[reportingPersonDesignation].investigated++;

        // Update department data
        if (!departmentData[departmentName]) {
          departmentData[departmentName] = {
            reported: 0,
            investigated: 0,
            actionPlanOpen: 0,
            actionPlanClosed: 0,
          };
        }
        departmentData[departmentName].reported++;
        if (!isPending) departmentData[departmentName].investigated++;

        //  console.log('action plans',data.actionPlans);

        // // Update action plan data
        if (data.actionPlans && Array.isArray(data.actionPlans)) {
          data.actionPlans.forEach((plan) => {
            if (plan.status === "Open") {
              // console.log('plans',plan);
              monthlyData[monthLabel].actionPlanOpen++;
              areaData[areaName].actionPlanOpen++;
              designationData[reportingPersonDesignation].actionPlanOpen++;
              departmentData[departmentName].actionPlanOpen++;
              totalActionPlanOpen++;
            } else if (plan.status === "Close") {
              monthlyData[monthLabel].actionPlanClosed++;
              areaData[areaName].actionPlanClosed++;
              designationData[reportingPersonDesignation].actionPlanClosed++;
              departmentData[departmentName].actionPlanClosed++;
              totalActionPlanClosed++;
            }
          });
        }
        // Update totals
        totalReported++;
        if (!isPending) totalInvestigated++;
      }
    });
    const sortedMonthLabels = Object.keys(monthlyData).sort(
      (a, b) => new Date(a) - new Date(b)
    );
    setChartData({
      monthlyReportedVsInvestigated: {
        labels: sortedMonthLabels,
        datasets: [
          {
            label: "Reported",
            data: sortedMonthLabels.map((label) => monthlyData[label].reported),
            backgroundColor: colorPalette.reported,
          },
          {
            label: "Investigated",
            data: sortedMonthLabels.map((label) => monthlyData[label].investigated),
            backgroundColor: colorPalette.investigated,
          },
        ],
      },
      monthlyActionPlanOpenVsClosed: {
        labels: sortedMonthLabels,
        datasets: [
          {
            label: "Action Plan Open",
            data: sortedMonthLabels.map((label) => monthlyData[label].actionPlanOpen),
            backgroundColor: colorPalette.actionPlanOpen,
          },
          {
            label: "Action Plan Closed",
            data: sortedMonthLabels.map((label) => monthlyData[label].actionPlanClosed),
            backgroundColor: colorPalette.actionPlanClosed,
          },
        ]
        }
    });

    setTotals({
      totalReported,
      totalInvestigated,
      totalActionPlanOpen,
      totalActionPlanClosed,
    });
    setLoading(false);
  };

  const renderChart = (index, title, chartComponent) => {
    const isFullScreen = fullScreenChart === index;
    const ChartContainer = isFullScreen ? FullScreenContainer : Paper;

    return (
      <ChartContainer elevation={2} sx={{ p: 2, position: "relative" }}>
        <Box sx={{ position: "absolute", top: 10, right: 10, zIndex: 1 }}>
          <IconButton
            onClick={() => handleClickOpen(index, title, chartComponent)}
          >
            <FullscreenIcon />
          </IconButton>
          {!isFullScreen && (
            <IconButton onClick={() => handleResize(index)}>
              <AspectRatioIcon />
            </IconButton>
          )}
        </Box>
        <Typography
          variant="h6"
          align="center"
          gutterBottom
          sx={{ fontSize: "0.895rem" }}
        >
          {title}
        </Typography>
        <Box
          sx={{
            height: isFullScreen
              ? "calc(100% - 60px)"
              : chartSizes[index].height,
            width: "100%",
          }}
        >
          {chartComponent}
        </Box>
      </ChartContainer>
    );
  };

  const handleExportPDF = () => {
    const pdf = new jsPDF("p", "mm", "a4");
    const pageHeight = pdf.internal.pageSize.height;
    const pageWidth = pdf.internal.pageSize.width;
    const margin = 10;
    let yOffset = margin;

    const addChartToPDF = (chartElement, callback) => {
      if (!chartElement) {
        console.error("Invalid chart element");
        callback();
        return;
      }

      html2canvas(chartElement, { useCORS: true }).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const imgWidth = pageWidth - 2 * margin;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;

        if (yOffset + imgHeight + margin > pageHeight) {
          pdf.addPage();
          yOffset = margin;
        }

        pdf.addImage(imgData, "PNG", margin, yOffset, imgWidth, imgHeight);
        yOffset += imgHeight + margin;

        callback();
      });
    };

    const processCharts = (index) => {
      if (index < chartRefs.current.length) {
        const chartElement = chartRefs.current[index];
        if (chartElement) {
          addChartToPDF(chartElement, () => {
            processCharts(index + 1);
          });
        } else {
          console.error(`Chart element at index ${index} is undefined`);
          processCharts(index + 1);
        }
      } else {
        pdf.save("near_miss_charts.pdf");
      }
    };

    processCharts(0);
  };
  
  const barChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: true,
        title: {
          display: true,
          text: "Categories",
          font: {
            family: "Poppins",
            size: 14,
            weight: "600",
          },
          color: "#333",
        },
        grid: {
          display: false,
        },
        ticks: {
          font: {
            family: "Poppins",
            size: 12,
          },
          color: "#555",
        },
      },
      y: {
        stacked: true,
        title: {
          display: true,
          text: "Values",
          font: {
            family: "Poppins",
            size: 14,
            weight: "600",
          },
          color: "#333",
        },
        grid: {
          color: "#e0e0e0",
        },
        ticks: {
          font: {
            family: "Poppins",
            size: 12,
          },
          color: "#555",
        },
      },
    },
    plugins: {
      legend: {
        position: "top",
        labels: {
          font: {
            family: "Poppins",
            size: 12,
            weight: "500",
          },
          color: "#333",
        },
      },
      tooltip: {
        mode: "index",
        intersect: false,
        callbacks: {
          label: (context) => `${context.dataset.label}: ${context.raw}`,
        },
        backgroundColor: "rgba(0, 0, 0, 0.8)",
        titleFont: {
          family: "Poppins",
          size: 14,
          weight: "600",
        },
        bodyFont: {
          family: "Poppins",
          size: 12,
          weight: "400",
        },
      },
    },
  };

  const chartOptions = {
    responsive: true,
    scales: {
      x: {
        stacked: true,
        grid: { display: false, offset: true },
        ticks: {
          font: {
            family: "'Roboto', sans-serif", // Font family for x-axis labels
            size: 12, // Font size for x-axis labels
          },
          color: "#666", // Color for x-axis labels
          padding: 10,
        },
        offset: true,
      },
      y: {
        stacked: true,
        grid: { display: true },
        ticks: {
          callback: function (value, index, values) {
            // Customizing the tick labels
            // Example: Change to a percentage
            return value;
          },
          padding: 10,
          font: {
            family: "'Roboto', sans-serif", // Font family for y-axis labels
            size: 12, // Font size for y-axis labels
          },
          color: "#666", // Color for y-axis labels
          stepSize: 1,
        },
        min: 0,
        max: 5,
      },
    },
    plugins: {
      legend: {
        position: "top",
        labels: {
          font: {
            family: "'Roboto', sans-serif", // Font family for legend labels
            size: 14, // Font size for legend labels
            weight: "400",
          },
          color: "#333", // Color for legend labels
        },
      },
      title: {
        display: true,
        text: "Near Miss Charts",
        font: {
          family: "'poppins', sans-serif", // Font family for title
          size: 14, // Font size for title
          weight: "400",
        },
        color: "#002244", // Color for title
      },
    },
  };

  const handleResize = (index) => {
    const newSizes = [...chartSizes];
    newSizes[index] = {
      width: newSizes[index].width === "100%" ? "80%" : "100%",
      height: newSizes[index].height === 300 ? 400 : 300,
    };
    setChartSizes(newSizes);
  };

  const handleClickOpen = (index, title, chartComponent) => {
    setCurrentChart({ index, title, component: chartComponent });
    setOpen(true);
  };

  const handleTotalClick = (dataType) => {
    setSelectedDataType(dataType);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };


  return (
      <Box p={2}>
        <Typography variant="h4" gutterBottom fontWeight={"bold"}>
          Near Miss Report
        </Typography>
        <Box mb={2}>
            <Grid container spacing={2}>
            <CustomCard
            URL={"/images/Nearmiss.png"}
            height={80}
            bannerText="Near Miss Reported"
            bannerColor="#ff5722"
            bannerTextColor="#f8f9fa"
            openTextColor="#f8f9fa"
            closedTextColor="#f8f9fa"
            onOpenClick={() => handleTotalClick("reported")}
            onCloseClick={() => handleTotalClick("investigated")}
            openTitle="Reported"
            openValue={totals.totalReported}
            closedTitle="Invetigated"
            closedValue={totals.totalInvestigated}
            subtitleColor="text.secondary"
          />
              <CustomCard
              URL={"/images/Slip.png"}
              height={80}
                bannerText="Action Plan"
                bannerColor="#ff5722"
                bannerTextColor="#f8f9fa"
                onOpenClick={() => handleTotalClick("actionPlanOpen")}
                onCloseClick={() => handleTotalClick("actionPlanClosed")}
                openTitle="Open"
                openValue={totals.totalActionPlanOpen}
                closedTitle="Closed"
                closedValue={totals.totalActionPlanClosed}
                subtitleColor="text.secondary"
              />
            </Grid>
        </Box>
        <Grid container spacing={3} mb={3} mt={2}>
          <Grid item xs={12} md={4}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <CustomDatePicker
                label="Start Date"
                value={startDate}
                onChange={(newValue) => setStartDate(newValue)}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} md={4}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <CustomDatePicker
                label="Start Date"
                value={startDate}
                onChange={(newValue) => setStartDate(newValue)}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} md={4}>
          <Button
          variant="contained"
          color="primary"
          onClick={handleExportPDF}
          startIcon={<DownloadIcon />}
          sx={{ minWidth: 150 }}
        >
          Export as PDF
        </Button>
          </Grid>
        </Grid>
        {loading ? (
          <Box display="flex" justifyContent="center" my={4}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Grid container spacing={3}>
              {Object.entries(chartData).map(([key, data], index) => (
                <Grid item xs={12} md={6} key={key}>
                  <Paper
                    elevation={12}
                    ref={(el) => (chartRefs.current[index] = el)}
                    sx={{ p: 2 }}
                  >
                    <Typography variant="h6" align="center" gutterBottom>
                      {key.split(/(?=[A-Z])/).join(" ")}
                    </Typography>
                    {data ? (
                      key.includes("monthly") ? (
                        <Line data={data} options={chartOptions} />
                      ) : (
                        <Bar data={data} options={chartOptions} />
                      )
                    ) : (
                      <Typography align="center" color="error">
                        No data available
                      </Typography>
                    )}
                  </Paper>
                </Grid>
              ))}
              
            </Grid>

            <Dialog
              open={openDialog}
              onClose={handleCloseDialog}
              maxWidth="lg"
              fullWidth
            >
              <DialogTitle>Detailed Report</DialogTitle>
              <DialogContent>
                <DetailedDataTable
                  companyName={company_name}
                  startDate={startDate.toDate()}
                  endDate={endDate.toDate()}
                  dataType={selectedDataType}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseDialog}>Close</Button>
              </DialogActions>
            </Dialog>
          </>
        )}
      </Box>
  );
};
export default NearMissChart;
