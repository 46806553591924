import React, { useState, useEffect } from "react";
import {
  Typography,
  CardContent,
  Button,
  Grid,
  Modal,
  Box,
  TextField,
  IconButton,
  Paper,
  CircularProgress,
  Tabs,
  Tab,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Alert,
  Container,
} from "@mui/material";
import { styled } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { firestore } from "../../firebase/firebaseConfig"; // Adjust the path as needed
import { useLocation } from "react-router-dom";
import firebase from "firebase/compat/app";
import "firebase/compat/storage";
import { makeStyles } from "@material-ui/core/styles";
import {
  Close,
  Expand,
  Info,
  PendingActions,
  PriorityHigh,
} from "@mui/icons-material";


import moment from "moment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { format } from "date-fns";

const useStyles = makeStyles((theme) => ({
  modalContent: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    maxWidth: 800,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    outline: "none",
    maxHeight: "80vh",
    overflowY: "auto",
  },
  formSection: {
    marginBottom: theme.spacing(3),
  },
  actionPlanForm: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const StyledCard = styled(Paper)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  "&:hover": {
    boxShadow: theme.shadows[4],
  },
}));
const StyledPaper = styled(Paper)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const FireInjuryList = ({ company_name }) => {
  const classes = useStyles();
  const [fireInjuries, setFireInjuries] = useState([]);
  const [selectedInjury, setSelectedInjury] = useState(null);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const [isInvestigationModalOpen, setIsInvestigationModalOpen] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [date, setDate] = useState(new Date());
  const [tabValue, setTabValue] = useState(0);
  const [imageOfVerification, setImageOfVerification] = useState(null);
  const location = useLocation();
  // const { company_name } = location.state || {};

  console.log("company name: fire injury list", location.state);
  // New state for investigation form
  const [investigationTeam, setInvestigationTeam] = useState("");
  const [dateOfInvestigation, setDateOfInvestigation] = useState(new Date());
  const [timeOfInvestigation, setTimeOfInvestigation] = useState(new Date());
  const [rootCause, setRootCause] = useState("");
  const [actionPlans, setActionPlans] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isButtonClicked, setIsButtonClicked] = useState(false);

  useEffect(() => {
    const getFireInjuries = async () => {
      const fireInjuriesRef = firestore
        .collection("company_data")
        .doc(company_name)
        .collection(company_name)
        .doc("for_user")
        .collection("fire_injury_reports");
      const snapshot = await fireInjuriesRef.get();
      const fireInjuriesData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      const sortedFireInjuries = fireInjuriesData.sort((a, b) => {
        const caseNumberA = parseInt(a.caseNumber, 10);
        const caseNumberB = parseInt(b.caseNumber, 10);
        return caseNumberB - caseNumberA;
      });

      setFireInjuries(sortedFireInjuries);
    };
    getFireInjuries();
  }, [company_name, selectedInjury]);

  const formatDate = (timestamp) => {
    if (typeof timestamp === "string" && timestamp.includes("-")) {
      return timestamp;
    }
    if (timestamp && timestamp.seconds) {
      return moment(
        timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000
      ).format("MMMM D, YYYY");
    }
    return "Invalid Date";
  };

  const formatTime = (timestamp) => {
    if (timestamp && timestamp.seconds) {
      return moment(
        timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000
      ).format("h:mm A");
    }
    return "Invalid Time";
  };

  const handleDetailsModalOpen = (injury) => {
    setSelectedInjury(injury);
    setIsDetailsModalOpen(true);
  };

  const handleFormModalOpen = (injury) => {
    setSelectedInjury(injury);
    setIsFormModalOpen(true);
  };

  const handleInvestigationModalOpen = (injury) => {
    setSelectedInjury(injury);
    setIsInvestigationModalOpen(true);
    if (injury.DateOfInvestigation) {
      setInvestigationTeam(injury.InvestigationTeam || "");
      setDateOfInvestigation(injury.DateOfInvestigation.toDate());
      setTimeOfInvestigation(injury.TimeOfInvestigation.toDate());
      setRootCause(injury.rootCause || "");
      setActionPlans(injury.actionPlans || []);
      setIsEditMode(true);
    } else {
      setInvestigationTeam("");
      setDateOfInvestigation(new Date());
      setTimeOfInvestigation(new Date());
      setRootCause("");
      setActionPlans([]);
      setIsEditMode(false);
    }
  };

  const handleModalClose = () => {
    setIsDetailsModalOpen(false);
    setIsFormModalOpen(false);
    setIsInvestigationModalOpen(false);
    setSelectedInjury(null);
  };

  const handleDateChange = (event) => {
    setDate(event.target.value);
  };

  const uploadImage = async (file) => {
    if (!file) return null;

    const storageRef = firebase.storage().ref();
    const fileRef = storageRef.child(
      `fire_injury_images/${Date.now()}_${file.name}`
    );

    try {
      await fileRef.put(file);
      const downloadURL = await fileRef.getDownloadURL();
      return downloadURL;
    } catch (error) {
      console.error("Error uploading image: ", error);
      throw error;
    }
  };

  const handleCloseFireInjury = async () => {
    setIsLoading(true);
    let imageUrl = null;
    if (imageOfVerification) {
      imageUrl = await uploadImage(imageOfVerification);
    }
    console.log("selected id", selectedInjury.id);
    try {
      const fireInjuryRef = firestore
        .collection("company_data")
        .doc(company_name)
        .collection(company_name)
        .doc("for_user")
        .collection("fire_injury_reports")
        .doc(selectedInjury.id);
      await fireInjuryRef.update({
        isPending: false,
        dateOfClosing: date,
        imageOfVerification: imageUrl,
      });
      setIsLoading(false);
      //alert('')
      alert("form submitted successfully.");
      handleModalClose();
      <Alert severity="success">This is a success Alert.</Alert>;
    } catch (error) {
      <Alert severity="error">Error in closing the form. </Alert>;
      console.error("Error updating fire injury:", error);
      setIsLoading(false);
    }
  };

  const handleImageChange = (e) => {
    if (e.target.files.length > 0) {
      setImageOfVerification(e.target.files[0]);
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const filteredFireInjuries = fireInjuries.filter((w) =>
    tabValue === 0 ? w.isPending : !w.isPending
  );

  const handleAddActionPlan = () => {
    if (actionPlans.length < 5) {
      setActionPlans([
        ...actionPlans,
        {
          TypeOfActionPlan: "",
          ResponsiblePerson: "",
          ApDescription: "",
          TargetDate: new Date(),
          status: "Open",
        },
      ]);
    }
  };

  const handleRemoveActionPlan = (index) => {
    const newActionPlans = actionPlans.filter((_, i) => i !== index);
    setActionPlans(newActionPlans);
  };

  const handleActionPlanChange = (index, field, value) => {
    const newActionPlans = [...actionPlans];
    newActionPlans[index][field] = value;
    setActionPlans(newActionPlans);
  };

  const handleSubmitInvestigation = async () => {
    setIsLoading(true);
    try {
      const fireInjuryRef = firestore
        .collection("company_data")
        .doc(company_name)
        .collection(company_name)
        .doc("for_user")
        .collection("fire_injury_reports")
        .doc(selectedInjury.id);
      await fireInjuryRef.update({
        InvestigationTeam: investigationTeam,
        DateOfInvestigation: dateOfInvestigation,
        TimeOfInvestigation: timeOfInvestigation,
        rootCause: rootCause,
        actionPlans: actionPlans,
      });
      setIsLoading(false);
      handleModalClose();
      <Alert severity="success">Submitted Sucessfully.</Alert>;
    } catch (error) {
      <Alert severity="error">Error submitting investigation details .</Alert>;
      console.error("Error updating investigation details:", error);
      setIsLoading(false);
    }
  };

  // useEffect(() => {
  //   const canCloseFireInjury = () => {
  //     return actionPlans.every(plan => plan.status === 'Close');
  //   };
  //   canCloseFireInjury();
  // }, [company_name]);

  const canCloseFireInjury = () => {
    console.log("actionPlans", actionPlans);
    return (
      actionPlans.length !== 0 &&
      actionPlans.every((plan) => plan.status === "Close")
    );
  };
  console.log("canCloseFireIn", canCloseFireInjury());

  // Define color mappings for each status level
  const getStatusColor = (severityLevel) => {
    switch (severityLevel) {
      case "minor":
        return "#FFD700"; // Yellow for minor
      case "major":
        return "#FFA500"; // Orange for major
      case "fatal":
        return "#FF4500"; // Red for fatal
      default:
        return "#000000"; // Default to black if the severity level is unknown
    }
  };

  const severityLevel = "minor"; // Replace with logic to determine the actual severity level

  const borderColor = getStatusColor(severityLevel);

  // Function to convert Firestore Timestamp to a JavaScript Date and then format it
  const formatTimestamp = (timestamp) => {
    if (timestamp && timestamp.seconds) {
      const date = new Date(timestamp.seconds * 1000);
      return format(date, "dd-MM-yyyy HH:mm:ss"); // Format the date as desired
    }
    return ""; // Return an empty string if the timestamp is invalid
  };

  const [expanded, setExpanded] = useState(false);

  const handleToggleExpand = () => {
    setExpanded(!expanded);
  };

  const maxLength = 10; // Maximum number of characters before truncating

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>

    <Box sx={{marginTop:12}}>
    <Container maxWidth="lg">
    <Typography variant="h5" component="div">
        Fire Injury Reports
      </Typography>
      <Tabs value={tabValue} onChange={handleTabChange} centered>
        <Tab label="Pending" />
        <Tab label="Completed" />
      </Tabs>
      <Box mt={3}>
        <Grid container spacing={2}>
          {filteredFireInjuries.map((injury) => (
            <Grid item xs={12} sm={6} md={6} key={injury.id}>
              <StyledCard elevation={1}>
                <CardContent>
                      <Typography><strong>Case Number:</strong> {injury.caseNumber}</Typography>
                      <Typography >
                    <strong>Reported on:</strong> {formatDate(injury.date)}
                  </Typography>
                  <Typography><strong>Area Name:</strong>{injury.areaName}</Typography>
                      <Typography variant="body1" paragraph><strong>Description:</strong>
                      {injury.possibleCause.length > 100
                      ? `${injury.possibleCause.substring(0, 100)}...`
                      : injury.possibleCause}
                      </Typography>
                      <Typography color={injury.isPending ? 'error' : 'green'}><strong>Status:</strong> {injury.isPending ? 'Pending' : 'Completed'}</Typography>
                      {/* <Typography>
                        Area: {injury.areaName}, {injury.departmentName},{" "} {formatTimestamp(injury.dateOfFireIncident)}
                      </Typography> */}
                      {/* <Typography>
                        By: {injury.reportingPersonName} at{" "} {formatTimestamp(injury.dateOfFireIncident)}
                      </Typography> */}
                    
                  <Box mt={2}>
                    <Button variant="contained" color="primary" 
                      onClick={() => handleDetailsModalOpen(injury)}
                    >
                       View Details
                    </Button>
                    {injury.isPending && (
                      <>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => handleInvestigationModalOpen(injury)}
                          sx={{marginLeft:2,marginRight:2}}
                        >
                          Investigate
                        </Button>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => handleFormModalOpen(injury)}
                        >
                         Close Form
                        </Button>
                      </>
                    )}
                  </Box>
                </CardContent>
              </StyledCard>
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* Details Modal */}
      <Modal
        open={isDetailsModalOpen}
        onClose={handleModalClose}
        aria-labelledby="fire-injury-details-modal-title"
        
      >
        <Box className={classes.modalContent} sx={{border:'1px solid grey', borderRadius:'10px'}}>
          <IconButton
            aria-label="close"
            onClick={handleModalClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
          {selectedInjury && (
            <Box  >
              <Typography
                id="fire-injury-details-modal-title"
                variant="h5"
                gutterBottom
                textAlign={'center'}
              >
                Fire Injury Details
              </Typography>
              {/* <Typography>Description: {selectedInjury.descriptionOfInjury}</Typography>
              <Typography>Date: {formatDate(selectedInjury.dateOfFireIncident)}</Typography>
              <Typography>Time: {formatTime(selectedInjury.time)}</Typography>
              <Typography>Case Number: {selectedInjury.caseNumber}</Typography> */}
              <Typography id="detail-modal-description">
                <strong>Area Name:</strong> {selectedInjury.areaName || "N/A"}
              </Typography>
              <Typography>
                <strong>Case Number:</strong> {selectedInjury.caseNumber || "N/A"}
              </Typography>
              <Typography>
                <strong>Date of Fire Incident:</strong>{" "}
                {formatDate(selectedInjury.dateOfFireIncident) || "N/A"}
              </Typography>
              <Typography>
                <strong>Date of Reporting:</strong>{" "}
                {formatDate(selectedInjury.dateOfReporting) || "N/A"}
              </Typography>
              <Typography>
                <strong>Department Name:</strong> {selectedInjury.departmentName || "N/A"}
              </Typography>
              <Typography>
                <strong>Employee ID:</strong> {selectedInjury.employeeId || "N/A"}
              </Typography>
              <Typography>
                <strong>Fire Origin:</strong> {selectedInjury.fireOrigin || "N/A"}
              </Typography>
              <Typography>
                <strong>Fuel Energy:</strong> {selectedInjury.fuelEnergy || "N/A"}
              </Typography>
              <Typography>
                <strong>Ignition Object:</strong> {selectedInjury.ignitionObject || "N/A"}
              </Typography>
              <Typography>
                <strong>Material First Ignited:</strong>{" "}
                {selectedInjury.materialFirstIgnited || "N/A"}
              </Typography>
              <Typography>
                <strong>Possible Cause:</strong> {selectedInjury.possibleCause || "N/A"}
              </Typography>
              <Typography>
                <strong>Reporting Person Designation:</strong>{" "}
                {selectedInjury.reportingPersonDesignation || "N/A"}
              </Typography>
              <Typography>
               <strong> Reporting Person Name:</strong>{" "}
                {selectedInjury.reportingPersonName || "N/A"}
              </Typography>
              <Typography>
                <strong>Time of Event:</strong> {formatTime(selectedInjury.timeOfEvent) || "N/A"}
              </Typography>
              <Box mt={2}>
                <Button variant="outlined" onClick={handleModalClose}>
                  Back to list
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      </Modal>

      {/* Investigation Modal */}
      <Modal
        open={isInvestigationModalOpen}
        onClose={handleModalClose}
        aria-labelledby="fire-injury-investigation-modal-title"
      >
        <Box className={classes.modalContent}>
          <IconButton
            aria-label="close"
            onClick={handleModalClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
          {selectedInjury && (
            <Box>
              <Typography
                id="fire-injury-investigation-modal-title"
                variant="h6"
                gutterBottom
              >
                Fire Injury Investigation
              </Typography>
              <TextField
                label="Investigation Team"
                value={investigationTeam}
                onChange={(e) => setInvestigationTeam(e.target.value)}
                fullWidth
                margin="normal"
                disabled={isEditMode && !isLoading}
              />
              <DatePicker
                label="Date of Investigation"
                value={dateOfInvestigation}
                onChange={setDateOfInvestigation}
                renderInput={(params) => (
                  <TextField {...params} fullWidth margin="normal" />
                )}
                disabled={isEditMode && !isLoading}
              />
              <TimePicker
                label="Time of Investigation"
                value={timeOfInvestigation}
                onChange={setTimeOfInvestigation}
                renderInput={(params) => (
                  <TextField {...params} fullWidth margin="normal" />
                )}
                disabled={isEditMode && !isLoading}
              />
              <TextField
                label="Root Cause"
                value={rootCause}
                onChange={(e) => setRootCause(e.target.value)}
                fullWidth
                multiline
                rows={4}
                margin="normal"
                disabled={isEditMode && !isLoading}
              />
              <Typography variant="h6" gutterBottom>
                Action Plans
              </Typography>
              {actionPlans.map((plan, index) => (
                <Box key={index} className={classes.actionPlanForm}>
                  <FormControl fullWidth margin="normal">
                    <InputLabel>Type of Action Plan</InputLabel>
                    <Select
                      value={plan.TypeOfActionPlan}
                      onChange={(e) =>
                        handleActionPlanChange(
                          index,
                          "TypeOfActionPlan",
                          e.target.value
                        )
                      }
                      disabled={isEditMode && !isLoading}
                    >
                      <MenuItem value="corrective">Corrective AP</MenuItem>
                      <MenuItem value="preventive">Preventive AP</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    label="Action plan description"
                    value={plan.ApDescription}
                    onChange={(e) =>
                      handleActionPlanChange(
                        index,
                        "ApDescription",
                        e.target.value
                      )
                    }
                    fullWidth
                    multiline
                    rows={2}
                    margin="normal"
                    disabled={isEditMode && !isLoading}
                  />
                  <TextField
                    label="Responsible Person"
                    value={plan.ResponsiblePerson}
                    onChange={(e) =>
                      handleActionPlanChange(
                        index,
                        "ResponsiblePerson",
                        e.target.value
                      )
                    }
                    fullWidth
                    margin="normal"
                    disabled={isEditMode && !isLoading}
                  />
                  <DatePicker
                    label="Target Date"
                    value={plan.TargetDate}
                    onChange={(date) =>
                      handleActionPlanChange(index, "TargetDate", date)
                    }
                    renderInput={(params) => (
                      <TextField {...params} fullWidth margin="normal" />
                    )}
                    disabled={isEditMode && !isLoading}
                  />
                  <FormControl fullWidth margin="normal">
                    <InputLabel>CAPA Status</InputLabel>
                    <Select
                      value={plan.status}
                      onChange={(e) =>
                        handleActionPlanChange(index, "status", e.target.value)
                      }
                      disabled={isEditMode && !isLoading}
                    >
                      <MenuItem value="Open">Open</MenuItem>
                      <MenuItem value="Close">Close</MenuItem>
                    </Select>
                  </FormControl>
                  {!isEditMode && (
                    <Button
                      startIcon={<DeleteIcon />}
                      onClick={() => handleRemoveActionPlan(index)}
                      disabled={isLoading}
                    >
                      Remove
                    </Button>
                  )}
                </Box>
              ))}
              {!isEditMode && actionPlans.length < 5 && (
                <Button
                  startIcon={<AddIcon />}
                  onClick={handleAddActionPlan}
                  disabled={isLoading}
                >
                  Add Action Plan
                </Button>
              )}
              <Box
                mt={2}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                {isEditMode ? (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setIsEditMode(false)}
                    disabled={isLoading}
                  >
                    Edit
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmitInvestigation}
                    disabled={isLoading}
                  >
                    Submit Investigation
                  </Button>
                )}
                {isLoading && <CircularProgress size={24} />}
              </Box>
            </Box>
          )}
        </Box>
      </Modal>

      {/* Close Form Modal */}
      <Modal
        open={isFormModalOpen}
        onClose={handleModalClose}
        aria-labelledby="fire-injury-form-modal-title"
      >
        <Box className={classes.modalContent}>
          <IconButton
            aria-label="close"
            onClick={handleModalClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
          {selectedInjury && (
            <Box>
              <Typography
                id="fire-injury-form-modal-title"
                variant="h6"
                gutterBottom
              >
                Close Fire Injury Report
              </Typography>
              <Typography>Case Number: {selectedInjury.caseNumber}</Typography>

              {/* Display Action Plan Progress */}
              <Typography variant="h6" gutterBottom>
                Action Plan Progress
              </Typography>
              {selectedInjury.actionPlans &&
                selectedInjury.actionPlans.map((plan, index) => (
                  <>
                    <Typography key={index}>
                      Action Plan {index + 1}: {plan.ApDescription}
                    </Typography>
                    <Typography>status: {plan.status}</Typography>
                  </>
                ))}

              <Typography>Date of Closing*</Typography>
              <TextField
                type="date"
                value={moment(date).format("YYYY-MM-DD")}
                onChange={handleDateChange}
                fullWidth
                margin="normal"
                disabled={true}
              />
              <Grid item xs={12}>
                <input
                  accept="image/*"
                  id="contained-button-file"
                  type="file"
                  onChange={handleImageChange}
                  style={{ display: "none" }}
                  disabled={!canCloseFireInjury()}
                />
                <label htmlFor="contained-button-file">
                  <Button variant="contained" color="primary" component="span">
                    Upload Image for Verification
                  </Button>
                </label>
                {imageOfVerification && (
                  <Typography>{imageOfVerification.name}</Typography>
                )}
              </Grid>
              <Box
                mt={2}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleCloseFireInjury}
                  disabled={isLoading || !canCloseFireInjury()}
                >
                  {isLoading ? "Closing..." : "Close the form"}
                </Button>
                {isLoading && <CircularProgress size={24} />}
              </Box>
              {!canCloseFireInjury() && (
                <Typography color="error">
                  All action plans must be closed before closing the fire injury
                  report.
                </Typography>
              )}
            </Box>
          )}
        </Box>
      </Modal>
     </Container>
    </Box>
    </LocalizationProvider>
  );
};

export default FireInjuryList;
