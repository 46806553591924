import React, { useState } from "react";
import {
  Container,
  Box,
  TextField,
  Button,
  Typography,
  Alert,
  Link,
} from "@mui/material";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { setLoginStatusLocally, setUIDLocally } from "./../utils/userUtils";
import { useLocation, useNavigate } from "react-router-dom";

const AuthenticationModule = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isUserSignIn, setIsUserSignIn] = useState(location.pathname.includes("User"));
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSwitch = (isUser) => {
    setIsUserSignIn(isUser);
    setError(null);
    setEmail("");
    setPassword("");
    navigate(isUser ? '/Login/User' : '/Login/Admin');
  };

  const handleSignIn = async (e) => {
    e.preventDefault();
    if (!email || !password) {
      setError("Please enter email and password");
      return;
    }

    setIsLoading(true);
    setError(null);
    try {
      const userCredential = await firebase
        .auth()
        .signInWithEmailAndPassword(email, password);
      const user = userCredential.user;

      if (isUserSignIn) {
        const userDocRef = firebase
          .firestore()
          .collection("peoples")
          .doc("users")
          .collection("users")
          .doc(user.uid);
        const userDoc = await userDocRef.get();
        if (userDoc.exists) {
          await Promise.all([
            setLoginStatusLocally(true),
            setUIDLocally(user.uid),
          ]);
          const userData = userDoc.data();
          const { company_name, email, role, user_name, permissions } = userData;

          localStorage.setItem(
            "userData",
            JSON.stringify({
              userId: user.uid,
              company_name,
              email,
              role,
              user_name,
              permissions,
            })
          );

          navigate("/user/user", {
            state: {
              userId: user.uid,
              company_name,
              email,
              role,
              user_name,
              permissions,
            },
          });
        } else {
          setError("User not found");
        }
      } else {
        const adminDocRef = firebase
          .firestore()
          .collection("admins")
          .doc(user.uid);
        const adminDoc = await adminDocRef.get();
        if (adminDoc.exists) {
          await Promise.all([
            setLoginStatusLocally(true),
            setUIDLocally(user.uid),
          ]);
          navigate("/company-list");
        } else {
          setError("Admin not found");
        }
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box sx={{ bgcolor: '#f5f5f5', minHeight: '100vh', display: 'flex', alignItems: 'center' }}>
      <Container maxWidth="xs">
        <Box
          sx={{
            backgroundColor: 'white',
            padding: 4,
            borderRadius: 2,
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography component="h1" variant="h5" sx={{ color: '#000', mb: 3 }}>
            {isUserSignIn ? "User Sign-In" : "Admin Sign-In"}
          </Typography>
          <Box
            component="form"
            onSubmit={handleSignIn}
            noValidate
            sx={{ mt: 1, width: '100%' }}
          >
            {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#000",
                  },
                  "&:hover fieldset": {
                    borderColor: "#000",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#000",
                  },
                },
                mb: 2
              }}
              InputLabelProps={{
                style: {
                  color: "#000",
                },
              }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#000",
                  },
                  "&:hover fieldset": {
                    borderColor: "#000",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#000",
                  },
                },
                mb: 2
              }}
              InputLabelProps={{
                style: {
                  color: "#000",
                },
              }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                mt: 3,
                mb: 2,
                color: "#fff",
                backgroundColor: "#000",
                "&:hover": {
                  backgroundColor: "#333",
                },
              }}
              disabled={isLoading}
            >
              {isLoading ? "Signing In..." : "Sign In"}
            </Button>
            
            <Box sx={{ textAlign: 'center', mt: 2 }}>
              <Typography variant="body2" color="text.secondary">
                {isUserSignIn ? "Are you an admin? " : "Are you a user? "}
                <Link
                  component="button"
                  variant="body2"
                  onClick={() => handleSwitch(!isUserSignIn)}
                  sx={{
                    color: '#000',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                    '&:hover': {
                      color: '#333',
                    }
                  }}
                >
                  {isUserSignIn ? "Login as Admin" : "Login as User"}
                </Link>
              </Typography>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default AuthenticationModule;