import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';

const NotificationCard = ({ title, severity, SEVERITY_COLORS }) => {
  const colors = SEVERITY_COLORS[severity] || {
    main: '#9E9E9E', // Default color if severity is not found
    light: '#F5F5F5'
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: 2,
        backgroundColor: colors.light,
        borderLeft: `4px solid ${colors.main}`,
        borderRadius: 1,
        boxShadow: 1,
        marginBottom: 2,
        width: '100%',
      }}
    >
      <Typography
        variant="h6"
        sx={{
          color: colors.main,
          fontWeight: 'bold',
          marginBottom: 1,
        }}
      >
        {title}
      </Typography>
      <Typography variant="body2" color="textSecondary">
        Severity: {severity}
      </Typography>
    </Box>
  );
};

NotificationCard.propTypes = {
  title: PropTypes.string.isRequired,
  severity: PropTypes.oneOf(['Low', 'Medium', 'High']).isRequired,
  SEVERITY_COLORS: PropTypes.objectOf(
    PropTypes.shape({
      main: PropTypes.string.isRequired,
      light: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default NotificationCard;
