import React from 'react';
import { useLocation } from 'react-router-dom';
import { 
  Card, 
  CardContent, 
  Typography, 
  Avatar, 
  Box, 
  Divider, 
  Stack 
} from '@mui/material';
import { Email, Work, person } from '@mui/icons-material';

const UserProfile = () => {
  const location = useLocation();
  const user = location.state;

  if (!user) {
    return (
      <Box 
        display="flex" 
        justifyContent="center" 
        alignItems="center" 
        height="100vh"
      >
        <Typography variant="h6" color="error">
          Error: No user data available
        </Typography>
      </Box>
    );
  }

  const { userName, email, profilePicture, companyName, designation } = user;

  return (
    <Box 
      display="flex" 
      justifyContent="center" 
      alignItems="center" 
      height="100vh" 
      bgcolor="background.default"
      p={3}
    >
      <Card
        sx={{
          maxWidth: 400,
          width: '100%',
          borderRadius: 3,
          boxShadow: '0 8px 24px rgba(0,0,0,0.12)',
          transition: 'transform 0.3s ease-in-out',
          '&:hover': {
            transform: 'scale(1.02)'
          }
        }}
      >
        <Box 
          display="flex" 
          flexDirection="column" 
          alignItems="center" 
          p={3}
          bgcolor="primary.light"
          color="primary.contrastText"
        >
          <Avatar
            src={profilePicture}
            alt={userName}
            sx={{
              width: 120,
              height: 120,
              border: '4px solid white',
              boxShadow: '0 4px 12px rgba(0,0,0,0.2)'
            }}
          />
          <Typography variant="h5" fontWeight="bold" mt={2}>
            {userName}
          </Typography>
          <Typography variant="subtitle1" color="inherit" opacity={0.8}>
            {designation}manager
          </Typography>
        </Box>

        <CardContent>
          <Stack spacing={2} divider={<Divider flexItem />}>
            <Box display="flex" alignItems="center" gap={2}>
              <Work color="action" />
              <Typography variant="body1" color="text.secondary">
                {companyName}
              </Typography>
            </Box>
            
            <Box display="flex" alignItems="center" gap={2}>
              <Email color="action" />
              <Typography variant="body1" color="text.secondary">
                {email}
              </Typography>
            </Box>
          </Stack>
        </CardContent>
      </Card>
    </Box>
  );
};

export default UserProfile;