import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Box,
  Grid,
  TextField,
  MenuItem,
  Button,
  IconButton,
} from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
//import firebase from './firebase';

//const firestore = firebase.firestore();
import { firestore } from '../../firebase/firebaseConfig';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
  },
  assignmentContainer: {
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(2),
  },
  label: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
  },
  actionButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2),
  },
  error: {
    color: theme.palette.error.main,
    marginTop: theme.spacing(0.5),
  },
}));

const ZoneAssignment = ({ companyName }) => {
 // console.log(companyName);
  const classes = useStyles();
  const [assignments, setAssignments] = useState([
    {
      leader: '',
      zone: '',
      subZone: '',
    },
  ]);
  const [leaders, setLeaders] = useState([]);
  const [zones, setZones] = useState([]);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const fetchLeaders = async () => {
      const leadersSnapshot = await firestore
        .collection('company_data')
        .doc(companyName)
        .collection(companyName)
        .doc('for_admin')
        .collection('zone_leader')
        .get();
      const leadersData = leadersSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setLeaders(leadersData);
    };

    const fetchZones = async () => {
      const zonesSnapshot = await firestore
        .collection('company_data')
        .doc(companyName)
        .collection(companyName)
        .doc('for_admin')
        .collection('company_zones')
        .get();
      const zonesData = zonesSnapshot.docs.map((doc) => {
        const data = doc.data();
        const subZones = Array.isArray(data.subZone) ? data.subZone : [];
        return {
          id: doc.id,
          name: data.name,
          alias: data.alias,
          subZones: subZones,
          category: data.category,
          remark: data.remark,
        };
      });
      setZones(zonesData);
    };

    fetchLeaders();
    fetchZones();
  }, [companyName]);

  const handleAddAssignment = () => {
    setAssignments([
      ...assignments,
      {
        leader: '',
        zone: '',
        subZone: '',
      },
    ]);
  };

  const handleSubmit = async () => {
    const errors = validateFields();
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      alert('Please fill in all mandatory fields');
      return;
    }

    try {
      const batch = firestore().batch();
      const savedAssignmentIds = [];

      for (const assignment of assignments) {
        const assignmentRef = firestore
          .collection('company_data')
          .doc(companyName)
          .collection(companyName)
          .doc('for_admin')
          .collection('zone_assignment')
          .doc();
        batch.set(assignmentRef, {
          leaderId: assignment.leader,
          zoneId: assignment.zone,
          subZone: assignment.subZone,
        });
        savedAssignmentIds.push(assignmentRef.id);
      }
      await batch.commit();

      console.log('Zone assignments saved with IDs:', savedAssignmentIds);
      alert('Your data has been submitted successfully!');
      clearForm();
    } catch (error) {
      console.error('Error saving zone assignments:', error);
      alert('Error occurred while submitting data. Please try again.');
    }
  };

  const validateFields = () => {
    const errors = {};

    assignments.forEach((assignment, index) => {
      if (!assignment.leader) {
        errors[`leader-${index}`] = 'Leader is required';
      }
      if (!assignment.zone) {
        errors[`zone-${index}`] = 'Zone is required';
      }
    });

    return errors;
  };

  const clearForm = () => {
    setAssignments([
      {
        leader: '',
        zone: '',
        subZone: '',
      },
    ]);
    setErrors({});
  };

  const handleAssignmentChange = (value, field, index) => {
    const updatedAssignments = [...assignments];
    updatedAssignments[index][field] = value;
    setAssignments(updatedAssignments);
    setErrors({ ...errors, [`${field}-${index}`]: '' }); // Clear error for this field
  };

  const handleRemoveAssignment = (index) => {
    const updatedAssignments = [...assignments];
    updatedAssignments.splice(index, 1);
    setAssignments(updatedAssignments);
  };

  const getSubZonesForZone = (zoneId) => {
    const zone = zones.find((z) => z.id === zoneId);
    return zone ? zone.subZones : [];
  };

  return (
    <Box className={classes.container}>
      <Typography variant="h5" gutterBottom>
        Zone Assignment
      </Typography>
      {assignments.map((assignment, index) => (
        <Box key={index} className={classes.assignmentContainer}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body1" className={classes.label}>
                Leader
              </Typography>
              <TextField
                fullWidth
                select
                value={assignment.leader}
                onChange={(e) =>
                  handleAssignmentChange(e.target.value, 'leader', index)
                }
                error={!!errors[`leader-${index}`]}
                helperText={errors[`leader-${index}`]}
              >
                {leaders.map((leader) => (
                  <MenuItem key={leader.id} value={leader.id}>
                    {leader.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" className={classes.label}>
                Zone
              </Typography>
              <TextField
                fullWidth
                select
                value={assignment.zone}
                onChange={(e) => {
                  handleAssignmentChange(e.target.value, 'zone', index);
                  handleAssignmentChange('', 'subZone', index);
                }}
                error={!!errors[`zone-${index}`]}
                helperText={errors[`zone-${index}`]}
              >
                {zones.map((zone) => (
                  <MenuItem key={zone.id} value={zone.id}>
                    {zone.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" className={classes.label}>
                Sub Zone
              </Typography>
              <TextField
                fullWidth
                select
                value={assignment.subZone}
                onChange={(e) =>
      handleAssignmentChange(e.target.value, 'subZone', index)
    }
  >
    {getSubZonesForZone(assignment.zone).map((subZone) => (
      <MenuItem key={subZone} value={subZone}>
        {subZone}
      </MenuItem>
    ))}
  </TextField>
</Grid>
          </Grid>
          <Box className={classes.actionButtons}>
            {index === assignments.length - 1 && (
              <>
                <IconButton onClick={handleAddAssignment}>
                  <AddCircleOutlineIcon />
                </IconButton>
                {index > 0 && (
                  <IconButton onClick={() => handleRemoveAssignment(index)}>
                    <RemoveCircleOutlineIcon />
                  </IconButton>
                )}
              </>
            )}
          </Box>
        </Box>
      ))}
      <Button variant="contained" color="primary" onClick={handleSubmit}>
        Submit
      </Button>
    </Box>
  );
};

export default ZoneAssignment;

