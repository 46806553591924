import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  TextField,
  Grid,
  Divider,
  Chip
} from '@mui/material';
import { format } from 'date-fns';
import { firestore } from '../../firebase/firebaseConfig';
import ChecklistSection from './ChecklistSection';

import { workPermitTypeQuestionList, ppesList } from './../../componants/workPermitTypeQuestionList';

// Helper function for status colors
const getStatusColor = (status) => {
  switch (status) {
    case 'ongoing':
      return 'primary';
    case 'hold':
      return 'warning';
    case 'closed':
      return 'success';
    case 'rejected':
      return 'error';
    default:
      return 'default';
  }
};

const WorkPermitModal = ({ open, onClose, permit: initialPermit, company_name }) => {
  // Keep local state of permit data that can be updated
  const [permit, setPermit] = useState(initialPermit);
  const [specialRemarks, setSpecialRemarks] = useState('');
  const [loading, setLoading] = useState(false);


 const [selectedStatus, setSelectedStatus] = useState('');
  const [remarks, setRemarks] = useState('');
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);

  // Set up real-time listener for permit updates
  useEffect(() => {
    if (!open || !initialPermit?.id) return;

    const permitRef = firestore
      .collection('company_data')
      .doc(company_name)
      .collection(company_name)
      .doc('for_user')
      .collection('work_permit_v2')
      .doc(initialPermit.id);

    const unsubscribe = permitRef.onSnapshot((doc) => {
      if (doc.exists) {
        const updatedPermit = { ...doc.data(), id: doc.id };
        setPermit(updatedPermit);
      }
    }, (error) => {
      console.error("Error fetching real-time updates:", error);
    });

    // Cleanup listener on modal close or component unmount
    return () => unsubscribe();
  }, [open, initialPermit?.id, company_name]);

  const openConfirmationDialog = (status) => {
    setSelectedStatus(status);
    setConfirmationDialogOpen(true);
  };

  const handleConfirmation = async () => {
    setLoading(true);
    try {
      const permitRef = firestore
        .collection('company_data')
        .doc(company_name)
        .collection(company_name)
        .doc('for_user')
        .collection('work_permit_v2')
        .doc(permit.id);

      await permitRef.update({
        status: selectedStatus,
        special_remarks: specialRemarks,
        confirmationRemarks: remarks,

      });

      setConfirmationDialogOpen(false);
      onClose();
    } catch (error) {
      console.error('Error updating permit status:', error);
    } finally {
      setLoading(false);
    }
  };

  const getPermitType = (type) => {
    switch(type) {
      case "Hot Work": return "HotWork";
      case "Work at Height": return "WorkAtHeight";
      case "Electrical Work": return "ElectricalWork";
      case "Confined Space": return "ConfinedSpace";
      default: return "";
    }
  };

  const handleUpdateStatus = async (newStatus) => {
    setLoading(true);
    try {
      const permitRef = firestore
        .collection('company_data')
        .doc(company_name)
        .collection(company_name)
        .doc('for_user')
        .collection('work_permit_v2')
        .doc(permit.id);

      await permitRef.update({
        status: newStatus,
        special_remarks: specialRemarks
      });

      onClose();
    } catch (error) {
      console.error('Error updating permit status:', error);
    } finally {
      setLoading(false);
    }
  };
  
  const { generalPrecautionsList, specialPrecautionsList } = 
    workPermitTypeQuestionList[getPermitType(permit.type)] || {};

  return (
    <>
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        Work Permit Details
        <Chip
          label={permit.status}
          color={getStatusColor(permit.status)}
          sx={{ ml: 2 }}
        />
      </DialogTitle>
      <DialogContent>
        <Box sx={{ mb: 4 }}>
          <Typography variant="h6" gutterBottom>Basic Information</Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle2" color="textSecondary">Case Number</Typography>
              <Typography variant="body1">{permit.caseNumber}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle2" color="textSecondary">Work Permit Type</Typography>
              <Typography variant="body1">{permit.type}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              {permit.basic_info.isMultiDay ? (
                <>
                  <Typography variant="subtitle2" color="textSecondary">Date Range</Typography>
                  <Typography variant="body1">
                    {`${format(permit.basic_info.startDate.toDate(), 'dd/MM/yyyy')} - 
                    ${format(permit.basic_info.endDate.toDate(), 'dd/MM/yyyy')}`}
                  </Typography>
                </>
              ) : (
                <>
                  <Typography variant="subtitle2" color="textSecondary">Date & Time</Typography>
                  <Typography variant="body1">
                    {`${format(permit.basic_info.date.toDate(), 'dd/MM/yyyy')} 
                    (${format(permit.basic_info.start_time.toDate(), 'HH:mm')} - 
                    ${format(permit.basic_info.end_time.toDate(), 'HH:mm')})`}
                  </Typography>
                </>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle2" color="textSecondary">Plant/Area</Typography>
              <Typography variant="body1">{permit.basic_info.plant}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle2" color="textSecondary">Section</Typography>
              <Typography variant="body1">{permit.basic_info.section}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle2" color="textSecondary">Doc. No.</Typography>
              <Typography variant="body1">{permit.basic_info.doc_no}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2" color="textSecondary">Job Description</Typography>
              <Typography variant="body1">{permit.basic_info.job_description}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2" color="textSecondary">Location of Work</Typography>
              <Typography variant="body1">{permit.basic_info.location_of_work}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2" color="textSecondary">Person/Contractor</Typography>
              <Typography variant="body1">{permit.basic_info.name_of_person}</Typography>
            </Grid>
          </Grid>
        </Box>

        <Divider sx={{ my: 3 }} />

        <ChecklistSection
          title="General Precautions"
          items={generalPrecautionsList}
          data={permit.general_precautions}
          permitId={permit.id}
          company_name={company_name}
          fieldName="general_precautions"
    //      onDataUpdate={handleDataUpdate}
        />

        <Divider sx={{ my: 3 }} />

        <ChecklistSection
          title="Special Precautions"
          items={specialPrecautionsList}
          data={permit.special_precautions}
          permitId={permit.id}
          company_name={company_name}
          fieldName="special_precautions"
      //    onDataUpdate={handleDataUpdate}
        />

        <Divider sx={{ my: 3 }} />

        <ChecklistSection
          title="Necessary PPEs"
          items={ppesList}
          data={permit.necessary_ppes}
          permitId={permit.id}
          company_name={company_name}
          fieldName="necessary_ppes"
        //  onDataUpdate={handleDataUpdate}
        />

        <Box sx={{ mt: 3 }}>
          <Typography variant="h6" gutterBottom>NOC Status</Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle2">Electrical NOC</Typography>
              <Chip
                label={permit.noc.electrical.status}
                color={permit.noc.electrical.status === 'confirmed' ? 'success' : 'warning'}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle2">Mechanical NOC</Typography>
              <Chip
                label={permit.noc.mechanical.status}
                color={permit.noc.mechanical.status === 'confirmed' ? 'success' : 'warning'}
              />
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ mt: 3 }}>
          <TextField
            fullWidth
            label="Special Remarks"
            multiline
            rows={4}
            value={specialRemarks}
            onChange={(e) => setSpecialRemarks(e.target.value)}
          />
        </Box>
      </DialogContent>

      {/* <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button 
          onClick={() => handleUpdateStatus('hold')} 
          color="warning"
          disabled={loading}
        >
          Hold
        </Button>
        <Button 
          onClick={() => handleUpdateStatus('rejected')} 
          color="error"
          disabled={loading}
        >
          Reject
        </Button>
        <Button 
          onClick={() => handleUpdateStatus('closed')} 
          color="success"
          disabled={loading}
        >
          Close Permit
        </Button>
      </DialogActions> */}

      <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={() => openConfirmationDialog('hold')} color="warning" disabled={loading}>
            Hold
          </Button>
          <Button
            onClick={() => openConfirmationDialog('rejected')}
            color="error"
            disabled={loading}
          >
            Reject
          </Button>
          <Button
            onClick={() => openConfirmationDialog('closed')}
            color="success"
            disabled={loading}
          >
            Close Permit
          </Button>
        </DialogActions>
      </Dialog>

    {/* </Dialog> */}

    {/* Confirmation Dialog */}
    <Dialog open={confirmationDialogOpen} onClose={() => setConfirmationDialogOpen(false)}>
    <DialogTitle>Confirm Action</DialogTitle>
    <DialogContent>
      <Typography gutterBottom>
        Are you sure you want to mark this permit as <strong>{selectedStatus}</strong>?
      </Typography>
      <TextField
        fullWidth
        label="Confirmation Remarks"
        multiline
        rows={4}
        value={remarks}
        onChange={(e) => setRemarks(e.target.value)}
        sx={{ mt: 2 }}
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={() => setConfirmationDialogOpen(false)}>Cancel</Button>
      <Button
        onClick={handleConfirmation}
        color="primary"
        variant="contained"
        disabled={loading || !remarks.trim()}
      >
        Confirm
      </Button>
    </DialogActions>
  </Dialog>
</>
  );
};

export default WorkPermitModal;