// import React, { useState, useEffect } from 'react';
// import {
//   Typography,
//   Box,
//   Checkbox,
//   FormControlLabel,
//   Grid,
//   CircularProgress
// } from '@mui/material';
// import { firestore } from '../../firebase/firebaseConfig';

// const ChecklistSection = ({ title, items, data, permitId, company_name, fieldName }) => {
//   // State to track loading state for each checkbox
//   const [loadingStates, setLoadingStates] = useState({});
//   // State to track verification status for each item
//   const [verificationStates, setVerificationStates] = useState({});
// console.log("args:", permitId,fieldName)
//   // Fetch initial verification states from database
//   useEffect(() => {
//     const fetchVerificationStates = () => {
//       const states = {};
//       items.forEach((_, index) => {
//         states[index] = data[index + 1]?.verified || false;
//       });
//       setVerificationStates(states);
//     };

//     fetchVerificationStates();
//   }, [data, items]);

//   const handleVerificationChange = async (index, verified) => {
//     try {
//       // Set loading state for this specific checkbox
//       setLoadingStates(prev => ({ ...prev, [index]: true }));

//       // Update local state immediately for responsive UI
//       setVerificationStates(prev => ({ ...prev, [index]: verified }));

//       const permitRef = firestore
//         .collection('company_data')
//         .doc(company_name)
//         .collection(company_name)
//         .doc('for_user')
//         .collection('work_permit_v2')
//         .doc(permitId);

//       const updateData = {
//         [fieldName]: {
//           ...data,
//           [index + 1]: {
//             ...data[index + 1],
//             verified,
//           },
//         },
//       };

//       await permitRef.update(updateData);
//     } catch (error) {
//       // Revert the state if there's an error
//       setVerificationStates(prev => ({ ...prev, [index]: !verified }));
//       console.error(`Error updating ${fieldName} verification:`, error);
//     } finally {
//       // Clear loading state
//       setLoadingStates(prev => ({ ...prev, [index]: false }));
//     }
//   };

//   return (
//     <Box>
//       <Typography variant="h6" gutterBottom>{title}</Typography>
//       {items.map((item, index) => (
//         <Grid container spacing={2} key={index} sx={{ mb: 1 }}>
//           <Grid item xs={6}>
//             <Typography variant="body1">{item}</Typography>
//           </Grid>
//           <Grid item xs={4}>
//             <Typography variant="body2" color="textSecondary">
//               {data[index + 1]?.not_required === true
//                 ? 'marked as not required'
//                 : data[index + 1]?.yes === 'yes'
//                   ? 'marked as yes'
//                   : 'marked as no'}
//             </Typography>
//           </Grid>
//           <Grid item xs={2} className="flex items-center">
//             <FormControlLabel
//               control={
//                 <Checkbox
//                   checked={verificationStates[index] || false}
//                   onChange={(e) => handleVerificationChange(index, e.target.checked)}
//                   disabled={loadingStates[index]}
//                 />
//               }
//               label="Verify"
//             />
//             {console.log('data2', data)}
//             {loadingStates[index] && (
//               <CircularProgress 
//                 size={20} 
//                 className="ml-2"
//               />
//             )}
//           </Grid>
//         </Grid>
//       ))}
//     </Box>
//   );
// };

// export default ChecklistSection;

// import React, { useState, useEffect } from 'react';
// import {
//   Typography,
//   Box,
//   Checkbox,
//   FormControlLabel,
//   Grid,
//   CircularProgress
// } from '@mui/material';
// import { firestore } from '../../firebase/firebaseConfig';

// const ChecklistSection = ({ title, items, data, permitId, company_name, fieldName, onDataUpdate }) => {
//   // State to track loading state for each checkbox
//   const [loadingStates, setLoadingStates] = useState({});
//   // State to track verification status for each item
//   const [verificationStates, setVerificationStates] = useState({});

//   // Initialize verification states when component mounts or data changes
//   useEffect(() => {
//     const states = {};
//     items.forEach((_, index) => {
//       states[index] = data[index + 1]?.verified || false;
//     });
//     setVerificationStates(states);
//   }, [data, items]);

//   const handleVerificationChange = async (index, verified) => {
//     try {
//       // Set loading state for this specific checkbox
//       setLoadingStates(prev => ({ ...prev, [index]: true }));

//       // Update local state immediately for responsive UI
//       setVerificationStates(prev => ({ ...prev, [index]: verified }));

//       const permitRef = firestore
//         .collection('company_data')
//         .doc(company_name)
//         .collection(company_name)
//         .doc('for_user')
//         .collection('work_permit_v2')
//         .doc(permitId);

//       // Create the update data
//       const updateData = {
//         [fieldName]: {
//           ...data,
//           [index + 1]: {
//             ...data[index + 1],
//             verified,
//           },
//         },
//       };

//       // Update Firebase
//       await permitRef.update(updateData);

//       // Fetch the updated document to ensure we have the latest data
//       const updatedDoc = await permitRef.get();
//       const updatedData = updatedDoc.data();

//       // Call the callback with updated data
//       if (onDataUpdate) {
//         onDataUpdate(fieldName, updatedData[fieldName]);
//       }

//     } catch (error) {
//       // Revert the state if there's an error
//       setVerificationStates(prev => ({ ...prev, [index]: !verified }));
//       console.error(`Error updating ${fieldName} verification:`, error);
//     } finally {
//       // Clear loading state
//       setLoadingStates(prev => ({ ...prev, [index]: false }));
//     }
//   };

//   return (
//     <Box>
//       <Typography variant="h6" gutterBottom>{title}</Typography>
//       {items.map((item, index) => (
//         <Grid container spacing={2} key={index} sx={{ mb: 1 }}>
//           <Grid item xs={6}>
//             <Typography variant="body1">{item}</Typography>
//           </Grid>
//           <Grid item xs={4}>
//             <Typography variant="body2" color="textSecondary">
//               {data[index + 1]?.not_required === true
//                 ? 'marked as not required'
//                 : data[index + 1]?.yes === 'yes'
//                   ? 'marked as yes'
//                   : 'marked as no'}
//             </Typography>
//           </Grid>
//           <Grid item xs={2} className="flex items-center">
//             <FormControlLabel
//               control={
//                 <Checkbox
//                   checked={verificationStates[index] || false}
//                   onChange={(e) => handleVerificationChange(index, e.target.checked)}
//                   disabled={loadingStates[index]}
//                 />
//               }
//               label="Verify"
//             />
//             {loadingStates[index] && (
//               <CircularProgress 
//                 size={20} 
//                 className="ml-2"
//               />
//             )}
//           </Grid>
//         </Grid>
//       ))}
//     </Box>
//   );
// };

// export default ChecklistSection;

import React, { useState, useEffect } from 'react';
import {
  Typography,
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  CircularProgress
} from '@mui/material';
import { firestore } from '../../firebase/firebaseConfig';

const ChecklistSection = ({ title, items, data, permitId, company_name, fieldName }) => {
  const [loadingStates, setLoadingStates] = useState({});
  const [verificationStates, setVerificationStates] = useState({});

  // Update local state whenever data prop changes
  useEffect(() => {
    const states = {};
    items.forEach((_, index) => {
      states[index] = data?.[index + 1]?.verified || false;
    });
    setVerificationStates(states);
  }, [data, items]);

  const handleVerificationChange = async (index, verified) => {
    try {
      setLoadingStates(prev => ({ ...prev, [index]: true }));

      const permitRef = firestore
        .collection('company_data')
        .doc(company_name)
        .collection(company_name)
        .doc('for_user')
        .collection('work_permit_v2')
        .doc(permitId);

      const updateData = {
        [fieldName]: {
          ...data,
          [index + 1]: {
            ...data?.[index + 1],
            verified,
          },
        },
      };

      await permitRef.update(updateData);
      
      // Update local state after successful Firebase update
      setVerificationStates(prev => ({ ...prev, [index]: verified }));
      
    } catch (error) {
      console.error(`Error updating ${fieldName} verification:`, error);
      // Revert local state if update fails
      setVerificationStates(prev => ({ ...prev, [index]: !verified }));
    } finally {
      setLoadingStates(prev => ({ ...prev, [index]: false }));
    }
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom>{title}</Typography>
      {items.map((item, index) => (
        <Grid container spacing={2} key={index} sx={{ mb: 1 }}>
          <Grid item xs={6}>
            <Typography variant="body1">{item}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body2" color="textSecondary">
              {data?.[index + 1]?.not_required === true
                ? 'marked as not required'
                : data?.[index + 1]?.yes === 'yes'
                  ? 'marked as yes'
                  : 'marked as no'}
            </Typography>
          </Grid>
          <Grid item xs={2} className="flex items-center">
            <FormControlLabel
              control={
                <Checkbox
                  checked={verificationStates[index] || false}
                  onChange={(e) => handleVerificationChange(index, e.target.checked)}
                  disabled={loadingStates[index]}
                />
              }
              label="Verify"
            />
            {loadingStates[index] && (
              <CircularProgress 
                size={20} 
                className="ml-2"
              />
            )}
          </Grid>
        </Grid>
      ))}
    </Box>
  );
};

export default ChecklistSection;