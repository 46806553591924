import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Card,
  CardContent,
  Button,
  Grid,
  Modal,
  Box,
  TextField,
  IconButton,
  CircularProgress,
  Paper
} from '@mui/material';
import { styled } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';
import { firestore } from '../../firebase/firebaseConfig'; // Adjust the import path as needed
import { useLocation } from 'react-router-dom';

const StyledCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  '&:hover': {
    boxShadow: theme.shadows[4],
  },
}));
const StyledPaper = styled(Paper)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  '&:hover': {
    boxShadow: theme.shadows[4],
  },
}));

//modal styling
const ModalContent = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[5],
  padding: theme.spacing(4),
  outline: 'none',
  maxHeight: '80vh',
  overflowY: 'auto',
}));

const WorkPermitAuditorList = () => {
  const [workPermits, setWorkPermits] = useState([]);
  const [selectedWorkPermit, setSelectedWorkPermit] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isInspection, setIsInspection] = useState(true);
  const [date, setDate] = useState(new Date());
  const [noOfWorkPermitInspected, setNoOfWorkPermitInspected] = useState(0);
  const [noOfWorkPermitComplied, setNoOfWorkPermitComplied] = useState(0);
  const [errors, setErrors] = useState({});

  const location = useLocation();
  const { company_name } = location.state || {};

  useEffect(() => {
    const getWorkPermits = async () => {
      const workPermitsRef = firestore.collection('company_data').doc(company_name).collection(company_name).doc('for_user').collection('work_permit');
      const snapshot = await workPermitsRef.get();
      const workPermitsData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setWorkPermits(workPermitsData);
    };
    getWorkPermits();
  }, [company_name]);

  const formatDate = (date) => {
    if (typeof date === 'string' && date.includes('-')) {
      return moment(date).format('MMMM D, YYYY');
    }
    if (date && date.seconds) {
      return moment(date.seconds * 1000 + date.nanoseconds / 1000000).format('MMMM D, YYYY');
    }
    return 'Invalid Date';
  };

  const openModal = (workPermit, type) => {
    setSelectedWorkPermit(workPermit);
    if (type === 'inspect') {
      setNoOfWorkPermitInspected(workPermit.noOfWorkPermitInspected || 0);
      setIsInspection(true);
    } else if (type === 'comply') {
      setNoOfWorkPermitComplied(workPermit.noOfWorkPermitComplied || 0);
      setIsInspection(false);
    }
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedWorkPermit(null);
    setNoOfWorkPermitInspected(0);
    setNoOfWorkPermitComplied(0);
    setErrors({});
  };
  
  const handleDateChange = (event) => {
    setDate(event.target.value);
  };

  const handleInspectSubmit = async () => {
    const remainingToBeInspected = selectedWorkPermit.noOfWorkPermitIssued - (selectedWorkPermit.noOfWorkPermitInspected || 0);
    if (noOfWorkPermitInspected <= remainingToBeInspected && noOfWorkPermitInspected >= 0) {
      setIsLoading(true);
      try {
        const workPermitRef = firestore.collection('company_data').doc(company_name).collection(company_name).doc('for_user').collection('work_permit').doc(selectedWorkPermit.id);
        await workPermitRef.update({
          noOfWorkPermitInspected: (selectedWorkPermit.noOfWorkPermitInspected || 0) + noOfWorkPermitInspected,
        });
        setIsLoading(false);
        closeModal();
        alert('Work permit inspected Successfully.');
      } catch (error) {
        console.error('Error updating work permit inspection:', error);
        setIsLoading(false);
      }
    } else {
      alert('Invalid value, No. of work permit inspected can not be greater than remaining to be inspected.');
      setErrors({ noOfWorkPermitInspected: 'Invalid value. Please enter a valid number.' });
    }
  };

  const handleComplySubmit = async () => {
    const remainingToBeComplied = (selectedWorkPermit.noOfWorkPermitInspected || 0) - (selectedWorkPermit.noOfWorkPermitComplied || 0);
    if (noOfWorkPermitComplied <= remainingToBeComplied && noOfWorkPermitComplied >= 0) {
      setIsLoading(true);
      try {
        const workPermitRef = firestore.collection('company_data').doc(company_name).collection(company_name).doc('for_user').collection('work_permit').doc(selectedWorkPermit.id);
        await workPermitRef.update({
          noOfWorkPermitComplied: (selectedWorkPermit.noOfWorkPermitComplied || 0) + noOfWorkPermitComplied,
        });
        setIsLoading(false);
        closeModal();
        alert('Work permit compiled Successfully.');
      } catch (error) {
        console.error('Error updating work permit compliance:', error);
        setIsLoading(false);
      }
    } else {
      alert('Invalid value, No. of work permit compiled can not be greater than remaining to be compiled.');
      setErrors({ noOfWorkPermitComplied: 'Invalid value. Please enter a valid number.' });
    }
  };

  return (
    <Box sx={{marginTop:12}}>
        <Container maxWidth="lg">
      <Typography variant="h5" component="div" gutterBottom>
        Work Permit Audit
      </Typography>
      <Box mt={3}>
      <Grid container spacing={2}>
        {workPermits.map((workPermit) => (
          <Grid item xs={12} sm={6} md={4} key={workPermit.id}>
            <StyledCard>
              <CardContent>
                <Typography><strong>Case Number:</strong> {workPermit.caseNumber}</Typography>
                <Typography><strong>Date:</strong> {formatDate(workPermit.date)}</Typography>
                <Typography><strong>Area Name:</strong> {workPermit.areaName}</Typography>
                <Typography><strong>Description:</strong> {workPermit.description}</Typography>
                <Typography color={workPermit.isPending ? "error" : "green"}><strong>Status:</strong> {workPermit.isPending ? 'Pending' : 'Closed'}</Typography>
                <Box mt={2}>
                  <Button variant="contained" color="primary" onClick={() => openModal(workPermit, 'inspect')} style={{ marginRight: '10px' }}>
                    Inspect
                  </Button>
                  <Button variant="contained" color="secondary" onClick={() => openModal(workPermit, 'comply')}>
                    Compile
                  </Button>
                </Box>
              </CardContent>
            </StyledCard>
          </Grid>
        ))}
      </Grid>
      </Box>

      <Modal
        open={isModalOpen}
        onClose={closeModal}
        aria-labelledby="work-permit-modal-title"
        aria-describedby="work-permit-modal-description"
      >
        <ModalContent>
          <IconButton
            aria-label="close"
            onClick={closeModal}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
          {selectedWorkPermit && (
            <Box>
              <Typography id="work-permit-modal-title" variant="h6" gutterBottom>Work Permit Details</Typography>
              <Typography>Date: {formatDate(selectedWorkPermit.date)}</Typography>
              <Typography>Department Name: {selectedWorkPermit.departmentName}</Typography>
              <Typography>No. of Work Permits Issued: {selectedWorkPermit.noOfWorkPermitIssued}</Typography>
              {isInspection ? (
                <>
                  <Typography>No. of Work Permits Inspected: {selectedWorkPermit.noOfWorkPermitInspected || 0}</Typography>
                  <Typography>Remaining to be Inspected: {selectedWorkPermit.noOfWorkPermitIssued - (selectedWorkPermit.noOfWorkPermitInspected || 0)}</Typography>
                  <TextField
                    label="No. of Work Permit Inspected"
                    type="number"
                    value={noOfWorkPermitInspected.toString()}
                    onChange={(e) => setNoOfWorkPermitInspected(parseInt(e.target.value, 10) || 0)}
                    fullWidth
                    margin="normal"
                    error={!!errors.noOfWorkPermitInspected}
                    helperText={errors.noOfWorkPermitInspected}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleInspectSubmit}
                    disabled={isLoading}
                    fullWidth
                  >
                    {isLoading ? <CircularProgress size={24} /> : 'Inspect it'}
                  </Button>
                </>
              ) : (
                <>
                  <Typography>No. of Work Permits Inspected: {selectedWorkPermit.noOfWorkPermitInspected || 0}</Typography>
                  <Typography>No. of Work Permits Complied: {selectedWorkPermit.noOfWorkPermitComplied || 0}</Typography>
                  <Typography>Remaining to be Complied: {(selectedWorkPermit.noOfWorkPermitInspected || 0) - (selectedWorkPermit.noOfWorkPermitComplied || 0)}</Typography>
                  <TextField
                    label="No. of Work Permit Complied"
                    type="number"
                    value={noOfWorkPermitComplied.toString()}
                    onChange={(e) => setNoOfWorkPermitComplied(parseInt(e.target.value, 10) || 0)}
                    fullWidth
                    margin="normal"
                    error={!!errors.noOfWorkPermitComplied}
                    helperText={errors.noOfWorkPermitComplied}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleComplySubmit}
                    disabled={isLoading}
                    fullWidth
                  >
                    {isLoading ? <CircularProgress size={24} /> : 'Compile it'}
                  </Button>
                </>
              )}
              <Box mt={2}>
                <Button variant="outlined" onClick={closeModal} fullWidth>
                  Back to list
                </Button>
              </Box>
            </Box>
          )}
        </ModalContent>
      </Modal>
    </Container>
    </Box>
    
  );
};

export default WorkPermitAuditorList;