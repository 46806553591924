// WorkPermitReportModal.js
import React from 'react';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions,
  Button,
  Typography,
  Grid,
  Paper,
  Chip,
  Box
} from '@mui/material';
import { format } from 'date-fns';
import { workPermitTypeQuestionList, ppesList } from './../../componants/workPermitTypeQuestionList';
import html2pdf from 'html2pdf.js';

const PrecautionItem = ({ label, status, verified }) => (
  <Box sx={{ mb: 1, p: 1, border: '1px solid #ddd', borderRadius: 1 }}>
    <Typography variant="body2">
      {label}: <strong>
        {status === 'not_required' ? 'marked as not required' :
         status === 'yes' ? 'marked as yes' : 'marked as no'}
      </strong>
    </Typography>
    <Typography variant="caption" color={verified ? "success.main" : "error.main"}>
      {verified ? "Verified" : "Not Verified"}
    </Typography>
  </Box>
);

const WorkPermitReport = ({ permit }) => {
  const getPermitType = (type) => {
    switch(type) {
      case "Hot Work": return "HotWork";
      case "Work at Height": return "WorkAtHeight";
      case "Electrical Work": return "ElectricalWork";
      case "Confined Space": return "ConfinedSpace";
      default: return "";
    }
  };

  const { generalPrecautionsList, specialPrecautionsList } = 
    workPermitTypeQuestionList[getPermitType(permit.type)] || {};

  // Helper function to render basic info value
  const renderValue = (value) => {
    if (value === null || value === undefined) return '-';
    if (typeof value === 'object' && !value.toDate) {
      return Object.entries(value)
        .map(([k, v]) => `${k}: ${v}`)
        .join(', ');
    }
    if (value.toDate) {
      return format(value.toDate(), 'dd/MM/yyyy');
    }
    return String(value);
  };
 
console.log('veri ppe',Boolean(permit.necessary_ppes?.[1]?.verified));
//console.log('verified_ppe',ppesList.map( (ppe, index) => (Boolean(permit.ppes?.[index + 1]?.verified)))

  return (
    <div id="work-permit-report">
      <Box sx={{ mb: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h5">Work Permit Report</Typography>
        <Chip 
          label={permit.status || 'N/A'}
          color={permit.status === 'ongoing' ? 'primary' :
                 permit.status === 'hold' ? 'warning' :
                 permit.status === 'closed' ? 'success' : 'error'}
        />
      </Box>

      <Paper sx={{ p: 2, mb: 3 }}>
        <Typography variant="h6" gutterBottom>Basic Information</Typography>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="subtitle2">Case Number</Typography>
            <Typography variant="body1" gutterBottom>{permit.caseNumber || 'N/A'}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle2">Work Permit Type</Typography>
            <Typography variant="body1" gutterBottom>{permit.type || 'N/A'}</Typography>
          </Grid>
          {permit.basic_info && Object.entries(permit.basic_info).map(([key, value]) => {
            // Skip these fields as they're handled separately or might cause rendering issues
            if (['isMultiDay', 'startDate', 'endDate', 'date'].includes(key)) {
              return null;
            }
            return (
              <Grid item xs={6} key={key}>
                <Typography variant="subtitle2">
                  {key.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                </Typography>
                <Typography variant="body1" gutterBottom>{renderValue(value)}</Typography>
              </Grid>
            );
          })}
          {permit.basic_info?.isMultiDay ? (
            <Grid item xs={6}>
              <Typography variant="subtitle2">Date Range</Typography>
              <Typography variant="body1" gutterBottom>
                {permit.basic_info.startDate && permit.basic_info.endDate ? 
                  `${format(permit.basic_info.startDate.toDate(), 'dd/MM/yyyy')} - 
                   ${format(permit.basic_info.endDate.toDate(), 'dd/MM/yyyy')}` : 'N/A'}
              </Typography>
            </Grid>
          ) : (
            <Grid item xs={6}>
              <Typography variant="subtitle2">Date</Typography>
              <Typography variant="body1" gutterBottom>
                {permit.basic_info?.date ? 
                  format(permit.basic_info.date.toDate(), 'dd/MM/yyyy') : 'N/A'}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Paper>

      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>General Precautions</Typography>
            {generalPrecautionsList?.map((item, index) => (
              <PrecautionItem
                key={index}
                label={item}
                status={
                  permit.general_precautions?.[index + 1]?.not_required ? 'not_required' :
                  permit.general_precautions?.[index + 1]?.yes === 'yes' ? 'yes' : 'no'
                }
                verified={Boolean(permit.general_precautions?.[index + 1]?.verified)}
              />
            ))}
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>Special Precautions</Typography>
            {specialPrecautionsList?.map((item, index) => (
              <PrecautionItem
                key={index}
                label={item}
                status={
                  permit.special_precautions?.[index + 1]?.not_required ? 'not_required' :
                  permit.special_precautions?.[index + 1]?.yes === 'yes' ? 'yes' : 'no'
                }
                verified={Boolean(permit.special_precautions?.[index + 1]?.verified === true)}
              />
            ))}
          </Paper>
        </Grid>
      </Grid>

      <Paper sx={{ p: 2, mt: 3 }}>
        <Typography variant="h6" gutterBottom>PPE Requirements</Typography>
        <Grid container spacing={2}>
          {ppesList.map((ppe, index) => (
            <Grid item xs={6} key={index}>
              <PrecautionItem
                label={ppe}
                status={
                  permit.ppes?.[index + 1]?.not_required ? 'not_required' :
                  permit.ppes?.[index + 1]?.yes === 'yes' ? 'yes' : 'no'
                }
                // verified={Boolean(permit.ppes?.[index + 1]?.verified)}
                verified={Boolean(permit.necessary_ppes?.[index + 1]?.verified)}
              />
            </Grid>
        
          ))}
        </Grid>
      </Paper>

      {permit.noc && (
        <Paper sx={{ p: 2, mt: 3 }}>
          <Typography variant="h6" gutterBottom>NOC Status</Typography>
          <Typography variant="body1">
            {/* {typeof permit.noc === 'object' 
              ? Object.entries(permit.noc)
                  .map(([key, value]) => `${key}: ${value}`)
                  .join(', ')
              : permit.noc} 
              */}
             {/* { typeof permit.noc === 'object'
                   ? Object.entries(permit.noc)
                     .map(([key, value]) => {
                  if (value.required) {
                      return `${key}:Email: ${value.email || 'N/A'}\nStatus: ${value.status || 'N/A'}`;
                  } else {
                      return `${key} is not required.`;
        }
      })
                 .join('\n\n')
                 : permit.noc } */}

          </Typography>
        </Paper>
      )}
    </div>
  );
};

const WorkPermitReportModal = ({ open, onClose, permit }) => {
  const handleDownloadPDF = () => {
    const element = document.getElementById('work-permit-report');
    const opt = {
      margin: 1,
      filename: `work-permit-${permit.caseNumber || 'report'}.pdf`,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' }
    };

    html2pdf().set(opt).from(element).save();
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose} 
      maxWidth="lg" 
      fullWidth
      PaperProps={{
        sx: { maxHeight: '90vh' }
      }}
    >
      <DialogTitle>Work Permit Report</DialogTitle>
      <DialogContent>
        <WorkPermitReport permit={permit} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        <Button onClick={handleDownloadPDF} variant="contained" color="primary">
          Download PDF
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default WorkPermitReportModal;