import React from "react";
import { Grid, Card, CardContent, Box, Typography, Stack } from "@mui/material";
import { Info, SwapHoriz } from "@mui/icons-material";


const CustomCard = ({ bannerText, onOpenClick, onCloseClick, openTitle, openValue, closedTitle, closedValue }) => {

  return (
    <Grid item xs={12} sm={4} md={3}>
      <Card
        sx={{
          width:300,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
          position: "relative",
          transition: "transform 0.3s ease-in-out",
          "&:hover": {
            transform: "scale(1.05)",
          },
          backgroundColor:"black",
          color:"white"
        }}
      >
        <Box
          sx={{
            top: 0,
            left: 0,
            padding: "4px 12px",
            position:"absolute",
            borderBottomRightRadius: "8px",
            fontWeight: "bold",
            fontSize: "1rem",
            backgroundColor:"orange",
            border:"solid white"
          }}
        >
          {bannerText} 
        </Box>
        <CardContent
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border:"solid white"
          }}
        >
          <Stack
            direction="row"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              mt:4
            }}
          >
          <Stack>
          
          </Stack>
            <Stack direction={"column"}>
              <Stack direction={'row'} >
              <Stack spacing={1} alignItems={"center"}>
            {/* <img src={URL} height={height}/> */}
            </Stack>
              {/* Open Section */}
              <Stack spacing={1} alignItems="center">
                <Typography variant="h3" color={"white"}>{ openValue ? openValue : 0 }</Typography>
                <Box sx={{width:100, display:"flex" ,justifyContent:'space-evenly'}}>
                <Typography variant="h6" fontWeight={"bold"} color={"orange"}>{openTitle}</Typography>
                <Info onClick={onOpenClick} sx={{cursor:'pointer'}}/>
                </Box>
              </Stack>
              
              <SwapHoriz fontSize="large"/>

              {/* Closed Section */}
              <Stack spacing={1} alignItems="center" sx={{marginRight:3}}>
                <Typography variant="h3" color={"white"}>{closedValue ? closedValue : 0 }</Typography>
              <Box sx={{width:100, display:"flex" ,justifyContent:'space-evenly'}}>
                <Typography variant="h6" fontWeight={"bold"} color={"green"}>{closedTitle}</Typography>
                <Info onClick={onCloseClick} sx={{cursor:'pointer'}}/>
              </Box>
              </Stack>
              </Stack>
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default CustomCard;
