import React, { useState, useEffect } from 'react';
import { 
  Container, 
  Tabs, 
  Tab, 
  Box, 
  Card, 
  CardContent, 
  Typography, 
  Button, 
  Chip,
  Grid
} from '@mui/material';
import { format } from 'date-fns';
import { firestore } from '../../firebase/firebaseConfig';
import WorkPermitModal from './WorkPermitModal';
import WorkPermitReportModal from './WorkPermitReportModal';
import { collection, getDocs, doc, updateDoc } from 'firebase/firestore';


const getStatusColor = (status) => {
  switch (status) {
    case 'ongoing':
      return 'primary';
    case 'hold':
      return 'warning';
    case 'closed':
      return 'success';
    case 'rejected':
      return 'error';
    default:
      return 'default';
  }
};

const WorkPermitCard = ({ permit, onViewDetails, onDownloadReport }) => {
  return (
    <Card sx={{ mb: 2, position: 'relative'}}>
      <Box sx={{ position: 'absolute', top: 16, right: 16 }}>
        <Chip 
          label={permit.status} 
          color={getStatusColor(permit.status)} 
          variant="filled"
        />
      </Box>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2" color="textSecondary">Case Number</Typography>
            <Typography variant="body1" gutterBottom>{permit.caseNumber}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2" color="textSecondary">Date</Typography>
            <Typography variant="body1" gutterBottom>
              {permit.basic_info.isMultiDay 
                ? `${format(permit.basic_info.startDate.toDate(), 'dd/MM/yyyy')} - ${format(permit.basic_info.endDate.toDate(), 'dd/MM/yyyy')}`
                : format(permit.basic_info.date.toDate(), 'dd/MM/yyyy')}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2" color="textSecondary">Work Permit Type</Typography>
            <Typography variant="body1" gutterBottom>{permit.type}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2" color="textSecondary">Plant/Area</Typography>
            <Typography variant="body1" gutterBottom>{permit.basic_info.plant}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2" color="textSecondary">Section</Typography>
            <Typography variant="body1" gutterBottom>{permit.basic_info.section}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2" color="textSecondary">Doc. No.</Typography>
            <Typography variant="body1" gutterBottom>{permit.basic_info.doc_no}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2" color="textSecondary">Job Description</Typography>
            <Typography variant="body1" gutterBottom>{permit.basic_info.job_description}</Typography>
          </Grid>
          <Grid item xs={12} sx={{ textAlign: 'right' }}>

          <Button 
              variant="outlined" 
              color="primary" 
              onClick={() => onDownloadReport(permit)}
              sx={{ mr: 1 }}
            >
              Download WPR
            </Button>
            <Button 
              variant="contained" 
              color="primary" 
              onClick={() => onViewDetails(permit)}
            >
              View Details
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

const WorkPermitList = ({ company_name }) => {
  const [tabValue, setTabValue] = useState(0);
  const [permits, setPermits] = useState([]);
  const [selectedPermit, setSelectedPermit] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [reportModalOpen, setReportModalOpen] = useState(false);

  useEffect(() => {
    const fetchPermits = async () => {
      const permitsRef = firestore
        .collection('company_data')
        .doc(company_name)
        .collection(company_name)
        .doc('for_user')
        .collection('work_permit_v2');

      //const snapshot = await permitsRef.get();
      const snapshot = await getDocs(permitsRef);
      const fetchedPermits = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      
      console.log('data',company_name,fetchedPermits);
      setPermits(fetchedPermits);
    };

    fetchPermits();
  }, [company_name, modalOpen, reportModalOpen ]);

  // console.log('permit : ',permits);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleViewDetails = (permit) => {
    setSelectedPermit(permit);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedPermit(null);
  };

  const getFilteredPermits = () => {
    if (tabValue === 0) {
      return permits.filter(permit => 
        permit.status === 'ongoing' || permit.status === 'hold'
      );
    } else {
      return permits.filter(permit => 
        permit.status === 'closed' || permit.status === 'rejected'
      );
    }
  };

  const handleDownloadReport = (permit) => {
    setSelectedPermit(permit);
    setReportModalOpen(true);
  };

  const handleCloseReportModal = () => {
    setReportModalOpen(false);
    setSelectedPermit(null);
  };

  return (
    <Container maxWidth="lg">
      <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 3, mt: 8, display: "flex", justifyContent: 'center' }}>
        <Tabs value={tabValue} onChange={handleTabChange}>
          <Tab label="Ongoing & Hold" />
          <Tab label="Closed & Rejected" />
        </Tabs>
      </Box>
      
      {getFilteredPermits().map((permit) => (
        <WorkPermitCard
          key={permit.id}
          permit={permit}
          onViewDetails={handleViewDetails}
          onDownloadReport={handleDownloadReport}
        />
      ))}

      {selectedPermit && (
        <>
          <WorkPermitModal
            open={modalOpen}
            onClose={handleCloseModal}
            permit={selectedPermit}
            company_name={company_name}
          />
          <WorkPermitReportModal
            open={reportModalOpen}
            onClose={handleCloseReportModal}
            permit={selectedPermit}
          />
        </>
      )}
    </Container>
  );
};

export default WorkPermitList;